// import React, { useState } from "react";
// import {
//     Button,
//     Card,
//     CardBody,
//     Col,
//     Container,
//     Form,
//     FormGroup,
//     Input,
//     Label,
//     NavItem,
//     NavLink,
//     Row,
//     TabContent,
//     TabPane,
//     Table,
//     InputGroup,
//     CardHeader,
//     CardTitle,
//     CardSubtitle,
//     PaginationItem,
//     PaginationLink,
//     Modal,
//     Pagination
// } from "reactstrap"
// import "../../src/common.css"
// import classnames from "classnames"
// import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
// import Select from "react-select";

// //Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
// import car1 from "../assets/images/latest/car1.jpg";
// import car2 from "../assets/images/latest/car2.jpg";
// import car3 from "../assets/images/latest/car3.jpg";
// import car4 from "../assets/images/latest/car4.jpg";
// import car5 from "../assets/images/latest/car5.jpg";
// import car6 from "../assets/images/latest/car6.jpg";
// import card1 from "../assets/images/latest/card1.jpeg";
// import card2 from "../assets/images/latest/card2.jpeg";
// import doc1 from "../assets/images/latest/11.png";
// import doc2 from "../assets/images/latest/22.png";
// import doc3 from "../assets/images/latest/33.png";

// const options = [
//     { value: 'Saudi', label: 'Saudi Arabia' },
//     { value: 'Oman', label: 'Oman' },
//     { value: 'Bahrain', label: 'Bahrain' },
//     { value: 'Belgium', label: 'Belgium' },
//     { value: 'Ireland', label: 'Ireland' },
//     { value: 'Turkey', label: 'Turkey' },
// ];

// const Addusers = () => {

//     const [selectedOption, setSelectedOption] = useState(null);
//     const [activeTab, setactiveTab] = useState(1)
//     const [activeTabVartical, setoggleTabVertical] = useState(1)
//     const [showResults, setShowResults] = React.useState(false);
//     const [editResults, seteditResults] = React.useState(false);
//     const showfield = () => setShowResults(true);
//     const hidefield = () => setShowResults(false);

//     const [passedSteps, setPassedSteps] = useState([1])
//     const [passedStepsVertical, setPassedStepsVertical] = useState([1])

//     function toggleTab(tab) {
//         if (activeTab !== tab) {
//             var modifiedSteps = [...passedSteps, tab]
//             if (tab >= 1 && tab <= 5) {
//                 setactiveTab(tab)
//                 setPassedSteps(modifiedSteps)
//             }
//         }
//     }

//     function toggleTabVertical(tab) {
//         if (activeTabVartical !== tab) {
//             var modifiedSteps = [...passedStepsVertical, tab]

//             if (tab >= 1 && tab <= 4) {
//                 setoggleTabVertical(tab)
//                 setPassedStepsVertical(modifiedSteps)
//             }
//         }
//     }
//     const [selectedMulti, setselectedMulti] = useState(null);
//     function handleMulti(selectedMulti) {
//         setselectedMulti(selectedMulti);
//     }

//     const optionGroup = [
//         {
//             label: "Colors",
//             options: [
//                 { label: "Beige", value: "Beige" },
//                 { label: "Black", value: "Black" },
//                 { label: "Blue", value: "Blue" },
//                 { label: "Brown", value: "Brown" },
//                 { label: "Dark Gray", value: "Dark Gray" },
//             ]
//         },
//     ];
//     const optionGroup1 = [
//         {
//             label: "Features",
//             options: [
//                 { label: "3D Surround Camera", value: "Beige" },
//                 { label: "Memory Front Seats", value: "Black" },
//                 { label: "Blind Spot Warning", value: "Blue" },
//                 { label: "Adaptive Cruise Control", value: "Brown" },
//                 { label: "Digital HUD", value: "Dark Gray" },
//                 { label: "Temperature Controlled Seats", value: "Dark" },
//             ]
//         },
//     ];

//     const [isDisabled, setIsDisabled] = useState(true);
//     const handleClick = () => {
//         setIsDisabled(!isDisabled)
//     };

//     const [isDisabled1, setIsDisabled1] = useState(true);
//     const handleClick1 = () => {
//         setIsDisabled1(!isDisabled1)
//     };
//     const [isDisabled2, setIsDisabled2] = useState(false);
//     const handleClick2 = () => {
//         setIsDisabled2(!isDisabled2)
//     };
//     const [isDisabled3, setIsDisabled3] = useState(true);
//     const handleClick3 = () => {
//         setIsDisabled3(!isDisabled3)
//     };
//     const [isDisabled4, setIsDisabled4] = useState(false);
//     const handleClick4 = () => {
//         setIsDisabled4(!isDisabled4)
//     };

//     const [selectedFiles, setselectedFiles] = useState([])
//     const [selectedFiles1, setselectedFiles1] = useState([])

//     function handleAcceptedFiles(files) {
//         files.map(file =>
//             Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//             })
//         )
//         setselectedFiles(files)
//     }
//     function handleAcceptedFiles1(files) {
//         files.map(file =>
//             Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//             })
//         )
//         setselectedFiles1(files)
//     }

//     function formatBytes(bytes, decimals = 2) {
//         if (bytes === 0) return "0 Bytes"
//         const k = 1024
//         const dm = decimals < 0 ? 0 : decimals
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//         const i = Math.floor(Math.log(bytes) / Math.log(k))
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//     }

//     //meta title
//     // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

//     const [modal_small, setmodal_small] = useState(false);

//     function tog_small() {
//         setmodal_small(!modal_small);
//         removeBodyCss();
//     }

//     const [fieldshow, setfieldshow] = useState()

//     const [fieldshow1, setfieldshow1] = useState()
//     const [fieldshow2, setfieldshow2] = useState()
//     const [fieldshow3, setfieldshow3] = useState()

//     function handleSelectChange(event) {
//         setfieldshow(event.target.value);
//     }
//     function handleSelectChange1(event) {
//         setfieldshow1(event.target.value);
//     }
//     function handleSelectChange2(event) {
//         setfieldshow2(event.target.value);
//     }
//     function handleSelectChange3(event) {
//         setfieldshow3(event.target.value);
//     }

//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     <Breadcrumbs title="Freedom Cars" breadcrumbItem="Edit Customer" />
//                     <Row>
//                     <Row>
//                             <div >
//                            <Link to="/users-list"> <Button style={{float:"right"}} className="mb-3" color="info"><i className="fas fa-arrow-circle-left"></i> Back</Button></Link>
//                            </div>
//                         </Row>
//                         <Form>
//                             <Col lg="12">
//                                 <Card>
//                                     <CardBody>
//                                         {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
//                                         <div className="wizard clearfix">
//                                             <div className="steps clearfix">
//                                                 <ul>
//                                                     <NavItem
//                                                         className={classnames({ current: activeTab === 1 })}
//                                                     >
//                                                         <NavLink
//                                                             className={classnames({ current: activeTab === 1 })}
//                                                             onClick={() => {
//                                                                 setactiveTab(1)
//                                                             }}
//                                                             disabled={!(passedSteps || []).includes(1)}
//                                                         >
//                                                             <span className="number"><i className="fas fa-user"></i></span> Personal Details
//                                                         </NavLink>
//                                                     </NavItem>
//                                                     <NavItem
//                                                         className={classnames({ current: activeTab === 2 })}
//                                                     >
//                                                         <NavLink
//                                                             className={classnames({ active: activeTab === 2 })}
//                                                             onClick={() => {
//                                                                 setactiveTab(2)
//                                                             }}
//                                                             disabled={!(passedSteps || []).includes(2)}
//                                                         >
//                                                             <span className="number"><i className="fas fa-shield-alt"></i></span> Documents

//                                                         </NavLink>
//                                                     </NavItem>
//                                                     {/* <NavItem
//                                                     className={classnames({ current: activeTab === 3 })}
//                                                 >
//                                                     <NavLink
//                                                         className={classnames({ active: activeTab === 3 })}
//                                                         onClick={() => {
//                                                             setactiveTab(3)
//                                                         }}
//                                                         disabled={!(passedSteps || []).includes(3)}
//                                                     >
//                                                         <span className="number"><i className="fas fa-images"></i></span> Car Images
//                                                     </NavLink>
//                                                 </NavItem>
//                                                 <NavItem
//                                                     className={classnames({ current: activeTab === 4 })}
//                                                 >
//                                                     <NavLink
//                                                         className={classnames({ active: activeTab === 4 })}
//                                                         onClick={() => {
//                                                             setactiveTab(4)
//                                                         }}
//                                                         disabled={!(passedSteps || []).includes(4)}
//                                                     >
//                                                         <span className="number"><i className="fas fa-money-check-alt"></i></span> PRICING
//                                                     </NavLink>
//                                                 </NavItem>
//                                                 <NavItem
//                                                     className={classnames({ current: activeTab === 5 })}
//                                                 >
//                                                     <NavLink
//                                                         className={classnames({ active: activeTab === 5 })}
//                                                         onClick={() => {
//                                                             setactiveTab(5)
//                                                         }}
//                                                         disabled={!(passedSteps || []).includes(5)}
//                                                     >
//                                                         <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
//                                                     </NavLink>
//                                                 </NavItem> */}

//                                                 </ul>
//                                             </div>
//                                             <div className="content clearfix mt-2">
//                                                 <TabContent activeTab={activeTab}>
//                                                     <TabPane tabId={1}>
//                                                         <Form>
//                                                             <Row>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input1">
//                                                                             Full Name <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="text"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input1"
//                                                                             placeholder="Enter Name"
//                                                                             
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Mobile No <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="number"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input3"
//                                                                             placeholder="Enter number"
//                                                                             
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Email <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="email"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input3"
//                                                                             placeholder="Enter Email"
//                                                                             
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Gender <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         {/* <Input
//                                                         type="date"
//                                                         className="form-control"
//                                                         id="basicpill-firstname-input3"
//                                                         placeholder="Enter DOB"
//                                                         
//                                                     /> */}
//                                                                         <select className="form-select">
//                                                                             <option value="male">Male</option>
//                                                                             <option value="male">Female</option>
//                                                                         </select>
//                                                                     </div>

//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Date of Birth <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="date"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input3"
//                                                                             placeholder="Enter DOB"
//                                                                             
//                                                                         />
//                                                                     </div>

//                                                                 </Col>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input1">
//                                                                             Image
//                                                                             {/* <span className="text-danger">*</span> */}
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="file"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input1"
//                                                                             placeholder="Enter Image"

//                                                                         />
//                                                                     </div>
//                                                                 </Col>

//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Occupation <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         {/* <Input
//                                                         type="email"
//                                                         className="form-control"
//                                                         id="basicpill-firstname-input3"
//                                                         placeholder="Enter Email"
//                                                         
//                                                     /> */}
//                                                                         <select onChange={handleSelectChange2} className="form-select">
//                                                                             {/* <option value="">Select</option> */}
//                                                                             <option value="student">Student</option>
//                                                                             <option value="employee">Employee</option>
//                                                                             <option value="businessman">Businessman</option>
//                                                                         </select>
//                                                                     </div>
//                                                                 </Col>
//                                                                 {fieldshow2 == "employee" || fieldshow2 == "businessman" ? (
//                                                                     <Col md={3}>
//                                                                         <div className="mb-3">
//                                                                             <Label for="basicpill-firstname-input3">
//                                                                                 Company Name
//                                                                                 {/* <span className="text-danger">*</span> */}
//                                                                             </Label>
//                                                                             <Input
//                                                                                 type="text"
//                                                                                 className="form-control"
//                                                                                 id="basicpill-firstname-input3"
//                                                                                 placeholder="Enter Company"

//                                                                             />
//                                                                         </div>
//                                                                     </Col>
//                                                                 ) : (
//                                                                     <Col md={3}>
//                                                                         <div className="mb-3">
//                                                                             <Label for="basicpill-firstname-input3">
//                                                                                 College Name
//                                                                                 {/* <span className="text-danger">*</span> */}
//                                                                             </Label>
//                                                                             <Input
//                                                                                 type="text"
//                                                                                 className="form-control"
//                                                                                 id="basicpill-firstname-input3"
//                                                                                 placeholder="Enter College"

//                                                                             />
//                                                                         </div>
//                                                                     </Col>
//                                                                 )}
//                                                             </Row>

//                                                             <Row>

//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input1">
//                                                                             Occupation Id Card
//                                                                             {/* <span className="text-danger">*</span> */}
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="file"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input1"
//                                                                             placeholder="Enter Name"

//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Address
//                                                                         </Label>
//                                                                         <Input
//                                                                             type="text"
//                                                                             className="form-control"
//                                                                             id="basicpill-firstname-input3"
//                                                                             placeholder="Enter Address"
//                                                                             
//                                                                         />
//                                                                     </div>
//                                                                 </Col>

//                                                                 <Col md={3}>
//                                                                     <div className="mb-3">
//                                                                         <Label for="basicpill-firstname-input3">
//                                                                             Residents Type <span className="text-danger">*</span>
//                                                                         </Label>
//                                                                         <select  onChange={handleSelectChange} className="form-select">
//                                                                             <option value="">Select</option>
//                                                                             <option value="res">Residents</option>
//                                                                             <option value="nonres">Non-Residents</option>
//                                                                         </select>
//                                                                     </div>
//                                                                 </Col>

//                                                             </Row>

//                                                         </Form>
//                                                     </TabPane>
//                                                     <TabPane tabId={2}>
//                                                         {fieldshow == "res" ? (
//                                                             <div>
//                                                                 {/* <Row>
//                                                                     <Col>
//                                                                     <img src={doc1} style={{width:"100%", height:"110px"}}/>
//                                                                     </Col>
//                                                                     <Col>
//                                                                     <img src={doc2}  style={{width:"100%", height:"110px"}}/>
//                                                                     </Col>
//                                                                     <Col>
//                                                                     <img src={doc3}  style={{width:"100%", height:"110px"}}/>
//                                                                     </Col>
//                                                                 </Row> */}
//                                                                 <Form className="mt-3">
//                                                                     <Row>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input1">
//                                                                                     Select Aadhar / vote / Passport<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 {/* <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input1"
//                                                                                     
//                                                                                 /> */}
//                                                                                 <select onChange={handleSelectChange3} className="form-select">
//                                                                                     <option value="">Select</option>
//                                                                                     <option value="aadhar">Aadhar</option>
//                                                                                     <option value="Vote">Vote Id</option>
//                                                                                     <option value="Passport">Passport</option>
//                                                                                 </select>

//                                                                             </div>
//                                                                         </Col>

//                                                                         {fieldshow3 == "Vote" ? (
//                                                                             <>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input1">
//                                                                                             Vote Id <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="text"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input1"
//                                                                                             placeholder=" Vote Id"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input3">
//                                                                                             Vote ID Front<span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input3"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input1">
//                                                                                             Vote ID  Back<span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input1"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>

//                                                                             </>
//                                                                         ) : (
//                                                                             ""
//                                                                         )}

//                                                                         {fieldshow3 == "Passport" ? (
//                                                                             <>

//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input1">
//                                                                                             Passport Front <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input1"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input3">
//                                                                                             Passport Back <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input3"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                             </>
//                                                                         ) : (
//                                                                             ""
//                                                                         )}
//                                                                         {fieldshow3 == "aadhar" ? (
//                                                                             <>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input1">
//                                                                                             Aadhar No. <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="text"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input1"
//                                                                                             placeholder="Aadhar No."
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>

//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input1">
//                                                                                             Aadhar Front <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input1"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                                 <Col md={3}>
//                                                                                     <div className="mb-3">
//                                                                                         <Label for="basicpill-firstname-input3">
//                                                                                             Aadhar Back <span className="text-danger">*</span>
//                                                                                         </Label>
//                                                                                         <Input
//                                                                                             type="file"
//                                                                                             className="form-control"
//                                                                                             id="basicpill-firstname-input3"
//                                                                                             
//                                                                                         />
//                                                                                     </div>
//                                                                                 </Col>
//                                                                             </>
//                                                                         ) : (
//                                                                             ""
//                                                                         )}

//                                                                         {/* <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Passport Visa <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col> */}
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Driving License Number<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="text"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     placeholder="license Number"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>

//                                                                             <Col md={3}>
//                                                                                 <div className="mb-3">
//                                                                                     <Label for="basicpill-firstname-input3">
//                                                                                         Driving License front<span className="text-danger">*</span>
//                                                                                     </Label>
//                                                                                     <Input
//                                                                                         type="file"
//                                                                                         className="form-control"
//                                                                                         id="basicpill-firstname-input3"
//                                                                                         
//                                                                                     />
//                                                                                 </div>
//                                                                             </Col>
//                                                                             <Col md={3}>
//                                                                                 <div className="mb-3">
//                                                                                     <Label for="basicpill-firstname-input3">
//                                                                                         Driving License back <span className="text-danger">*</span>
//                                                                                     </Label>
//                                                                                     <Input
//                                                                                         type="file"
//                                                                                         className="form-control"
//                                                                                         id="basicpill-firstname-input3"
//                                                                                         
//                                                                                     />
//                                                                                 </div>
//                                                                             </Col>
//                                                                     </Row>

//                                                                 </Form>
//                                                             </div>
//                                                         ) : (
//                                                             ""
//                                                         )}
//                                                         {fieldshow == "nonres" ? (
//                                                             <div>
//                                                                 <Form>
//                                                                     <Row>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input1">
//                                                                                     Countries <span className="text-danger">*</span>
//                                                                                 </Label>

//                                                                                 <Select
//                                                                                     defaultValue={selectedOption}
//                                                                                     onChange={setSelectedOption}
//                                                                                     options={options}
//                                                                                 />

//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Passport Copy <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Id card front <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Visit Visa Copy<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>
//                                                                     <Row className="mt-3">
//                                                                     <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                 International Driving License <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="text"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     placeholder="license Number"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={4}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     International Driving License Front  <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={4}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     International Driving License Back <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>
//                                                                 </Form>
//                                                             </div>
//                                                         ) : (
//                                                             ""
//                                                         )}

//                                                     </TabPane>
//                                                     <TabPane tabId={3}>
//                                                         {fieldshow == "nonres" ? (
//                                                             <div>
//                                                                 <Form>
//                                                                     <Row>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input1">
//                                                                                     Countries <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <select className="form-select">
//                                                                                     <option value="saudi">Saudi Arabia</option>
//                                                                                     <option value="saudi">Oman</option>
//                                                                                     <option value="saudi">Bahrain</option>
//                                                                                     <option value="saudi">Belgium</option>
//                                                                                     <option value="saudi">Ireland</option>
//                                                                                     <option value="saudi">Turkey</option>
//                                                                                 </select>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Passport Copy <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Id card front <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     License<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 {/* <Input
//                       type="file"
//                       className="form-control"
//                       id="basicpill-firstname-input3"
//                       
//                   /> */}
//                                                                                 <select className="form-select">
//                                                                                     <option value="home">Home License</option>
//                                                                                     <option value="home">International Driving License</option>
//                                                                                 </select>
//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>
//                                                                     <Row className="mt-3">
//                                                                     <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                 Home License Number<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     placeholder="license Number"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input1">
//                                                                                     Home License Front<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input1"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     Home License Back<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                 International  Driving License <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     placeholder="license Number"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     International Driving License Front <span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={3}>
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-firstname-input3">
//                                                                                     International Driving License Back<span className="text-danger">*</span>
//                                                                                 </Label>
//                                                                                 <Input
//                                                                                     type="file"
//                                                                                     className="form-control"
//                                                                                     id="basicpill-firstname-input3"
//                                                                                     
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>
//                                                                 </Form>
//                                                             </div>
//                                                         ) : (

//                                                             <div>
//                                                                 <Form>
//                                                                     <Row>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3 mt-4">
//                                                                                 <div className="form-check mb-3" >
//                                                                                     <input
//                                                                                         className="form-check-input"
//                                                                                         type="checkbox"
//                                                                                         value=""
//                                                                                         id="defaultCheck1"
//                                                                                         onClick={handleClick}
//                                                                                     />
//                                                                                     <label
//                                                                                         className="form-check-label"
//                                                                                         htmlFor="defaultCheck1"

//                                                                                     >
//                                                                                         Available for Daily
//                                                                                     </label>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input11">
//                                                                                     Price Per Day
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">AED</div>
//                                                                                     <input disabled={isDisabled} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input21">
//                                                                                     Mileage
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">KM</div>
//                                                                                     <input type="text" disabled={isDisabled} className="form-control" id="basicpill-namecard-input21" placeholder="Mileage" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label>Minimum Day Booking</Label>
//                                                                                 <select disabled={isDisabled} className="form-select">
//                                                                                     <option value="">Select</option>
//                                                                                     <option value="1">1</option>
//                                                                                     <option value="2">2</option>
//                                                                                     <option value="3">3</option>
//                                                                                 </select>

//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>
//                                                                     <Row>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3 mt-4">
//                                                                                 <div className="form-check mb-3">
//                                                                                     <input
//                                                                                         className="form-check-input"
//                                                                                         type="checkbox"
//                                                                                         value=""
//                                                                                         id="defaultCheck2"
//                                                                                         onClick={handleClick1}
//                                                                                     />
//                                                                                     <label
//                                                                                         className="form-check-label"
//                                                                                         htmlFor="defaultCheck2"
//                                                                                     >
//                                                                                         Available for Weekly
//                                                                                     </label>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input11">
//                                                                                     Price Per Week
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">AED</div>
//                                                                                     <input disabled={isDisabled1} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input21">
//                                                                                     Mileage
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">KM</div>
//                                                                                     <input disabled={isDisabled1} type="text" className="form-control" id="basicpill-namecard-input21" placeholder="Mileage" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                         {/* <Col lg="3">
//                                   <div className="mb-3">
//                                     <Label>Minimum Day Booking</Label>
//                                     <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="1">1</option>
//                                     <option value="2">2</option>
//                                     <option value="3">3</option>
//                                     </select>

//                                   </div>
//                                 </Col> */}

//                                                                     </Row>

//                                                                     <Row>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3 mt-4">
//                                                                                 <div className="form-check mb-3">
//                                                                                     <input
//                                                                                         className="form-check-input"
//                                                                                         type="checkbox"
//                                                                                         value=""
//                                                                                         id="defaultCheck4"
//                                                                                         onClick={handleClick2}
//                                                                                     />
//                                                                                     <label
//                                                                                         className="form-check-label"
//                                                                                         htmlFor="defaultCheck4"
//                                                                                     >
//                                                                                         It's Free!
//                                                                                     </label>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input11">
//                                                                                     Extra Millage Cost / km
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">AED</div>
//                                                                                     <input disabled={isDisabled2} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input21">
//                                                                                     CDW Insurance Per Day
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">KM</div>
//                                                                                     <input type="number" className="form-control" id="basicpill-namecard-input21" placeholder="" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>

//                                                                     <Row>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3 mt-4">
//                                                                                 <div className="form-check mb-3">
//                                                                                     <input
//                                                                                         className="form-check-input"
//                                                                                         type="checkbox"
//                                                                                         value=""
//                                                                                         id="defaultCheck3"
//                                                                                         onClick={handleClick3}
//                                                                                     />
//                                                                                     <label
//                                                                                         className="form-check-label"
//                                                                                         htmlFor="defaultCheck3"
//                                                                                     >
//                                                                                         Available for Monthly
//                                                                                     </label>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col lg="3">
//                                                                             <div className="mb-3">
//                                                                                 <Label for="basicpill-namecard-input11">
//                                                                                     CDW Insurance Per Month
//                                                                                 </Label>
//                                                                                 <InputGroup>
//                                                                                     <div className="input-group-text">AED</div>
//                                                                                     <input disabled={isDisabled3} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                 </InputGroup>
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>

//                                                                     <Row>
//                                                                         <Col md={12}>
//                                                                             <div className="table-responsive">

//                                                                                 <Table className="table table-sm table-bordered mb-5 mt-3">
//                                                                                     <thead>
//                                                                                         <tr>
//                                                                                             <th>Period / Mileage</th>
//                                                                                             <th>1 Month </th>
//                                                                                             <th>3 Months</th>
//                                                                                             <th>6 Months</th>
//                                                                                             <th>9 Months</th>
//                                                                                             <th>12 Months</th>
//                                                                                         </tr>
//                                                                                     </thead>
//                                                                                     <tbody>
//                                                                                         <tr>
//                                                                                             <th scope="row">2000KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>
//                                                                                         <tr>
//                                                                                             <th scope="row">3000KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>
//                                                                                         <tr>
//                                                                                             <th scope="row">4000KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>
//                                                                                         <tr>
//                                                                                             <th scope="row">4500KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>
//                                                                                         <tr>
//                                                                                             <th scope="row">5000KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>
//                                                                                         <tr>
//                                                                                             <th scope="row">6000KM</th>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                             <td>
//                                                                                                 <InputGroup>
//                                                                                                     <div className="input-group-text">AED</div>
//                                                                                                     <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
//                                                                                                 </InputGroup>
//                                                                                             </td>
//                                                                                         </tr>

//                                                                                     </tbody>
//                                                                                 </Table>
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>

//                                                                 </Form>
//                                                             </div>
//                                                         )}

//                                                     </TabPane>
//                                                     <TabPane tabId={4}>

//                                                     </TabPane>
//                                                     <TabPane tabId={5}>
//                                                         <div>
//                                                             <Form>
//                                                                 <Row>
//                                                                     <Col lg="2">
//                                                                         <div className="mb-3 mt-4">
//                                                                             <div className="form-check mb-3">
//                                                                                 <input
//                                                                                     className="form-check-input"
//                                                                                     type="checkbox"
//                                                                                     value=""
//                                                                                     id="defaultCheck5"
//                                                                                     onClick={handleClick4}
//                                                                                 />
//                                                                                 <label
//                                                                                     className="form-check-label"
//                                                                                     htmlFor="defaultCheck5"
//                                                                                 >
//                                                                                     Not 
//                                                                                 </label>
//                                                                             </div>
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col lg="3">
//                                                                         <div className="mb-3">
//                                                                             <Label for="basicpill-namecard-input11">
//                                                                                 Security Deposit
//                                                                             </Label>
//                                                                             <Input
//                                                                                 type="number"
//                                                                                 className="form-control"
//                                                                                 id="basicpill-namecard-input11"
//                                                                                 placeholder="Enter Amount"
//                                                                                 disabled={isDisabled4}
//                                                                             />
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col lg="3">
//                                                                         <div className="mb-3">
//                                                                             <Label>Excess Claim Amount</Label>
//                                                                             <Input
//                                                                                 type="number"
//                                                                                 className="form-control"
//                                                                                 id="basicpill-namecard-input11"
//                                                                                 placeholder="Enter Amount"
//                                                                             />
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col lg="4">
//                                                                         <div className="mb-3">
//                                                                             <Label>Special Note for Customers</Label>
//                                                                             <textarea
//                                                                                 type="text"
//                                                                                 className="form-control"
//                                                                                 id="basicpill-namecard-input11"
//                                                                                 placeholder="Upto 150 characters"
//                                                                             />
//                                                                         </div>
//                                                                     </Col>

//                                                                 </Row>
//                                                             </Form>
//                                                         </div>
//                                                     </TabPane>

//                                                 </TabContent>
//                                             </div>
//                                             <div className="actions clearfix">
//                                                 <ul>
//                                                     <li
//                                                         className={
//                                                             activeTab === 1 ? "previous disabled" : "previous"
//                                                         }
//                                                     >
//                                                         <Link
//                                                             to="#"
//                                                             onClick={() => {
//                                                                 toggleTab(activeTab - 1)
//                                                             }}
//                                                             style={{ background: "#d05151" }}
//                                                         >
//                                                             <i className="fas fa-arrow-circle-left"></i>  Previous
//                                                         </Link>
//                                                     </li>
//                                                     <li
//                                                         className={activeTab === 2 ? "next logbtn" : "next"}
//                                                     >
//                                                         <Link
//                                                             to="#"
//                                                             onClick={() => {
//                                                                 toggleTab(activeTab + 1)
//                                                             }}
//                                                         >
//                                                             Next <i className="fas fa-arrow-circle-right"></i>
//                                                         </Link>
//                                                     </li>
//                                                     <li
//                                                         className={
//                                                             activeTab < 2 ? "previous logbtn " : "previous"
//                                                         }
//                                                     >
//                                                         <Link
//                                                             to="#"
//                                                             className="btn btn-primary" type="submit"
//                                                         >
//                                                             <i className="fas fa-check-circle"></i> Submit
//                                                         </Link>
//                                                     </li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Form>
//                     </Row>

//                 </Container>

//                 <Modal
//                     isOpen={modal_small}
//                     toggle={() => {
//                         tog_small();
//                     }}
//                 >
//                     <div className="modal-header">
//                         <h5
//                             className="modal-title mt-0"
//                             id="mySmallModalLabel"
//                         >
//                             Edit Users
//                         </h5>
//                         <button
//                             onClick={() => {
//                                 setmodal_small(false);
//                             }}
//                             type="button"
//                             className="close"
//                             data-dismiss="modal"
//                             aria-label="Close"
//                         >
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <div className="modal-body">
//                         <Form>
//                             <Row>
//                                 <Col>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input3">
//                                             Branch <span className="text-danger">*</span>
//                                         </Label>
//                                         {/* <Input
//                                                 type="emial"
//                                                 className="form-control"
//                                                 id="basicpill-firstname-input3"
//                                                 placeholder="Enter Email"
//                                                 
//                                             /> */}
//                                         <select className="form-select" >
//                                             <option value="">Select</option>
//                                             <option value="Dubai">Dubai</option>
//                                             <option value="AbuDhabi">Abu Dhabi</option>
//                                         </select>
//                                     </div>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input1">
//                                             Name <span className="text-danger">*</span>
//                                         </Label>
//                                         <Input
//                                             type="text"
//                                             className="form-control"
//                                             id="basicpill-firstname-input1"
//                                             placeholder="Enter Name"
//                                             
//                                         />
//                                     </div>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input3">
//                                             Phone No <span className="text-danger">*</span>
//                                         </Label>
//                                         <Input
//                                             type="number"
//                                             className="form-control"
//                                             id="basicpill-firstname-input3"
//                                             placeholder="Enter Phone No"
//                                             
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input3">
//                                             Department <span className="text-danger">*</span>
//                                         </Label>
//                                         {/* <Input
//                                                 type="emial"
//                                                 className="form-control"
//                                                 id="basicpill-firstname-input3"
//                                                 placeholder="Enter Email"
//                                                 
//                                             /> */}
//                                         <select className="form-select" >
//                                             <option value="">Select</option>
//                                             <option value="Dubai">Manager</option>
//                                             <option value="AbuDhabi">Employee</option>
//                                             {/* <option value="AbuDhabi">Driver</option> */}
//                                             {/* <option value="AbuDhabi">Customer</option> */}
//                                         </select>
//                                     </div>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input3">
//                                             Email <span className="text-danger">*</span>
//                                         </Label>
//                                         <Input
//                                             type="emial"
//                                             className="form-control"
//                                             id="basicpill-firstname-input3"
//                                             placeholder="Enter Email"
//                                             
//                                         />
//                                     </div>
//                                     <div className="mb-3">
//                                         <Label for="basicpill-firstname-input3">
//                                             Password <span className="text-danger">*</span>
//                                         </Label>
//                                         <Input
//                                             type="password"
//                                             className="form-control"
//                                             id="basicpill-firstname-input3"
//                                             placeholder="Enter Password"
//                                             
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input2">
//                                     Address <span className="text-danger">*</span>
//                                 </Label>
//                                 <textarea
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input2"
//                                     placeholder="Enter  Address"
//                                     
//                                 />
//                             </div>
//                             <div style={{ float: "right" }}>
//                                 <Button onClick={() => {
//                                     setmodal_small(false);
//                                 }} color="danger" type="button">
//                                     Cancel <i className="fas fa-times-circle"></i>
//                                 </Button>
//                                 <Button className="m-1" color="primary" type="button">
//                                     Submit <i className="fas fa-check-circle"></i>
//                                 </Button>

//                             </div>
//                         </Form>
//                     </div>
//                 </Modal>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Addusers

import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  CardHeader,
  CardTitle,
  CardSubtitle,
  PaginationItem,
  PaginationLink,
  Modal,
  Pagination,
} from "reactstrap"
import "../../src/common.css"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import Select from "react-select"

import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg"
import car2 from "../assets/images/latest/car2.jpg"
import car3 from "../assets/images/latest/car3.jpg"
import car4 from "../assets/images/latest/car4.jpg"
import car5 from "../assets/images/latest/car5.jpg"
import car6 from "../assets/images/latest/car6.jpg"
import card1 from "../assets/images/latest/card1.jpeg"
import card2 from "../assets/images/latest/card2.jpeg"
import doc1 from "../assets/images/latest/11.png"
import doc2 from "../assets/images/latest/22.png"
import doc3 from "../assets/images/latest/33.png"

const options = [
  { value: "Saudi", label: "Saudi Arabia" },
  { value: "Oman", label: "Oman" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Belgium", label: "Belgium" },
  { value: "Ireland", label: "Ireland" },
  { value: "Turkey", label: "Turkey" },
]

const Editusers = () => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [showResults, setShowResults] = React.useState(false)
  const [editResults, seteditResults] = React.useState(false)
  const showfield = () => setShowResults(true)
  const hidefield = () => setShowResults(false)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  const [selectedMulti, setselectedMulti] = useState(null)
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  const optionGroup = [
    {
      label: "Colors",
      options: [
        { label: "Beige", value: "Beige" },
        { label: "Black", value: "Black" },
        { label: "Blue", value: "Blue" },
        { label: "Brown", value: "Brown" },
        { label: "Dark Gray", value: "Dark Gray" },
      ],
    },
  ]
  const optionGroup1 = [
    {
      label: "Features",
      options: [
        { label: "3D Surround Camera", value: "Beige" },
        { label: "Memory Front Seats", value: "Black" },
        { label: "Blind Spot Warning", value: "Blue" },
        { label: "Adaptive Cruise Control", value: "Brown" },
        { label: "Digital HUD", value: "Dark Gray" },
        { label: "Temperature Controlled Seats", value: "Dark" },
      ],
    },
  ]

  const [isDisabled, setIsDisabled] = useState(true)
  const handleClick = () => {
    setIsDisabled(!isDisabled)
  }

  const [isDisabled1, setIsDisabled1] = useState(true)
  const handleClick1 = () => {
    setIsDisabled1(!isDisabled1)
  }
  const [isDisabled2, setIsDisabled2] = useState(false)
  const handleClick2 = () => {
    setIsDisabled2(!isDisabled2)
  }
  const [isDisabled3, setIsDisabled3] = useState(true)
  const handleClick3 = () => {
    setIsDisabled3(!isDisabled3)
  }
  const [isDisabled4, setIsDisabled4] = useState(false)
  const handleClick4 = () => {
    setIsDisabled4(!isDisabled4)
  }

  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }
  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  //meta title
  // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [modal_small2, setmodal_small2] = useState(false)
  const [modal_small3, setmodal_small3] = useState(false)
  const [modal_small4, setmodal_small4] = useState(false)
  const [modal_small5, setmodal_small5] = useState(false)
  const [modal_small6, setmodal_small6] = useState(false)
  const [modal_small7, setmodal_small7] = useState(false)
  const [modal_small8, setmodal_small8] = useState(false)
  const [modal_small9, setmodal_small9] = useState(false)
  const [modal_small10, setmodal_small10] = useState(false)
  const [modal_small11, setmodal_small11] = useState(false)
  const [modal_small12, setmodal_small12] = useState(false)
  const [modal_small13, setmodal_small13] = useState(false)
  const [modal_small14, setmodal_small14] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }
  function tog_small1() {
    setmodal_small1(!modal_small)
    removeBodyCss()
  }
  function tog_small2() {
    setmodal_small2(!modal_small)
    removeBodyCss()
  }
  function tog_small3() {
    setmodal_small3(!modal_small)
    removeBodyCss()
  }
  function tog_small4() {
    setmodal_small4(!modal_small)
    removeBodyCss()
  }
  function tog_small5() {
    setmodal_small5(!modal_small)
    removeBodyCss()
  }
  function tog_small6() {
    setmodal_small6(!modal_small)
    removeBodyCss()
  }
  function tog_small7() {
    setmodal_small7(!modal_small)
    removeBodyCss()
  }
  function tog_small8() {
    setmodal_small8(!modal_small)
    removeBodyCss()
  }
  function tog_small9() {
    setmodal_small9(!modal_small)
    removeBodyCss()
  }
  function tog_small10() {
    setmodal_small10(!modal_small)
    removeBodyCss()
  }
  function tog_small11() {
    setmodal_small11(!modal_small)
    removeBodyCss()
  }
  function tog_small12() {
    setmodal_small12(!modal_small)
    removeBodyCss()
  }
  function tog_small13() {
    setmodal_small13(!modal_small)
    removeBodyCss()
  }
  function tog_small14() {
    setmodal_small14(!modal_small)
    removeBodyCss()
  }

  const [fieldshow, setfieldshow] = useState()
  const [fieldshow1, setfieldshow1] = useState()
  console.log(fieldshow1)
  const [fieldshow2, setfieldshow2] = useState()
  const [fieldshow3, setfieldshow3] = useState()

  const [form, setform] = useState({
    customerName: "",
    phone: "",
    email: "",
    status: "",
    gender: "",
    dateOfBirth: "",
    occupation: "",
    occupationdetails: "",
    address: "",
    residentStatus: "",
    dlNumber: "",
    countryId: "",
    aadharNumber: "",
    voterIdNumber: "",

    documentType: "",
  })

  //     --form 'customerName="sameer sattyanand13"' \
  // --form 'phone="9475866013"' \
  // --form 'email="sattyasameer13@gmail.com"' \
  // --form 'password="CT123456"' \
  // --form 'gender="M"' \
  // --form 'dateOfBirth="1989-09-23"' \
  // --form 'profileImg=@"/C:/Users/NISHAT FATEMA/Downloads/carBannerFreedom.jpg"' \
  // --form 'occupation="business"' \
  // --form 'occupationdetails="Owner of a import-export firm named \"leke aa bhejde\""' \
  // --form 'occupidImg=@"/C:/Users/NISHAT FATEMA/Downloads/vehicleregister.jpg"' \
  // --form 'address="Abu Dhabi, UAE"' \
  // --form 'residentStatus="nonResident"' \
  // --form 'dlNumber="ADH-2388706"' \
  // --form 'countryId="6385a313e4cc4eaf8914ff6f"' \
  // --form 'documentType="passport"' \
  // --form 'aadharNumber=""' \
  // --form 'aadharFrontImg=@"/path/to/file"' \
  // --form 'aadharBackImg=@"/path/to/file"' \
  // --form 'voterIdNumber=""' \
  // --form 'voterIdFrontImg=@"/path/to/file"' \
  // --form 'voterIdBackImg=@"/path/to/file"' \
  // --form 'passportFrontImg=@"/C:/Users/NISHAT FATEMA/Downloads/vehicleregister.jpg"' \
  // --form 'passportBackImg=@"/C:/Users/NISHAT FATEMA/Downloads/vehicleregister.jpg"' \
  // --form 'dlFrontImg=@"/C:/Users/NISHAT FATEMA/Downloads/vehicleregister.jpg"' \
  // --form 'dlBackImg=@"/C:/Users/NISHAT FATEMA/Downloads/vehicleregister.jpg"' \
  // --form 'photoidImg=@"/path/to/file"' \
  // --form 'visaImg=@"/path/to/file"' \
  // --form 'interPassportImg=@"/path/to/file"' \
  // --form 'interDlFrontImg=@"/path/to/file"' \
  // --form 'interDlBackImg=@"/path/to/file"'

  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }
  function handleSelectChange1(event) {
    setfieldshow1(event.target.value)
  }
  function handleSelectChange2(event) {
    setfieldshow2(event.target.value)
  }
  function handleSelectChange3(event) {
    setfieldshow3(event.target.value)
  }

  const [count, setcount] = useState([])

  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")
  const [Files2, setFiles2] = useState("")
  const [Files3, setFiles3] = useState("")
  const [Files4, setFiles4] = useState("")
  const [Files5, setFiles5] = useState("")
  const [Files6, setFiles6] = useState("")
  const [Files7, setFiles7] = useState("")
  const [Files8, setFiles8] = useState("")
  const [Files9, setFiles9] = useState("")
  const [Files10, setFiles10] = useState("")
  const [Files11, setFiles11] = useState("")
  const [Files12, setFiles12] = useState("")
  const [Files13, setFiles13] = useState("")
  const [Files14, setFiles14] = useState("")
  // const [Files3, setFiles3] = useState("");

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }
  const changeHandler2 = e => {
    setFiles2(e.target.files)
  }
  const changeHandler3 = e => {
    setFiles3(e.target.files)
  }
  const changeHandler4 = e => {
    setFiles4(e.target.files)
  }
  const changeHandler5 = e => {
    setFiles5(e.target.files)
  }
  const changeHandler6 = e => {
    setFiles6(e.target.files)
  }
  const changeHandler7 = e => {
    setFiles7(e.target.files)
  }
  const changeHandler8 = e => {
    setFiles8(e.target.files)
  }
  const changeHandler9 = e => {
    setFiles9(e.target.files)
  }
  const changeHandler10 = e => {
    setFiles10(e.target.files)
  }
  const changeHandler11 = e => {
    setFiles11(e.target.files)
  }
  const changeHandler12 = e => {
    setFiles12(e.target.files)
  }
  const changeHandler13 = e => {
    setFiles13(e.target.files)
  }
  const changeHandler14 = e => {
    setFiles14(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChanges = e => {
    let myUser = { ...form }
    myUser.countryId = e.target.value
    setform(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const getAllcountries = () => {
    var token = datas
    axios
      .post(
        URL.allcountries,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setcount(res.data.countriesResult)
      })
  }

  const custid = sessionStorage.getItem("dataid")

  const getonecustomer = () => {
    var token = datas
    const params = {
      _id: custid,
    }

    axios
      .post(URL.viewcustomer, params, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        setform(res.data.customerResult)
      })
  }

  useEffect(() => {
    getAllcountries()
    getonecustomer()
  }, [])

  const history = useHistory()

  function handlenavigate() {
    history.push("/users-list")
  }

  const editcutomer = () => {
    var token = datas
    const userdoid = custid
    const dataArray = new FormData()
    dataArray.append("customerName", form.customerName)
    dataArray.append("phone", form.phone)
    dataArray.append("email", form.email)
    dataArray.append("status", form.status)
    dataArray.append("gender", form.gender)
    dataArray.append("dateOfBirth", form.dateOfBirth)
    dataArray.append("occupation", form.occupation)
    dataArray.append("occupationdetails", form.occupationdetails)
    dataArray.append("address", form.address)
    dataArray.append("residentStatus", form.residentStatus)
    dataArray.append("dlNumber", form.dlNumber)
    dataArray.append("countryId", form.countryId)
    dataArray.append("aadharNumber", form.aadharNumber)
    dataArray.append("voterIdNumber", form.voterIdNumber)
    // dataArray.append("countryId", form.countryId);
    dataArray.append("documentType", form.documentType)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("profileImg", Files[i])
    }
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("occupidImg", Files1[i])
    }
    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("dlFrontImg", Files2[i])
    }
    for (let i = 0; i < Files3.length; i++) {
      dataArray.append("dlBackImg", Files3[i])
    }

    for (let i = 0; i < Files4.length; i++) {
      dataArray.append("aadharFrontImg", Files4[i])
    }
    for (let i = 0; i < Files5.length; i++) {
      dataArray.append("aadharBackImg", Files5[i])
    }
    for (let i = 0; i < Files6.length; i++) {
      dataArray.append("voterIdFrontImg", Files6[i])
    }
    for (let i = 0; i < Files7.length; i++) {
      dataArray.append("voterIdBackImg", Files7[i])
    }
    for (let i = 0; i < Files8.length; i++) {
      dataArray.append("passportFrontImg", Files8[i])
    }
    for (let i = 0; i < Files9.length; i++) {
      dataArray.append("passportBackImg", Files9[i])
    }
    for (let i = 0; i < Files10.length; i++) {
      dataArray.append("photoidImg", Files10[i])
    }
    for (let i = 0; i < Files11.length; i++) {
      dataArray.append("visaImg", Files11[i])
    }
    for (let i = 0; i < Files12.length; i++) {
      dataArray.append("interPassportImg", Files12[i])
    }
    for (let i = 0; i < Files13.length; i++) {
      dataArray.append("interDlFrontImg", Files13[i])
    }
    for (let i = 0; i < Files14.length; i++) {
      dataArray.append("interDlBackImg", Files14[i])
    }

    axios
      .put(URL.editcustomer + "/" + userdoid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcustomer()
            console.log(res.data)
            setform("")
            setFiles("")
            clearForm()
            handlenavigate()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    editcutomer()
    clearForm()
    handlenavigate()
  }

  const clearForm = () => {
    setform({
      customerName: "",
      phone: "",
      email: "",
      status: "",
      gender: "",
      dateOfBirth: "",
      occupation: "",
      occupationdetails: "",
      address: "",
      residentStatus: "",
      dlNumber: "",
      countryId: "",
      documentType: "",
      aadharNumber: "",
      voterIdNumber: "",
    })
    setFiles({
      profileImg: "",
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Edit Customer" />
          <Row>
            <div>
              <Link to="/users-list">
                {" "}
                <Button style={{ float: "right" }} className="btn-info mb-3">
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Button>
              </Link>
            </div>
            <Form
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">
                                <i className="fas fa-user"></i>
                              </span>{" "}
                              Personal Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">
                                <i className="fas fa-shield-alt"></i>
                              </span>{" "}
                              Documents
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                                                    className={classnames({ current: activeTab === 3 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 3 })}
                                                        onClick={() => {
                                                            setactiveTab(3)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(3)}
                                                    >
                                                        <span className="number"><i className="fas fa-images"></i></span> Car Images
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 4 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 4 })}
                                                        onClick={() => {
                                                            setactiveTab(4)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(4)}
                                                    >
                                                        <span className="number"><i className="fas fa-money-check-alt"></i></span> PRICING
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 5 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 5 })}
                                                        onClick={() => {
                                                            setactiveTab(5)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(5)}
                                                    >
                                                        <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
                                                    </NavLink>
                                                </NavItem> */}
                        </ul>
                      </div>
                      <div className="content clearfix mt-2">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            <Row>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Full Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Name"
                                    
                                    value={form.customerName}
                                    name="customerName"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Mobile No{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter number"
                                    
                                    value={form.phone}
                                    name="phone"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Email <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter Email"
                                    
                                    value={form.email}
                                    name="email"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Gender{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  {/* <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="basicpill-firstname-input3"
                                                        placeholder="Enter DOB"
                                                        
                                                    /> */}
                                  <select
                                    value={form.gender}
                                    name="gender"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Date of Birth{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter DOB"
                                    
                                    name="dateOfBirth"
                                    value={form.dateOfBirth}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label
                                    className="mr-4"
                                    for="basicpill-firstname-input1"
                                  >
                                    Image
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                  <small
                                    className="text-danger ml-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      tog_small()
                                    }}
                                  >
                                    <i></i>{" "}
                                    <i className="fas fa-eye text-danger"></i>{" "}
                                    View
                                  </small>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Image"
                                    name="profileImg"
                                    value={form.profileImg}
                                    onChange={changeHandler}
                                  />
                                </div>
                              </Col>

                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Occupation{" "}
                                    <span className="text-danger">*</span>
                                  </Label>

                                  <select
                                    name="occupation"
                                    value={form.occupation}
                                    // onChange={handleSelectChange1}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="student">Student</option>
                                    <option value="employee">Employee</option>
                                    <option value="business">
                                      Businessman
                                    </option>
                                  </select>
                                </div>
                              </Col>
                              {form.occupation == "employee" ||
                              form.occupation == "business" ? (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input3">
                                      Company Name
                                      {/* <span className="text-danger">*</span> */}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-firstname-input3"
                                      placeholder="Enter Company"
                                      name="occupationdetails"
                                      value={form.occupationdetails}
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                </Col>
                              ) : (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input3">
                                      College Name
                                      {/* <span className="text-danger">*</span> */}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-firstname-input3"
                                      placeholder="Enter College"
                                      name="occupationdetails"
                                      value={form.occupationdetails}
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>

                            <Row>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label
                                    className="mr-4"
                                    for="basicpill-firstname-input1"
                                  >
                                    Occupation Id Card
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                  <small
                                    className="text-danger ml-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      tog_small1()
                                    }}
                                  >
                                    <i></i>{" "}
                                    <i className="fas fa-eye text-danger"></i>{" "}
                                    View
                                  </small>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Name"
                                    name="occupidImg"
                                    value={form.occupidImg}
                                    onChange={changeHandler1}
                                  />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Address
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter Address"
                                    name="address"
                                    value={form.address}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>

                                  <select
                                    
                                    name="status"
                                    value={form.status}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    {" "}
                                    <option value="">Select</option>
                                    <option value="true">Verified</option>
                                    <option value="false">Pending</option>
                                  </select>
                                  {/* <Input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Password"
                                                                        
                                                                        name="password"
                                                                        value={form.password}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    /> */}
                                </div>
                              </Col>

                              <Col md={3}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input3">
                                    Residents Type{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    name="residentStatus"
                                    value={form.residentStatus}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    
                                    // onChange={handleSelectChange}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="resident">Residents</option>
                                    <option value="nonResident">
                                      Non-Residents
                                    </option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}>
                            {form.residentStatus == "resident" ? (
                              <div>
                                {/* <Row>
                                                                    <Col>
                                                                    <img src={doc1} style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                    <Col>
                                                                    <img src={doc2}  style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                    <Col>
                                                                    <img src={doc3}  style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                </Row> */}

                                <Row className="mt-3">
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input1">
                                        Select Aadhar / vote / Passport
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {/* <Input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    id="basicpill-firstname-input1"
                                                                                    
                                                                                /> */}
                                      <select
                                        name="documentType"
                                        value={form.documentType}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                        // onChange={handleSelectChange3}
                                        className="form-select"
                                      >
                                        <option value="">Select</option>
                                        <option value="aadhar">Aadhar</option>
                                        <option value="voterId">Vote Id</option>
                                        <option value="passport">
                                          Passport
                                        </option>
                                      </select>
                                    </div>
                                  </Col>

                                  {form.documentType == "voterId" ? (
                                    <>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input1">
                                            Vote Id{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder=" Vote Id"
                                            
                                            name="voterIdNumber"
                                            value={form.voterIdNumber}
                                            onChange={e => {
                                              handleChange(e)
                                            }}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input3">
                                            Vote ID Front
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small4()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>

                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            name="voterIdFrontImg"
                                            value={form.voterIdFrontImg}
                                            onChange={changeHandler6}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input1">
                                            Vote ID Back
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small5()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>

                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            name="voterIdBackImg"
                                            value={form.voterIdBackImg}
                                            onChange={changeHandler7}
                                          />
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {form.documentType == "passport" ? (
                                    <>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input1">
                                            Passport Front
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small6()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>
                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            name="passportFrontImg"
                                            value={form.passportFrontImg}
                                            onChange={changeHandler8}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input3">
                                            Passport Back
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small7()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>
                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            name="passportBackImg"
                                            value={form.passportBackImg}
                                            onChange={changeHandler9}
                                          />
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {form.documentType == "aadhar" ? (
                                    <>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input1">
                                            Aadhar No.{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Aadhar No."
                                            
                                            name="aadharNumber"
                                            value={form.aadharNumber}
                                            onChange={e => {
                                              handleChange(e)
                                            }}
                                          />
                                        </div>
                                      </Col>

                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input1">
                                            Aadhar Front
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small2()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>

                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            name="aadharFrontImg"
                                            value={form.aadharFrontImg}
                                            onChange={changeHandler4}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <Label for="basicpill-firstname-input3">
                                            Aadhar Back
                                          </Label>
                                          <small
                                            className="text-danger ml-5"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              tog_small3()
                                            }}
                                          >
                                            <i></i>{" "}
                                            <i className="fas fa-eye text-danger"></i>{" "}
                                            View
                                          </small>

                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            name="aadharBackImg"
                                            value={form.aadharBackImg}
                                            onChange={changeHandler5}
                                          />
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {/* <Col md={3}>
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input3">
                                                                                    Passport Visa <span className="text-danger">*</span>
                                                                                </Label>
                                                                                <Input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    id="basicpill-firstname-input3"
                                                                                    
                                                                                />
                                                                            </div>
                                                                        </Col> */}
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Driving License Number
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        placeholder="license Number"
                                        
                                        name="dlNumber"
                                        value={form.dlNumber}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                    </div>
                                  </Col>

                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Driving License front
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small8()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="dlFrontImg"
                                        value={form.dlFrontImg}
                                        onChange={changeHandler2}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Driving License back
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small9()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="dlBackImg"
                                        value={form.dlBackImg}
                                        onChange={changeHandler3}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                            {form.residentStatus == "nonResident" ? (
                              <div>
                                <Row>
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input1">
                                        Countries{" "}
                                        <span className="text-danger">*</span>
                                      </Label>

                                      {/* <Select
                                                                                    defaultValue={selectedOption}
                                                                                    onChange={setSelectedOption}
                                                                                    options={options}
                                                                                /> */}

                                      <select
                                        name="countryId"
                                        value={form.countryId}
                                        onChange={e => {
                                          handleChanges(e)
                                        }}
                                        className="form-select"
                                      >
                                        <option value="">Select</option>
                                        {count.map((data, key) => {
                                          return (
                                            <option key={key} value={data._id}>
                                              {data.countryName}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Passport Copy
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small10()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="interPassportImg"
                                        value={form.interPassportImg}
                                        onChange={changeHandler12}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Id card front
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small11()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="photoidImg"
                                        value={form.photoidImg}
                                        onChange={changeHandler10}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        Visit Visa Copy
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small12()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="visaImg"
                                        value={form.visaImg}
                                        onChange={changeHandler11}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col md={3}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        International Driving License
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        placeholder="license Number"
                                        name="dlNumber"
                                        value={form.dlNumber}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        International Driving License Front
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small13()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="interDlFrontImg"
                                        value={form.interDlFrontImg}
                                        onChange={changeHandler13}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label for="basicpill-firstname-input3">
                                        International Driving License Back
                                      </Label>
                                      <small
                                        className="text-danger ml-5"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          tog_small14()
                                        }}
                                      >
                                        <i></i>{" "}
                                        <i className="fas fa-eye text-danger"></i>{" "}
                                        View
                                      </small>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="basicpill-firstname-input3"
                                        name="interDlBackImg"
                                        value={form.interDlBackImg}
                                        onChange={changeHandler14}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                              style={{ background: "#d05151" }}
                            >
                              <i className="fas fa-arrow-circle-left"></i>{" "}
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 2 ? "next logbtn" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next <i className="fas fa-arrow-circle-right"></i>
                            </Link>
                          </li>
                          <li
                            className={
                              activeTab < 2 ? "previous logbtn " : "previous"
                            }
                          >
                            <button
                              to="#"
                              className="btn btn-primary"
                              type="submit"
                            >
                              <i className="fas fa-check-circle"></i> Submit
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Form>
          </Row>
        </Container>

        <Modal
          size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.profilePic}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.occupationIdCard}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small2}
          toggle={() => {
            tog_small2()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.aadharFront}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small3}
          toggle={() => {
            tog_small3()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small3(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.aadharBack}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small4}
          toggle={() => {
            tog_small4()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small4(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.voterIdFront}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small5}
          toggle={() => {
            tog_small5()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small5(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.voterIdBack}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small6}
          toggle={() => {
            tog_small6()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small6(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.passportFront}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small7}
          toggle={() => {
            tog_small7()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small7(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.passportBack}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small8}
          toggle={() => {
            tog_small8()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small8(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.dlFront}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small9}
          toggle={() => {
            tog_small9()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small9(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.dlBack}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small10}
          toggle={() => {
            tog_small10()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small10(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.interPassport}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small11}
          toggle={() => {
            tog_small11()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small11(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.photoIdProof}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small12}
          toggle={() => {
            tog_small12()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small12(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.visaCopy}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small13}
          toggle={() => {
            tog_small13()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small13(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.interDlFront}
            />
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small14}
          toggle={() => {
            tog_small14()
          }}
          centered
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small14(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%" }}
              src={"https://api.freedomcars.co.in/" + form.interDlFront}
            />
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Editusers
