import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination, TabContent, TabPane, FormGroup,
    PaginationItem, FormFeedback,
    PaginationLink, Modal, Nav, NavItem, NavLink,
} from "reactstrap"
import classnames from "classnames";
import "../../../src/common.css"
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import user1 from "../../assets/images/users/avatar-1.jpg";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useSelector, useDispatch } from "react-redux";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import passport from "../../assets/images/latest/passport.jfif";
import card1 from "../../assets/images/latest/card1.jpeg";
import card2 from "../../assets/images/latest/card2.jpeg";
import visa from "../../assets/images/latest/visa.jpg";
import emirate from "../../assets/images/latest/emirate.jpg";



const Addusers = () => {

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState("2");

    const radios = [
        { name: 'deactive', value: '1' },
        { name: 'Active', value: '2' },
    ];



    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";


    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    const reportstest = [
        // {
        //   title: "Total Users",
        //   icon: "mdi mdi-bitcoin",
        //   color: "warning",
        //   value: "$ 57,986.76",
        //   // desc: "+ 0.0012 ( 0.2 % )",
        //   series: series1,
        //   options: options1,
        //   arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
        // },
        {
            title: "Total Vehicle ",
            icon: "bx bxs-car",
            color: "primary",
            value: "20",
            // desc: "- 4.102 ( 0.1 % )",
            //   series: series2,
            //   options: options2,
            arrowUpDown: "mdi mdi-arrow-down ms-1 text-danger",
        },
        {
            title: "Upcoming Vehicle ",
            icon: "bx bxs-car",
            color: "info",
            value: "22",
            // desc: "+ 1.792 ( 0.1 % )",
            //   series: series3,
            //   options: options3,
            arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
        },
        {
            title: "Running Vehicle ",
            icon: "bx bxs-car",
            color: "warning",
            value: " 86",
            // desc: "+ 0.0012 ( 0.2 % )",
            //   series: series1,
            //   options: options1,
            arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
        },
        {
            title: "Completed Vehicle ",
            icon: "bx bxs-car",
            color: "success",
            value: " 57",
            // desc: "+ 0.0012 ( 0.2 % )",
            //   series: series4,
            //   options: options4,
            arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
        },
    ]

    const dispatch = useDispatch();

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [idx, setidx] = useState(1);

    const { error, success } = useSelector(state => ({
        error: state.Profile.error,
        success: state.Profile.success,
    }));

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName);
                setemail(obj.email);
                setidx(obj.uid);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username);
                setemail(obj.email);
                setidx(obj.uid);
            }
            setTimeout(() => {
                dispatch(resetProfileFlag());
            }, 3000);
        }
    }, [dispatch, success]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: name || '',
            idx: idx || '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your UserName"),
        }),
        onSubmit: (values) => {
            dispatch(editProfile(values));
        }
    });



    const [activeTab1, setactiveTab1] = useState("5");
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Driver Details" />

                    <Row>
                        <div >
                            <Link to="/driver-list"><Button className="mb-3" style={{ float: "right" }} color="info"><i className="far fa-arrow-alt-circle-left"></i> Back</Button></Link>
                        </div>
                        <Col md={3} >
                            <Card>
                                {/* <CardHeader className="bg-white">
                                    <CardTitle>Add Users</CardTitle>
                                </CardHeader> */}
                                <CardBody >
                                    {/* <Form>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Branch <span className="text-danger">*</span>
                                            </Label>
                                          
                                            <select className="form-select" required>
                                                <option value="">Select</option>
                                                <option value="Dubai">Dubai</option>
                                                <option value="AbuDhabi">Abu Dhabi</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Department <span className="text-danger">*</span>
                                            </Label>
                                          
                                            <select className="form-select" required>
                                                <option value="">Select</option>
                                                <option value="Dubai">Manager</option>
                                                <option value="AbuDhabi">Employee</option>
                                               
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Name <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter Name"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Email <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Phone No <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Phone No"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Password <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Password"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input2">
                                                Address <span className="text-danger">*</span>
                                            </Label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input2"
                                                placeholder="Enter  Address"
                                                required
                                            />
                                        </div>
                                        <div style={{ float: "right" }}>
                                            <Button color="primary" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>

                                        </div>
                                    </Form> */}

                                    <img style={{ width: "190px" }} src={user1} />
                                    <h5 className="mt-3">Asha</h5>
                                    <p>Joined At 23 Nov, 2022 07:14 AM</p>


                                    <div className="mt-3">
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem >
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "5",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("5");
                                                    }}
                                                >
                                                    Details
                                                </NavLink>
                                            </NavItem>
                                            <NavItem  >
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "6",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("6");
                                                    }}
                                                >
                                                    Documents
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9}>
                            <Row>
                                {reportstest.map((report, key) => (
                                    <Col sm="3" key={key}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs="3">
                                                        <p className="text-muted mb-4">
                                                            <i
                                                                className={
                                                                    report.icon +
                                                                    " h2 text-" +
                                                                    report.color +
                                                                    " align-middle mb-0 me-3"
                                                                }
                                                            />{" "}
                                                            {/* {report.title}{" "} */}
                                                        </p>
                                                    </Col>
                                                    <Col xs="9">

                                                        <div>

                                                            <h5 className="text-center mt-2">{report.value}</h5>

                                                            {/* <p className="text-muted text-truncate mb-0">
                            {report.desc}
                          </p> */}
                                                        </div>
                                                    </Col>
                                                    <p>{report.title}</p>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}

                            </Row>

                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Information of Asha</CardTitle>
                                </CardHeader>
                                <CardBody >

                                    <TabContent activeTab={activeTab1} className="p-1 text-muted">
                                        <TabPane tabId="5">
                                            <div>
                                                <Row>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col>
                                                                <p><b>Name</b></p>
                                                                <p><b>Mobile No </b></p>
                                                                <p><b>Email </b></p>

                                                            </Col>
                                                            <Col>
                                                                <p>: Asha</p>
                                                                <p>: 86558965456</p>
                                                                <p>: Asha@gmail</p>
                                                               

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col>
                                                                
                                                            <p><b>Gender </b></p>
                                                                <p><b>Occupation</b></p>
                                                                <p><b>Resident Type</b></p>
                                                                {/* <p><b>Address </b></p> */}
                                                                {/* <p><b>Gender </b></p>
                                                    <p><b>Date of Birth  </b></p> */}
                                                            </Col>
                                                            <Col>
                                                                
                                                            <p>: Female</p>
                                                                <p>: Employee</p>
                                                                <p>: Residents</p>
                                                                {/* <p> : 403, Manjeera Majestic Commercial, JNTU - Hitech City Road, K P H B Phase 1, Kukatpally,</p> */}
                                                                {/* <p>Female</p> */}
                                                                {/* <p>26-8-2000</p> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input3">
                                                                Address
                                                            </Label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                id="basicpill-firstname-input3"
                                                                placeholder="Enter Address"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col md={3}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input3">
                                                                City
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="basicpill-firstname-input3"
                                                                placeholder="Enter City"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input3">
                                                                State
                                                                {/* <span className="text-danger">*</span> */}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="basicpill-firstname-input3"
                                                                placeholder="Enter State"

                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1">
                                                                Zip/Postal
                                                                {/* <span className="text-danger">*</span> */}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="basicpill-firstname-input1"
                                                                placeholder="Enter Zip/Postal"

                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input3">
                                                                Country
                                                            </Label>
                                                            <select required className="form-select">
                                                                <option value="">Select</option>
                                                                <option value="Afghanistan">Afghanistan</option>
                                                                <option value="Albania">Albania</option>
                                                                <option value="Algeria">Algeria</option>
                                                                <option value="Australia">Australia</option>
                                                                <option value="Australia">Australia</option>
                                                                <option value="Islands">Aland Islands</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col md={4}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1">
                                                                Status <span className="text-danger">*</span>
                                                            </Label><br />
                                                            <select className="form-select">
                                                                <option value="Active">Active</option>
                                                                <option value="deactive">In-active</option>
                                                            </select>

                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1">
                                                                Email Verification <span className="text-danger">*</span>
                                                            </Label>
                                                            <select className="form-select">
                                                                <option value="Verified">Verified</option>
                                                                <option value="Unverified">Unverified</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1">
                                                                SMS Verification <span className="text-danger">*</span>
                                                            </Label>
                                                            <select className="form-select">
                                                                <option value="Verified">Verified</option>
                                                                <option value="Unverified">Unverified</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="mt-3" style={{ float: "right" }}>
                                                    <Link to="/driver-list"> <button style={{ width: "120px" }} className="btn btn-danger m-1">Cancel <i className="fas fa-times-circle"></i> </button></Link>
                                                    <button style={{ width: "120px" }} className="btn btn-success m-1">Submit <i className="fas fa-check-circle"></i></button>

                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="6">


                                            <Row>
                                                <Col md={4}>
                                                    <small>Passport Front</small><br />
                                                    <img onClick={tog_small} src={passport} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                                <Col md={4}>
                                                    <small>Passport Back</small><br />
                                                    <img onClick={tog_small} src={passport} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                                <Col md={4}>
                                                    <small>Passport Visa</small><br />
                                                    <img onClick={tog_small} src={visa} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <small>Passport Emirates IDEmirates ID Front</small><br />
                                                    <img onClick={tog_small} src={emirate} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                                <Col md={4}>
                                                    <small>Passport Emirates ID Back</small><br />
                                                    <img onClick={tog_small} src={emirate} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                                <Col md={4}>
                                                    <small>UAE Driving License Front</small><br />
                                                    <img onClick={tog_small} src={card2} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>

                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <small>UAE Driving License Back</small><br />
                                                    <img onClick={tog_small} src={card1} style={{ width: "200px", height: "150px", cursor: "pointer" }} />
                                                </Col>
                                            </Row>


                                        </TabPane>
                                    </TabContent>

                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="sm"
                    centered
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                     
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <div>
                                <img src={passport} style={{ width: "100%", height: "350px", cursor: "pointer" }} />

                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Addusers
