import React, { useState } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
import "../../../src/common.css"
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Driverlist = () => {

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";


    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Drivers" />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader className="bg-white">
                                    {/* <CardTitle>Users List</CardTitle> */}
                                </CardHeader>
                                <div className="container">
                                    <Input type="search" style={{ width: "40%", float: "right" }} className="form-control" placeholder="Search.." />
                                </div>
                                <CardBody >

                                    <div>
                                        <div className="table-responsive mb-4">

                                            <Table className="table table-bordered mb-3">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th>S No</th>
                                                        <th>Driver</th>
                                                        <th>Email-Phone </th>
                                                        <th>Country</th>
                                                        <th>Joined At</th>
                                                        <th>Status</th>
                                                         <th style={{ width: "130px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center">
                                                        <th scope="row">1</th>
                                                        <td>balakrishna</td>
                                                        <td>
                                                            <small><b>Asha@gmail.com</b></small><br/>
                                                            <small>9189100099</small>
                                                        </td>
                                                        <td>NL</td>
                                                        <td>2022-11-23 07:14 AM</td>
                                                        <td ><span style={{borderRadius:"5px"}} className="bg-success text-white ">Active</span></td>
                                                      
                                                        <td>
                                                           <Link to="/edit-driver"> <Button 
                                                            // onClick={() => {
                                                            //     tog_small();
                                                            // }}
                                                                className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                <i className="bx bx-edit "></i>
                                                            </Button></Link>
                                                            <Link to="/view-driver"> <Button style={{ padding: "6px", margin: "3px" }} color="warning" outline>
                                                                <i  className="fas fa-eye text-dark"></i>
                                                            </Button></Link>
                                                            <Button style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                <i className="bx bx-trash"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div style={{ float: "right" }}>
                                            <Pagination
                                                size="sm"
                                                aria-label="Page navigation example"
                                            >
                                                <PaginationItem disabled>
                                                    <PaginationLink href="#" tabIndex="-1">
                                                        Previous
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink href="#">1</PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink href="#">2</PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink href="#">3</PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink href="#">Next</PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Users
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Branch <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Dubai</option>
                                            <option value="AbuDhabi">Abu Dhabi</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Phone No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Phone No"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Department <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Manager</option>
                                            <option value="AbuDhabi">Employee</option>
                                            {/* <option value="AbuDhabi">Driver</option> */}
                                            {/* <option value="AbuDhabi">Customer</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Email <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="emial"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Password <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Password"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div>
                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="button">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Driverlist
