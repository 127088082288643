import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, CardHeader, Input,Table } from "reactstrap"
import MiniWidget from "./mini-widget"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import LatestTranaction from "./LatestTranaction"
import Breadcrumbs from "../../components/Common/Breadcrumb";

import ApexRadial from "./ApexRadial"
import { URL } from "../../Apiurl";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Dashboard = () => {
  //meta title
  // document.title = "Forget Password | Skote - React Admin & Dashboard Template"
  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]

 

  const series1 = [
    { name: "RB", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
  ]
  const options1 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#f1b44c"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
  }

  //Etherium Chart
  const series2 = [
    { name: "TB", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
  ]
  const options2 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#1A374D"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
  }

  //LiteCoin Chart
  const series3 = [
    { name: "UB", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
  ]
  const options3 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#50a5f1"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
  }

  const series4 = [
    { name: "CB", data: [35, 53, 13, 27, 54, 75, 47, 75, 65, 19, 14] },
  ]
  const options4 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#34c38f"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
  }


  const [cars1,setcars1]=useState()
  const [cars2,setcars2]=useState()
  const [cars3,setcars3]=useState()
  const [cars4,setcars4]=useState()
  const [cars5,setcars5]=useState([])
  const [cars6,setcars6]=useState([])


  const [cars7,setcars7]=useState([])

  var gets = localStorage.getItem("authUser");
  var data = JSON.parse(gets);
  var datas = data.token;
  console.log(datas)

  const getdeshdata = () => {
    var token = datas;
    const dataArray = new FormData();
    dataArray.append("docLimit", "");
    axios.post(URL.dashdata, dataArray, {
        headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
        console.log(res.data)
        setcars1(res.data.totaBooking)
        setcars2(res.data.upcomingBooking)
        setcars3(res.data.runningBooking)
        setcars4(res.data.completedBooking)
        setcars5(res.data.bookingPiChart)
        setcars6(res.data.bookingList)
        setcars7(res.data.canceledBooking)
    })
}

useEffect(()=>{
  getdeshdata()
},[])



  const reportstest = [
    // {
    //   title: "Total Users",
    //   icon: "mdi mdi-bitcoin",
    //   color: "warning",
    //   value: "$ 57,986.76",
    //   // desc: "+ 0.0012 ( 0.2 % )",
    //   series: series1,
    //   options: options1,
    //   arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    // },
    {
      title: "Total Bookings ",
      icon: "bx bxs-car",
      color: "primary",
      value: cars1,
      // desc: "- 4.102 ( 0.1 % )",
      series: series2,
      options: options2,
      arrowUpDown: "mdi mdi-arrow-down ms-1 text-danger",
    },
    {
      title: "Upcoming Bookings ",
      icon: "bx bxs-car",
      color: "info",
      value: cars2,
      // desc: "+ 1.792 ( 0.1 % )",
      series: series3,
      options: options3,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    {
      title: "Running Bookings ",
      icon: "bx bxs-car",
      color: "warning",
      value: cars3,
      // desc: "+ 0.0012 ( 0.2 % )",
      series: series1,
      options: options1,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    {
      title: "Completed Bookings ",
      icon: "bx bxs-car",
      color: "success",
      value: cars4,
      // desc: "+ 0.0012 ( 0.2 % )",
      series: series4,
      options: options4,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    // {
    //   title: "Completed Bookings ",
    //   icon: "bx bxs-car",
    //   color: "success",
    //   value: cars4,
    //   // desc: "+ 0.0012 ( 0.2 % )",
    //   series: series4,
    //   options: options4,
    //   arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    // },
  ]

  const series = [cars5[0], cars5[1],cars5[2],cars5[3] ]
  const options = {
    labels: ["Upcoming Booking", "Running Booking", "Completed Booking", " Cancelled Booking"],
    colors: ["#e5701a", "#1a374d", "#2ec609", "#ff0000"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }


  const [forms, setforms] = useState([]);
  console.log(forms)

  const handlechange = (e) => {
      let myUser = { ...forms };
      myUser[e.target.name] = e.target.value;
      setforms(myUser);
      console.log(myUser)
      var token = datas;
      axios
          .post(
              `https://api.freedomcars.co.in/v1/admin/getadmindashitems?searchQuery=${e.target.value}`,
              {},

              {
                  headers: { Authorization: `Bearer ${token}` },
              }
          )
          .then((res) => {
            setcars1(res.data.totaBooking)
            setcars2(res.data.upcomingBooking)
            setcars3(res.data.runningBooking)
            setcars4(res.data.completedBooking)
            setcars5(res.data.bookingPiChart)
            setcars6(res.data.bookingList)
          });
  };


  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = cars6.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(cars6.length / listPerPage);
  const changePage = ({ selected }) => {
      setPageNumber(selected);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboards" />


          {/* <h5 className="mt-3">Bookings</h5> */}
          <Row>
            {reportstest.map((report, key) => (
              <Col sm="3" key={key}>
                <Card>
                  <CardBody>
                    <p className="text-muted mb-4">
                      <i
                        className={
                          report.icon +
                          " h2 text-" +
                          report.color +
                          " align-middle mb-0 me-3"
                        }
                      />{" "}
                      {report.title}{" "}
                    </p>

                    <Row>
                      <Col xs="6">
                        <div>
                          <h5 className="mt-3">{report.value}</h5>
                          {/* <p className="text-muted text-truncate mb-0">
                            {report.desc}
                          </p> */}
                        </div>
                      </Col>
                      <Col xs="6">
                        <div>
                          <div>
                            <ReactApexChart
                              options={report.options}
                              series={report.series}
                              type="area"
                              height={40}
                              className="apex-charts"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Booking Analytics</h4>

                  <div>
                    <div id="donut-chart">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height={260}
                        className="apex-charts"
                      />
                    </div>
                  </div>

                  <div className="text-center text-muted">
                    <Row>
                      <Col xs="3">
                        <div className="mt-4">
                          <p className="mb-2 text-truncate">
                            <i style={{color:"#e5701a"}} className="mdi mdi-circle  me-1" />{" "}
                            Upcoming Booking
                          </p>
                          <h5>{cars5[0]}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="mt-4">
                          <p className="mb-2 text-truncate">
                            <i style={{color:"#1a374d"}} className="mdi mdi-circle  me-1" />{" "}
                            Running Booking
                          </p>
                          <h5>{cars5[1]}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="mt-4">
                          <p className="mb-2 text-truncate">
                            <i style={{color:"#2ec609"}} className="mdi mdi-circle  me-1" />{" "}
                            Completed Booking
                          </p>
                          <h5>{cars5[2]}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="mt-4">
                          <p className="mb-2 text-truncate">
                            <i style={{color:"Red"}} className="mdi mdi-circle  me-1" />{" "}
                            Cancelled Booking
                          </p>
                          <h5>{cars7}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Monthly Earning</CardTitle>
                  <Row>
                    <Col sm="6">
                      <p className="text-muted">This month</p>
                      <h3>$34,252</h3>
                      <p className="text-muted">
                        <span className="text-success me-2">
                          {" "}
                          12% <i className="mdi mdi-arrow-up"></i>{" "}
                        </span>{" "}
                        From previous period
                      </p>
                      <div className="mt-4">
                        <Link to="" className="btn btn-primary  btn-sm">
                          View More <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="mt-4 mt-sm-0">
                        <ApexRadial />
                      </div>
                    </Col>
                  </Row>
                  <p className="text-muted mb-0">
                    We craft digital, graphic and dimensional thinking.
                  </p>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <Col md={12}>


            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Latest Bookings</CardTitle>
                                </CardHeader>

                                <CardBody >

                                    <div>
                                        <div className="table-responsive">
                                            <div style={{ float: "right" }}>
                                                <Input type="text"
                                                name="search"
                                                value={forms.search}
                                                onChange={handlechange}
                                                className="form-control" placeholder="Search.." />
                                            </div>
                                            <Table className="table table-bordered mb-4 mt-5">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th>S No</th>
                                                        <th>Booking Id</th>
                                                        <th>Customer Name</th>
                                                        <th>Email</th>
                                                        <th>Car</th>
                                                        <th>Date</th>
                                                        <th>Price </th>
                                                        <th>Booking Status</th>
                                                        {/* <th style={{ width: "100px" }}>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((data, key) => (
                                                            <tr className="text-center" key={key}>
                                                                <td>{(pageNumber - 1) * 10 + key + 11}</td>
                                                                <td>{data.booking_id}</td>
                                                                <td>{data.customerName}</td>
                                                                <td>{data.email}</td>
                                                                <td>{data.carModelName}</td>
                                                                <td>{data.date}</td>
                                                                <td>{data.totalprice}</td>
                                                                <td>
                                                                {data.status == "pending" ? (
                                                                    <a className="text-warning">{data.status}</a>
                                                                ) : ""}
                                                                {data.status == "accepted" ? (
                                                                    <a  className="text-primary ">{data.status}</a>
                                                                ) : ""}
                                                                {data.status == "cancelled" ? (
                                                                    <a  className="text-danger">{data.status}</a>
                                                                ) : ""}
                                                                {data.status == "completed" ? (
                                                                    <a  className="text-success">{data.status}</a>
                                                                ) : ""}
                                                            </td>
                                                              
                                                            </tr>

                                                        ))}

                                                </tbody>
                                            </Table>
                                          
                                            <div className="mt-3" style={{ float: "right" }}>
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
              {/* <LatestTranaction /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
