import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  CardHeader,
  CardTitle,
  CardSubtitle,
  Modal,
} from "reactstrap"
import Select from "react-select"

import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import car1 from "../assets/images/latest/car1.jpg"
import car2 from "../assets/images/latest/car2.jpg"
import car3 from "../assets/images/latest/car3.jpg"
import car4 from "../assets/images/latest/car4.jpg"
import car5 from "../assets/images/latest/car5.jpg"
import car6 from "../assets/images/latest/car6.jpg"
import card1 from "../assets/images/latest/card1.jpeg"
import card2 from "../assets/images/latest/card2.jpeg"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"
import Badge from "react-bootstrap/Badge"

const options = [
  { value: "Luxury", label: "Luxury" },
  { value: "LuxurySUV", label: "Luxury SUV" },
  { value: "SUV", label: "SUV" },
  { value: "Sedan", label: "Sedan" },
  { value: "Hatchback", label: "Hatchback" },
  { value: "Crossover", label: "Crossover" },
]
const options1 = [
  { value: "AlfaRomeo", label: "Alfa Romeo" },
  { value: "Audi", label: "Audi" },
  { value: "Toyota", label: "Toyota" },
  { value: "BMW", label: "BMW" },
  { value: "Bugatti", label: "Bugatti" },
]
const options2 = [
  { value: "Giulietta", label: "Giulietta" },
  { value: "Brera", label: "Brera" },
  { value: "Giulietta", label: "Giulietta" },
  { value: "Spider", label: "Spider" },
  { value: "Stelvio", label: "Stelvio" },
]

const Cars = () => {
  //meta title
  // document.title = "Freedom Cars ";
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOption1, setSelectedOption1] = useState(null)
  const [selectedOption2, setSelectedOption2] = useState(null)

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [showResults, setShowResults] = React.useState(false)
  const [editResults, seteditResults] = React.useState(false)
  const showfield = () => setShowResults(true)
  const hidefield = () => setShowResults(false)
  const hideeditfield = () => seteditResults(false)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const [value, setValue] = useState(false)

  console.log(value)

  const [isDisabled, setIsDisabled] = useState(true)
  const handleClick = () => {
    setIsDisabled(!isDisabled)
  }

  const [isDisabled1, setIsDisabled1] = useState(true)
  const handleClick1 = () => {
    setIsDisabled1(!isDisabled1)
  }
  const [isDisabled2, setIsDisabled2] = useState(true)
  const handleClick2 = () => {
    setIsDisabled2(!isDisabled2)
  }
  const [isDisabled3, setIsDisabled3] = useState(true)
  const handleClick3 = () => {
    setIsDisabled3(!isDisabled3)
  }
  const [isDisabled4, setIsDisabled4] = useState(true)
  const handleClick4 = () => {
    setIsDisabled4(!isDisabled4)
  }

  const [isDisabled5, setIsDisabled5] = useState(true)
  const handleClick5 = () => {
    setIsDisabled4(!isDisabled4)
  }

  const [selectedFiles, setselectedFiles] = useState([])
  // console.log(selectedFiles)
  const [selectedFiles1, setselectedFiles1] = useState([])
  // console.log(selectedFiles1)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [fieldshow, setfieldshow] = useState()

  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }

  const [cars, setcars] = useState([])
  const [spce, setspce] = useState([])
  const [fut, setfut] = useState([])
  const [model1, setmodel1] = useState([])
  const [model, setmodel] = useState([])

  const [form, setform] = useState([])
  const [formS0, setformS0] = useState([])

  const [form1, setform1] = useState([])
  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")
  const [vers, setvers] = useState([])
  const [versSeat, setversSeat] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  // console.log(datas)

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    version()
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleChange1s = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const getAllcars = () => {
    var token = datas
    axios
      .post(
        URL.allcars,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        setcars(res.data.carsResult)
        // setform0(res.data.isPopular)
        // console.log(res.data)
        // setform01(res.data.isFeatured)
      })
  }

  const getactivebrand = () => {
    var token = datas
    axios
      .post(
        URL.activecarbrands,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        setmodel1(res.data.activecarBrandResult)
      })
  }

  const getAllmodal = () => {
    var token = datas
    axios
      .post(
        URL.allcaractivemodels,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        // setmodel(res.data.modelResult)
      })
  }

  const getAllfeature = () => {
    var token = datas
    axios
      .post(
        URL.activefeature,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        setfut(res.data.featuresResult)
      })
  }

  const getAllspfc = () => {
    var token = datas
    axios
      .post(
        URL.activecarspecs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        setspce(res.data.activeSpecs)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser._id = e.target.value
    setform(myUser)
    getAllmodals(e.target.value)
  }

  const Optionchange12 = e => {
    let myUser = { ...formS0 }
    myUser._id = e.target.value
    setformS0(myUser)
    getversons(e.target.value)
  }

  const getAllmodals = _id => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carType", form.carType)
    dataArray.append("brandId", _id)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carmodel/getcarmodelbytypebrand",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // console.log(res.data)
        setmodel(res.data.modelsResult)
      })
  }

  const getversons = _id => {
    var token = datas
    const dataArray = new FormData()
    // dataArray.append("carType", form.carType);
    dataArray.append("modelId", _id)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carversion/getcarversionbymodelid",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data.noOfSeats)
        setvers(res.data.carversions)
        setversSeat(res.data.noOfSeats)
      })
  }

  const priceArr = [
    {
      sixHoursprice:
        form1.sixHoursprice != undefined ? form1.sixHoursprice : "",
      kms: form1.kms != undefined ? form1.kms : "",
      tweleveHoursprice:
        form1.tweleveHoursprice != undefined ? form1.tweleveHoursprice : "",
      onedayPrice: form1.onedayPrice != undefined ? form1.onedayPrice : "",
      unlimitedStatus: form1.unlimitedStatus,
    },
    {
      sixHoursprice:
        form1.sixHoursprice1 != undefined ? form1.sixHoursprice1 : "",
      kms: form1.kms1 != undefined ? form1.kms1 : "",
      tweleveHoursprice:
        form1.tweleveHoursprice1 != undefined ? form1.tweleveHoursprice1 : "",
      onedayPrice: form1.onedayPrice1 != undefined ? form1.onedayPrice1 : "",
      unlimitedStatus: form1.unlimitedStatus1,
    },
    {
      sixHoursprice:
        form1.sixHoursprice2 != undefined ? form1.sixHoursprice2 : "",
      kms: form1.kms2 != undefined ? form1.kms2 : "",
      tweleveHoursprice:
        form1.tweleveHoursprice2 != undefined ? form1.tweleveHoursprice2 : "",
      onedayPrice: form1.onedayPrice2 != undefined ? form1.onedayPrice2 : "",
      unlimitedStatus: form1.unlimitedStatus2,
    },
    {
      sixHoursprice:
        form1.sixHoursprice3 != undefined ? form1.sixHoursprice3 : "",
      kms: form1.kms3 != undefined ? form1.kms3 : "",
      tweleveHoursprice:
        form1.tweleveHoursprice3 != undefined ? form1.tweleveHoursprice3 : "",
      onedayPrice: form1.onedayPrice3 != undefined ? form1.onedayPrice3 : "",
      unlimitedStatus: form1.unlimitedStatus3,
    },
    {
      sixHoursprice:
        form1.sixHoursprice4 != undefined ? form1.sixHoursprice5 : "",
      kms: form1.kms4 != undefined ? form1.kms4 : "",
      tweleveHoursprice:
        form1.tweleveHoursprice4 != undefined ? form1.tweleveHoursprice4 : "",
      onedayPrice: form1.onedayPrice4 != undefined ? form1.onedayPrice4 : "",
      unlimitedStatus: form1.unlimitedStatus4,
    },
    {
      sixHoursprice: form1.sixHoursprice5,
      kms: form1.kms5 != undefined ? form1.kms5 : "",
      tweleveHoursprice: form1.tweleveHoursprice5,
      onedayPrice: form1.onedayPrice5 != undefined ? form1.onedayPrice5 : "",
      unlimitedStatus: form1.unlimitedStatus5,
    },
  ]

  const datass = JSON.stringify(priceArr)
  console.log(URL.addcars)

  const addnewcar = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carType", form.carType)
    dataArray.append("vehicleType", form.vehicleType)
    dataArray.append("carBrandId", form._id)
    dataArray.append("carModelId", formS0._id)
    dataArray.append("carMakeYearId", form.carMakeYear)
    dataArray.append("carRegistNumber", form.carRegistNumber)
    dataArray.append("carSpecId", form.carSpecId)
    dataArray.append("carBootCapacity", form.carBootCapacity)
    dataArray.append("carColorIds", form.carColorAvailble)
    dataArray.append("carReading", form.carReading)
    dataArray.append("insurance", form.insurance)
    dataArray.append("priceArr", datass)

    for (let i = 0; i < selectedOptions.length; i++) {
      dataArray.append("carFeatureId", selectedOptions[i].value)
    }
   
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("carImages", Files[i])
    }
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("carRegisterImages", Files1[i])
    }
    axios
      .post(URL.addcars, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            hidefield()
            getAllcars()
            setform("")
            setform1("")
            setFiles("")
            setTimeout(() => {
              window.location.reload(false)
              toast(res.data.message)
            }, 200)
            console.log("page to reload")
           
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Handlesubmit = e => {
    e.preventDefault()
    addnewcar()
  }

  useEffect(() => {
    getAllcars()
    getcolor()
    getactivebrand()
    getAllmodal()
    getAllfeature()
    getAllspfc()
  }, [])

  const optionGroup123 = [
    {
      label: "Colors",
      options: [
        { label: "Beige", value: "Beige" },
        { label: "Black", value: "Black" },
        { label: "Blue", value: "Blue" },
        { label: "Brown", value: "Brown" },
        { label: "DarkGray", value: "Dark Gray" },
      ],
    },
  ]

  const optionGroup1 = fut.map(response => ({
    value: response._id,
    label: response.featureName,
  }))
  // console.log(optionGroup1);

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    ///setSelectedOptions(current => [...current, details.value]);
    setSelectedOptions(details)
    // console.log(details);
  }

  const [selectedMulti1, setselectedMulti1] = useState([])
  // console.log(selectedMulti1)
  function handleMulti(data12) {
    setselectedMulti1(data12)
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = cars.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(cars.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const [form0, setform0] = useState([])

  const [form01, setform01] = useState(true)
  // console.log(form0)

  // const handlechangescar=(e)=>{
  //   let myUser = {...form0}
  //   myUser[e.target.name] = e.target.value;
  //   setform0(myUser)
  //   populercars(e)
  // }

  // const handlechangescar = data => {
  //   let myUser = { ...form0 };
  //   myUser[e.target.name] = (!form0);
  //   var valu = data.isPopular
  //   setform0(!valu)
  //   populercars(data)
  // }

  const handlechangescar = (e, data) => {
    let myUser = { ...data }
    myUser[e.target.name] = e.target.value
    setdata(data)
    populercars(e)
  }

  const handlechangescarf = data => {
    // let myUser = { ...form0 };
    // myUser[e.target.name] = (!form0);
    var valu = data.isFeatured ? false : true
    setform01(!valu)
    Featurcars(data)
  }

  const history = useHistory()

  function handleClick123() {
    history.push("/editcar")
  }
  function handleClick1234() {
    history.push("/car-details")
  }

  const getdata = data => {
    sessionStorage.setItem("carid", data._id)
    // history.push("/edituser")
    // <Redirect to='/edituser' />
    handleClick123()
  }
  const getdata1 = data => {
    sessionStorage.setItem("carid", data._id)
    // history.push("/edituser")
    // <Redirect to='/edituser' />
    handleClick1234()
  }

  const [errorObject, seterrorObject] = useState({
    carType: "",
    _id: "",
    carRegistNumber: "",
    vehicleType: "",
    carModelId: "",
    carMakeYear: "",
    carColorAvailble: "",
    carSpecId: "",
    carBootCapacity: "",
    carFeatureId: "",
  })
  const [er, seter] = useState({
    carType: "",
    carRegistNumber: "",
    _id: "",
    vehicleType: "",
    carModelId: "",
    carMakeYear: "",
    carColorAvailble: "",
    carSpecId: "",
    carBootCapacity: "",
    carFeatureId: "",
  })
  // console.log(console.errorObject)

  // const [errorObject1, seterrorObject1] = useState({ });
  // const [er1, seter1] = useState({ "carSpecId": "","carBootCapacity":"","carFeatureId":"" });

  const validateFun = e => {
    // console.log(form);
    if (form.carType == null) {
      let error = { ...er }
      error["carType"] = "Please Select Car Type"
      seterrorObject(error)
    } else if (form._id == null) {
      let error = { ...er }
      error["_id"] = "Please Select Car brand"
      seterrorObject(error)
    } else if (form._id == null) {
      let error = { ...er }
      error["_id"] = "Please Select Model"
      seterrorObject(error)
    } else if (form.carMakeYear == null) {
      let error = { ...er }
      error["carMakeYear"] = "Please Select Year"
      seterrorObject(error)
    } else if (form.vehicleType == null) {
      let error = { ...er }
      error["vehicleType"] = "Please Select M/A/B "
      seterrorObject(error)
    } else if (form.carRegistNumber == null) {
      let error = { ...er }
      error["carRegistNumber"] = "Please Select Car registration Number "
      seterrorObject(error)
    } else {
      let error = { ...er }
      seterrorObject(error)
      toggleTab(activeTab + 1)
    }
  }

  const validateFun1 = e => {
    // console.log(form);
    if (form.carSpecId == null) {
      let error = { ...er }
      error["carSpecId"] = "Please Select Car Specification"
      seterrorObject(error)
    } else if (form.carBootCapacity == null) {
      let error = { ...er }
      error["carBootCapacity"] = "Please Enter Boot Capacity"
      seterrorObject(error)
    } else if (selectedOptions.carFeatureId == 0) {
      let error = { ...er }
      error["carFeatureId"] = "Please Select Model"
      seterrorObject(error)
    }
    //  else if (form.carMakeYear == null)
    //  {
    //   let error = { ...er };
    //   error["carMakeYear"] = "Please Select Year";
    //   seterrorObject(error);
    // }
    // else if (form.vehicleType == null) {
    //   let error = { ...er };
    //   error["vehicleType"] = "Please Select M/A/B ";
    //   seterrorObject(error);
    // }
    else {
      let error = { ...er }
      seterrorObject(error)
      toggleTab(activeTab + 1)
    }
  }

  const populercars = () => {
    var token = datas
    var remid = remam23._id
    const dataArray = new FormData()
    dataArray.append("isPopular", remam23.isPopular)
    axios
      .put(URL.popularcar + "/" + remid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcars()
            tog_small23()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [versions, setversions] = useState([])

  // console.log(res.data.noOfSeats)

  const handleChange5 = e => {
    let myUser = { ...versions }
    myUser[e.target.name] = e.target.value
    setversions(myUser)
  }

  // const version = () => {
  //   var token = datas;
  //   const dataArray = new FormData();
  //   dataArray.append("modelId", form.carModelId);
  //   axios.post('https://api.freedomcars.co.in/v1/admin/carversion/getcarversionbymodelid', dataArray, {
  //     headers: { Authorization: `Bearer ${token}` }
  //   }).then((res) => {
  //     setversions(res.data.carversions)
  //   })
  // }

  const handleSubmit123 = e => {
    e.preventDefault()
    populercars()
    clearForm()
  }

  const handleSubmit1234 = e => {
    e.preventDefault()
    Featurcars()
    clearForm()
  }

  const Featurcars = () => {
    var token = datas
    var remid = remam24._id
    const dataArray = new FormData()
    dataArray.append("isFeatured", remam24.isFeatured)
    axios
      .put(URL.featurecar + "/" + remid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcars()
            tog_small24()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletebooking = data => {
    var token = datas
    var remid = data._id
    axios
      .patch(
        URL.disablecars + "/" + remid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbenners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Inactive ?")
    if (confirmBox === true) {
      deletebooking(data)
    }
  }

  const [forms, setforms] = useState([])
  // console.log(forms)

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    // console.log(myUser)
    var token = datas
    axios
      .post(
        `https://api.freedomcars.co.in/v1/admin/car/getallcarmodel?searchQuery=${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcars(res.data.carsResult)
      })
  }

  const [remam, setremam] = useState([])

  const handleChange2 = e => {
    let myUser = { ...remam }
    myUser[e.target.name] = e.target.value
    setremam(myUser)
  }

  const [modal_small2, setmodal_small2] = useState(false)

  function tog_small2() {
    setmodal_small2(!modal_small2)
    removeBodyCss()
  }

  const [modal_small23, setmodal_small23] = useState(false)

  function tog_small23() {
    setmodal_small23(!modal_small23)
    removeBodyCss()
  }

  const [modal_small24, setmodal_small24] = useState(false)

  function tog_small24() {
    setmodal_small24(!modal_small24)
    removeBodyCss()
  }

  const getpopup2 = data => {
    setremam(data)
    tog_small2()
  }

  const [remam23, setremam23] = useState([])

  const handleChange23 = e => {
    let myUser = { ...remam23 }
    myUser[e.target.name] = e.target.value
    setremam23(myUser)
  }

  const getpopup3 = data => {
    setremam23(data)
    tog_small23()
  }

  const [remam24, setremam24] = useState([])

  const handleChange24 = e => {
    let myUser = { ...remam24 }
    myUser[e.target.name] = e.target.value
    setremam24(myUser)
  }

  const getpopup4 = data => {
    setremam24(data)
    tog_small24()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editbrands()
    clearForm()
  }

  const editbrands = () => {
    var token = datas
    const docid = remam._id
    const dataArray = new FormData()
    dataArray.append("isActive", remam.isActive)
    axios
      .patch(
        "https://api.freedomcars.co.in/v1/admin/car/updatecarstatus" + "/" + docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small2(false)
            getAllcars()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  let isFeatured = []

  console.log(isFeatured)

  var mobile_actions_two = [{ type: "isFeatured", value: false }]

  const handlemobileapp = e => {
    mobile_actions_two.forEach(check => {
      if (e.target.value === check.type) {
        check.isFeatured = e.target.checked
      }
    })
  }

  const [color, setcolor] = useState([])

  const getcolor = () => {
    var token = datas
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/color/getallcarcolors",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcolor(res.data.colorResult)
      })
  }

  // registration

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Cars List" />

          {/* Add cars */}

          {showResults ? (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        Handlesubmit(e)
                      }}
                    >
                      {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <Row>
                            <Col>
                              <h5 className="mb-3">Add Car</h5>
                            </Col>
                            <Col>
                              <div style={{ float: "right" }}>
                                <a onClick={hidefield} className="text-danger">
                                  {" "}
                                  <i className="far fa-times-circle"></i> Close
                                </a>
                              </div>
                            </Col>
                          </Row>
                          <ul>
                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">
                                  <i className="fas fa-car"></i>
                                </span>{" "}
                                SELECT CAR
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                                disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number">
                                  <i className="fas fa-shield-alt"></i>
                                </span>{" "}
                                CAR SPECS
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 3,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 3,
                                })}
                                onClick={() => {
                                  setactiveTab(3)
                                }}
                                disabled={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">
                                  <i className="fas fa-images"></i>
                                </span>{" "}
                                Car Images
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 4,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 4,
                                })}
                                onClick={() => {
                                  setactiveTab(4)
                                }}
                                disabled={!(passedSteps || []).includes(4)}
                              >
                                <span className="number">
                                  <i className="fas fa-money-check-alt"></i>
                                </span>{" "}
                                PRICING
                              </NavLink>
                            </NavItem>
                            {/* <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(5)}
                          >
                            <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
                          </NavLink>
                        </NavItem> */}
                          </ul>
                        </div>
                        <div className="content clearfix mt-4">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Car Type
                                    </Label>
                                    {/* <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                  /> */}
                                    <select
                                      value={form.carType}
                                      required
                                      name="carType"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      <option value="compactsuv">
                                        Compact SUV
                                      </option>
                                      <option value="hatchback">
                                        Hatchback
                                      </option>
                                      <option value="suv">SUV</option>
                                      <option value="sedan">Sedan</option>
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.carType}
                                    </small>
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Car Brand
                                    </Label>
                                    {/* <Select
                                    defaultValue={selectedOption1}
                                    onChange={setSelectedOption1}
                                    options={options1}
                                  /> */}
                                    <select
                                      value={form.carBrandId}
                                      name="carBrandId"
                                      onChange={e => {
                                        Optionchange(e)
                                      }}
                                      className="form-select"
                                    >
                                      {/* <option value="">Select</option> */}
                                      <option value="">Select</option>
                                      {model1.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            {data.title}
                                          </option>
                                          // options={data}
                                        )
                                      })}
                                      {/* <option value="Audi">Audi</option> */}
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.carBrandId}
                                    </small>
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Model
                                    </Label>

                                    {/* <Select
                                    defaultValue={selectedOption2}
                                    onChange={setSelectedOption2}
                                    options={options2}
                                  /> */}

                                    <select
                                      value={formS0.carModelId}
                                      name="carModelId"
                                      onChange={e => {
                                        Optionchange12(e)
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      {model.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            {data.model_name}
                                          </option>
                                        )
                                      })}
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.carModelId}
                                    </small>
                                  </div>
                                </Col>

                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      Version
                                    </Label>
                                    <select
                                      value={form.carMakeYear}
                                      name="carMakeYear"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>

                                      {vers.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            {data.carVersion}
                                          </option>
                                        )
                                      })}
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.carMakeYear}
                                    </small>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Select M/A/B{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    {/* <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                  /> */}
                                    <select
                                      value={form.vehicleType}
                                      name="vehicleType"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      <option value="manual">Manual</option>
                                      <option value="automatic">
                                        Automatic
                                      </option>
                                      <option value="battery">Battery</option>
                                    </select>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.vehicleType}
                                    </small>
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      Car Registration Number
                                    </Label>
                                    <Input
                                      value={form.carRegistNumber}
                                      name="carRegistNumber"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      type="text"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      placeholder=" Car Registration Number"
                                    />
                                  </div>
                                  <small style={{ color: "#ff0000" }}>
                                    {errorObject.carRegistNumber}
                                  </small>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Car Reading
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="Enter Car Reading"
                                      required
                                      value={form.carReading}
                                      name="carReading"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      No Of Seates
                                    </Label>
                                    <Input
                                      disabled
                                      type="text"
                                      name="noOfSeats"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="Enter No of Seates"
                                      required
                                      value={versSeat}
                                      // onChange={(e) => { handleChange(e) }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Row>
                                  <Col lg="3">
                                    <div className="mb-3">
                                      <Label for="basicpill-pancard-input5">
                                        Available Colors
                                      </Label>
                                      {/* <Select
                                        value={selectedMulti1}
                                        isMulti={true}
                                        onChange={handleMulti}
                                        options={optionGroup123}
                                        name="carColorAvailble"
                                      /> */}

                                      <select
                                        value={form.carColorAvailble}
                                        name="carColorAvailble"
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                        className="form-select"
                                      >
                                        <option value="">Select</option>

                                        {color.map((data, key) => {
                                          return (
                                            <option key={key} value={data._id}>
                                              {data.colorName}
                                            </option>
                                          )
                                        })}
                                      </select>
                                      <small style={{ color: "#ff0000" }}>
                                        {errorObject.carColorAvailble}
                                      </small>
                                    </div>
                                  </Col>

                                  <Col lg="3">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        Car Features
                                      </Label>
                                      {/* <Select
                                      value={selectedMulti}
                                      isMulti={true}
                                      onChange={() => {
                                        handleMulti();
                                      }}
                                      options={optionGroup1}
                                      classNamePrefix="select2-selection"
                                    /> */}

                                      <Select
                                        style={{ width: "100%" }}
                                        required
                                        options={optionGroup1}
                                        placeholder="Select Feature"
                                        value={selectedOptions}
                                        onChange={handleSelect}
                                        isSearchable={true}
                                        isMulti
                                        name="carFeatureId"
                                      />
                                      <small style={{ color: "#ff0000" }}>
                                        {errorObject.selectedOptions}
                                      </small>
                                    </div>
                                  </Col>

                                  <Col lg="3">
                                    <div className="mb-3">
                                      <Label for="basicpill-servicetax-input8">
                                        Specification
                                      </Label>
                                      {/* <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-servicetax-input8"
                                      placeholder="Enter Your Service Tax No."
                                    /> */}
                                      <select
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                        value={form.carSpecId}
                                        name="carSpecId"
                                        className="form-select"
                                      >
                                        <option value="">Select</option>
                                        {spce.map((data, key) => {
                                          return (
                                            <option key={key} value={data._id}>
                                              {data.specName}
                                            </option>
                                          )
                                        })}
                                      </select>
                                      <small style={{ color: "#ff0000" }}>
                                        {errorObject.carSpecId}
                                      </small>
                                    </div>
                                  </Col>

                                  <Col lg="2">
                                    <div className="mb-3">
                                      <Label for="basicpill-declaration-input10">
                                        Boot Capacity
                                      </Label>
                                      <Input
                                        value={form.carBootCapacity}
                                        name="carBootCapacity"
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                        type="number"
                                        className="form-control"
                                        id="basicpill-Declaration-input10"
                                        placeholder="Boot Capacity"
                                      />
                                    </div>
                                    <small style={{ color: "#ff0000" }}>
                                      {errorObject.carBootCapacity}
                                    </small>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={6}>
                                        <Label>Add Car Images </Label>
                                        <Input
                                          type="file"
                                          multiple
                                          name="carImages"
                                          value={form.carImages}
                                          onChange={changeHandler}
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label>Add Registartion Card</Label>

                                        <Input
                                          type="file"
                                          multiple
                                          name="carRegisterImages"
                                          value={form.carRegisterImages}
                                          onChange={changeHandler1}
                                        />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <Row>
                              <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                     Insurance Amount <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="Enter Insurance Amount"
                                      required
                                      value={form.insurance}
                                      name="insurance"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <div className="table-responsive">
                                    <Table className="table table-sm table-bordered mb-5 mt-3">
                                      <thead>
                                        <tr>
                                          <th>Period / Mileage</th>
                                          <th>6 Hours</th>
                                          <th>12 Hours</th>
                                          <th>Unlimited kms</th>
                                          <th>1 day</th>
                                          {/* {form1.unlimitedStatus == "0" ? (
                                            <th>1 day</th>
                                          ) : (
                                            ""
                                          )} */}
                                          {/* <th>9 Months</th>
                                          <th>12 Months</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form1.kms}
                                                name="kms"
                                                required
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.sixHoursprice}
                                                name="sixHoursprice"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                required
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.tweleveHoursprice}
                                                name="tweleveHoursprice"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                required
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            {/* <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.tweleveHoursprice}
                                                name="tweleveHoursprice"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                required
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"

                                                /></InputGroup> */}

                                            {/* <input type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}

      /> */}
                                            <select
                                              value={form1.unlimitedStatus}
                                              name="unlimitedStatus"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select Unlimited
                                              </option>
                                              <option value="Yes">Yes</option>
                                            </select>
                                          </td>

                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.onedayPrice}
                                                required
                                                name="onedayPrice"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form1.kms1}
                                                name="kms1"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.sixHoursprice1}
                                                name="sixHoursprice1"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.tweleveHoursprice1}
                                                name="tweleveHoursprice1"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <select
                                              value={form1.unlimitedStatus1}
                                              name="unlimitedStatus1"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option>Select Unlimited</option>
                                              <option value="Yes">Yes</option>
                                            </select>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.onedayPrice1}
                                                name="onedayPrice1"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form.kms2}
                                                name="kms2"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.sixHoursprice2}
                                                name="sixHoursprice2"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.tweleveHoursprice2}
                                                name="tweleveHoursprice2"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <select
                                              value={form1.unlimitedStatus2}
                                              name="unlimitedStatus2"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select Unlimited
                                              </option>
                                              <option value="Yes">Yes</option>
                                            </select>{" "}
                                          </td>

                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.onedayPrice2}
                                                name="onedayPrice2"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form.kms3}
                                                name="kms3"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.sixHoursprice3}
                                                name="sixHoursprice3"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.tweleveHoursprice3}
                                                name="tweleveHoursprice3"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <select
                                              value={form1.unlimitedStatus3}
                                              name="unlimitedStatus3"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select Unlimited
                                              </option>
                                              <option value="Yes">Yes</option>
                                              <option value="No">No</option>
                                            </select>{" "}
                                          </td>

                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.onedayPrice3}
                                                name="onedayPrice3"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form.kms4}
                                                name="kms4"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.sixHoursprice4}
                                                name="sixHoursprice4"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.tweleveHoursprice4}
                                                name="tweleveHoursprice4"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <select
                                              value={form1.unlimitedStatus4}
                                              name="unlimitedStatus4"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select Unlimited
                                              </option>
                                              <option value="Yes">Yes</option>
                                            </select>
                                          </td>

                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.onedayPrice4}
                                                name="onedayPrice4"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <InputGroup>
                                              <div className="input-group-text">
                                                KM
                                              </div>
                                              <input
                                                value={form.kms5}
                                                name="kms5"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Mileage"
                                              />
                                            </InputGroup>
                                          </th>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form.sixHoursprice5}
                                                name="sixHoursprice5"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.tweleveHoursprice5}
                                                name="tweleveHoursprice5"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                          <td>
                                            <select
                                              value={form1.unlimitedStatus5}
                                              name="unlimitedStatus5"
                                              onChange={e => {
                                                handleChange1s(e)
                                              }}
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select Unlimited
                                              </option>
                                              <option value="Yes">Yes</option>
                                            </select>{" "}
                                          </td>

                                          <td>
                                            <InputGroup>
                                              <div className="input-group-text">
                                                {" "}
                                                <i className="fas fa-rupee-sign text-dark"></i>
                                              </div>
                                              <input
                                                value={form1.onedayPrice5}
                                                name="onedayPrice5"
                                                onChange={e => {
                                                  handleChange1(e)
                                                }}
                                                type="number"
                                                className="form-control"
                                                id="basicpill-namecard-input11"
                                                placeholder="Charges"
                                              />
                                            </InputGroup>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ background: "#d05151" }}
                              >
                                <i className="fas fa-arrow-circle-left"></i>{" "}
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab > 3 ? "next logbtn" : "next"}
                            >
                              <>
                                {/* <li
                                className={activeTab != 5 ? "next logbtn" : "next"}
                              > */}
                                <Link
                                  to="#"
                                  onClick={e => {
                                    validateFun(e)
                                  }}
                                >
                                  Next{" "}
                                  <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                                {/* </li> */}
                              </>
                            </li>
                            {/*                         
                          <li
                            className={activeTab > 2 || activeTab !== 2 ? "next logbtn" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={(e) => {
                                validateFun1(e)
                              }}
                            >
                              Next <i className="fas fa-arrow-circle-right"></i>
                            </Link>
                          </li> */}

                            <li
                              className={
                                activeTab < 4 ? "previous logbtn " : "previous"
                              }
                            >
                              <Button type="submit" color="primary">
                                Submit <i className="fas fa-check-circle"></i>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {/* Edit cars */}

          <Card>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="row mt-4">
                    <div className="col">
                      <Input
                        name="search"
                        value={forms.search}
                        onChange={handlechange}
                        type="text"
                        style={{ width: "40%" }}
                        className="form-control"
                        placeholder="Search.."
                      />
                    </div>
                    <div className="col">
                      <Button
                        style={{
                          float: "right",
                          background: "#1A374D",
                          color: "white",
                        }}
                        onClick={() => {
                          setShowResults(!showResults)
                        }}
                      >
                        Create New One
                      </Button>
                    </div>
                  </div>
                  {/* <h5 className="mt-2">Manage Listings</h5> */}
                  <div className="table-responsive">
                    <Table className="table table-bordered mt-3">
                      <thead>
                        <tr className="text-center">
                          <th>S no</th>
                          <th>Car Image</th>
                          {/* <th>CarRegisterNumber</th> */}
                          <th>Car Brand / Model / Year</th>
                          <th>Car Type</th>
                          <th>Status</th>
                          <th>Popular</th>
                          <th>Feature</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>
                              {/* {data.carRegisterImage[0]} */}
                              <img
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  data.carImage[0]
                                }
                                style={{ width: "80px" }}
                              />
                            </td>
                            {/* <td>
                              {data.carRegistNumber}
                          
                            </td> */}
                            <td>
                              {data.carBrandName} / {data.carModelName} /{" "}
                              {data.carMakeYear}
                            </td>
                            {/* <td><span className="text-primary">2100 </span>/ month</td> */}
                            <td>{data.carType}</td>
                            <td>
                              {data.isActive == true ? (
                                <Badge pill bg="success">
                                  Active
                                </Badge>
                              ) : (
                                <Badge pill bg="danger">
                                  Inactive
                                </Badge>
                              )}
                            </td>
                            <td>
                              {/* <BootstrapSwitchButton checked={true} size="lg" /> */}
                              {/* <FormGroup style={{ float: "right" }} switch>
                                <Input
                                  name="isPopular"
                                  value={data.isPopular}
                                  defaultChecked={data.isPopular}
                                  onChange={() => {
                                    handlechangescar(data)
                                  }}
                                  style={{ fontSize: "20px" }}
                                  type="switch"
                                  role="switch"
                                />
                              </FormGroup> */}

                              {/* <select
                                onChange={e => {
                                  handlechangescar(e)
                                }}
                                value={data.isPopular}
                                name="isPopular"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="true">show</option>
                                <option value="false">Hide</option>
                              </select> */}

                              {data.isPopular == true ? (
                                <Badge pill bg="success">
                                  Show
                                </Badge>
                              ) : (
                                <Badge pill bg="danger">
                                  Hide
                                </Badge>
                              )}

                              <Button
                                onClick={() => {
                                  getpopup3(data)
                                }}
                                style={{ padding: "6px", margin: "3px" }}
                                color="info"
                                outline
                              >
                                <i className="bx bx-edit "></i>
                              </Button>
                            </td>

                            <td>
                              {data.isFeatured == true ? (
                                <Badge pill bg="success">
                                  Show
                                </Badge>
                              ) : (
                                <Badge pill bg="danger">
                                  Hide
                                </Badge>
                              )}

                              {/* <FormGroup style={{ float: "right" }} switch>
                                <Input name="isFeatured" value={data.isFeatured} onChange={() => { handlechangescarf(data) }} defaultChecked={data.isFeatured} style={{ fontSize: "20px" }} type="switch" role="switch" />
                              </FormGroup> */}
                              <Button
                                onClick={() => {
                                  getpopup4(data)
                                }}
                                style={{ padding: "6px", margin: "3px" }}
                                color="info"
                                outline
                              >
                                <i className="bx bx-edit "></i>
                              </Button>
                            </td>

                            <td>
                              <Button
                                onClick={() => {
                                  getdata(data)
                                }}
                                // onClick={() => { seteditResults(!editResults); }}
                                className="mr-2"
                                style={{ padding: "6px", margin: "3px" }}
                                color="success"
                                outline
                              >
                                <i className="bx bx-edit "></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  getdata1(data)
                                }}
                                style={{ padding: "6px", margin: "3px" }}
                                color="warning"
                                outline
                              >
                                <i className="fas fa-eye text-dark"></i>
                              </Button>
                              {/* <Button
                                onClick={() => { manageDelete(data) }}
                                style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                <i className="bx bx-trash"></i>
                              </Button> */}

                              <Button
                                onClick={() => {
                                  getpopup2(data)
                                }}
                                style={{ padding: "6px", margin: "3px" }}
                                color="success"
                                outline
                              >
                                <i className="fas fa-retweet text-dark "></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="mt-3" style={{ float: "right" }}>
                      {/* <Stack spacing={2}> */}
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                      {/* </Stack> */}
                    </div>
                  </div>
                </Col>
              </Row>

              <Modal
                size="sm"
                isOpen={modal_small2}
                toggle={() => {
                  tog_small2()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Car Status Update
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small2(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form
                    onSubmit={e => {
                      handleSubmit1(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <select
                        onChange={e => {
                          handleChange2(e)
                        }}
                        value={remam.isActive}
                        name="isActive"
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="true">Active</option>
                        <option value="false">In Active</option>
                      </select>
                    </div>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          setmodal_small2(false)
                        }}
                        color="danger"
                        type="button"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>

              <Modal
                size="sm"
                isOpen={modal_small23}
                toggle={() => {
                  tog_small23()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Popular update
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small23(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form
                    onSubmit={e => {
                      handleSubmit123(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <select
                        onChange={e => {
                          handleChange23(e)
                        }}
                        value={remam23.isPopular}
                        name="isPopular"
                        className="form-select"
                      >
                        <option value="true">Show</option>
                        <option value="false">Hide</option>
                      </select>
                    </div>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          setmodal_small23(false)
                        }}
                        color="danger"
                        type="button"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>

              <Modal
                size="sm"
                isOpen={modal_small24}
                toggle={() => {
                  tog_small24()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Feature Update
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small24(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form
                    onSubmit={e => {
                      handleSubmit1234(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <select
                        onChange={e => {
                          handleChange24(e)
                        }}
                        value={remam24.isFeatured}
                        name="isFeatured"
                        className="form-select"
                      >
                        <option value="true">Show</option>
                        <option value="false">Hide</option>
                      </select>
                    </div>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          setmodal_small24(false)
                        }}
                        color="danger"
                        type="button"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>
              <ToastContainer />
            </Container>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Cars
