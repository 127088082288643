// import React, { useState } from "react";
// import {
//     CardBody, CardHeader, Container,
//     Row, Col, Card, CardText, CardTitle,
//     Form, Label, Input, Button, Table,
//     Pagination,
//     PaginationItem,
//     PaginationLink, Modal,
// } from "reactstrap"

// //Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
// import car1 from "../assets/images/latest/car1.jpg";

// const Version = () => {

//     //meta title
//     // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

//     const [modal_small, setmodal_small] = useState(false);

//     function tog_small() {
//         setmodal_small(!modal_small);
//         removeBodyCss();
//     }


//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     <Breadcrumbs title="Freedom Cars" breadcrumbItem="Car Version" />
//                     <Row>
//                         <Col md={4}>
//                             <Card>
//                                 <CardHeader className="bg-white">
//                                     <CardTitle>Add Car Version</CardTitle>
//                                 </CardHeader>
//                                 <CardBody >
//                                 <Form>
//                         <div className="mb-3">
//                                 <Label for="basicpill-firstname-input3">
//                                     Brand <span className="text-danger">*</span>
//                                 </Label>
//                                 {/* <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input3"
//                                     placeholder="Enter City"
//                                     required
//                                 /> */}
//                                 <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="AlfaRomeo">Alfa Romeo</option>
//                                     <option value="Audi">Audi</option>
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input1">
//                                    Model Name <span className="text-danger">*</span>
//                                 </Label>
//                                 {/* <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input1"
//                                     placeholder="Enter Model Name"
//                                     required
//                                 /> */}
//                                  <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="AlfaRomeo">Giulietta</option>
//                                     <option value="Audi">A3 Convertible</option>
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input2">
//                                 Version <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input2"
//                                     placeholder="Enter  Version"
//                                     required
//                                 />
//                             </div>

//                             {/* <div className="mb-3">
//                                 <Label for="basicpill-firstname-input3">
//                                     Status <span className="text-danger">*</span>
//                                 </Label>
//                                 <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="active">Active</option>
//                                     <option value="inactive">In Active</option>
//                                 </select>
//                             </div> */}

//                             <div style={{ float: "right" }}>
//                                 {/* <Button      onClick={() => {
//                                 setmodal_small(false);
//                             }} color="danger" type="button">
//                                     Cancel <i className="fas fa-times-circle"></i>
//                                 </Button> */}
//                                 <Button className="m-1" color="primary" type="submit">
//                                     Submit <i className="fas fa-check-circle"></i>
//                                 </Button>

//                             </div>
//                         </Form>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         <Col md={8}>
//                             <Card>
//                                 <CardHeader className="bg-white">
//                                     <CardTitle>Model List</CardTitle>
//                                 </CardHeader>

//                                 <CardBody >

//                                     <div>
//                                         <div className="table-responsive">
//                                             <div style={{ float: "right" }}>
//                                                 <Input type="search" className="form-control" placeholder="Search.." />
//                                             </div>
//                                             <Table className="table table-bordered mb-4 mt-5">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>Slno</th>
//                                                         <th>Brand Name</th>
//                                                         <th>Model Name</th>
//                                                         <th>Version</th>
//                                                         <th>Status</th>
//                                                         <th style={{ width: "100px" }}>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr>
//                                                         <th scope="row">1</th>
//                                                         <td>Alfa Romeo</td>
//                                                         <td>Giulietta</td>
//                                                         <td>Convertible</td>
//                                                         <td>Active</td>
//                                                         <td>
//                                                             <Button onClick={() => {
//                                                                 tog_small();
//                                                             }} className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
//                                                                 <i className="bx bx-edit "></i>
//                                                             </Button>
//                                                             <Button style={{ padding: "6px", margin: "3px" }} color="danger" outline>
//                                                                 <i className="bx bx-trash"></i>
//                                                             </Button>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <th scope="row">2</th>
//                                                         <td>Audi</td>
//                                                         <td>A3 Convertible</td>
//                                                         <td>4-door </td>
//                                                         <td>Active</td>
//                                                         <td>
//                                                             <Button onClick={() => {
//                                                                 tog_small();
//                                                             }} className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
//                                                                 <i className="bx bx-edit "></i>
//                                                             </Button>
//                                                             <Button style={{ padding: "6px", margin: "3px" }} color="danger" outline>
//                                                                 <i className="bx bx-trash"></i>
//                                                             </Button>
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             </Table>
//                                             <div style={{ float: "right" }}>
//                                                 <Pagination
//                                                     size="sm"
//                                                     aria-label="Page navigation example"
//                                                 >
//                                                     <PaginationItem disabled>
//                                                         <PaginationLink href="#" tabIndex="-1">
//                                                             Previous
//                                                         </PaginationLink>
//                                                     </PaginationItem>
//                                                     <PaginationItem>
//                                                         <PaginationLink href="#">1</PaginationLink>
//                                                     </PaginationItem>
//                                                     <PaginationItem>
//                                                         <PaginationLink href="#">2</PaginationLink>
//                                                     </PaginationItem>
//                                                     <PaginationItem>
//                                                         <PaginationLink href="#">3</PaginationLink>
//                                                     </PaginationItem>
//                                                     <PaginationItem>
//                                                         <PaginationLink href="#">Next</PaginationLink>
//                                                     </PaginationItem>
//                                                 </Pagination>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>


//                 </Container>


//                 <Modal
//                     size="sm"
//                     isOpen={modal_small}
//                     toggle={() => {
//                         tog_small();
//                     }}
//                 >
//                     <div className="modal-header">
//                         <h5
//                             className="modal-title mt-0"
//                             id="mySmallModalLabel"
//                         >
//                            Edit Car Version
//                         </h5>
//                         <button
//                             onClick={() => {
//                                 setmodal_small(false);
//                             }}
//                             type="button"
//                             className="close"
//                             data-dismiss="modal"
//                             aria-label="Close"
//                         >
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <div className="modal-body">
//                         <Form>
//                         <div className="mb-3">
//                                 <Label for="basicpill-firstname-input3">
//                                     Brand <span className="text-danger">*</span>
//                                 </Label>
//                                 {/* <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input3"
//                                     placeholder="Enter City"
//                                     required
//                                 /> */}
//                                 <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="AlfaRomeo">Alfa Romeo</option>
//                                     <option value="Audi">Audi</option>
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input1">
//                                    Model Name <span className="text-danger">*</span>
//                                 </Label>
//                                 {/* <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input1"
//                                     placeholder="Enter Model Name"
//                                     required
//                                 /> */}
//                                  <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="AlfaRomeo">Giulietta</option>
//                                     <option value="Audi">A3 Convertible</option>
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input2">
//                                 Version <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input2"
//                                     placeholder="Enter  Version"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <Label for="basicpill-firstname-input3">
//                                     Status <span className="text-danger">*</span>
//                                 </Label>
//                                 {/* <Input
//                                     type="text"
//                                     className="form-control"
//                                     id="basicpill-firstname-input3"
//                                     placeholder="Enter City"
//                                     required
//                                 /> */}
//                                 <select className="form-select">
//                                     <option value="">Select</option>
//                                     <option value="active">Active</option>
//                                     <option value="inactive">In Active</option>
//                                 </select>
//                             </div>

//                             <div style={{ float: "right" }}>
//                                 <Button      onClick={() => {
//                                 setmodal_small(false);
//                             }} color="danger" type="button">
//                                     Cancel <i className="fas fa-times-circle"></i>
//                                 </Button>
//                                 <Button className="m-1" color="primary" type="submit">
//                                     Submit <i className="fas fa-check-circle"></i>
//                                 </Button>

//                             </div>
//                         </Form>
//                     </div>
//                 </Modal>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Version

























import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";
import axios from "axios";
import { Link } from "react-router-dom";

const options = [
    { value: 'Luxury', label: 'Luxury' },
    { value: 'LuxurySUV', label: 'Luxury SUV' },
    { value: 'SUV', label: 'SUV' },
    { value: 'Sedan', label: 'Sedan' },
    { value: 'Hatchback', label: 'Hatchback' },
    { value: 'Crossover', label: 'Crossover' },
];



const Version = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);
    // const navigate = useNavigate();

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }




    const [version, setversion] = useState([])
    const [model, setmodel] = useState([])
    const [model1, setmodel1] = useState([])
    // console.log(model1[0].title)
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])
    const [Files, setFiles] = useState("");
    const [Files1, setFiles1] = useState("");

    const changeHandler = (e) => {
        setFiles(e.target.files);
    };
    const changeHandler1 = (e) => {
        setFiles1(e.target.files);
    };

    const options1 = [
        { value: 'AlfaRomeo', label: 'Alfa Romeo' },
        { value: 'Audi', label: 'Audi' },
        { value: 'Toyota', label: 'Toyota' },
    ];


    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };
    const handleChange1 = (e) => {
        let myUser = { ...form1 };
        myUser[e.target.name] = e.target.value;
        setform1(myUser);
    };

    var gets = localStorage.getItem("authUser");
    var data = JSON.parse(gets);
    var datas = data.token;
    console.log(datas)

    const getAllmodal = () => {
        var token = datas;
        axios.post(URL.allcaractivemodels, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setmodel(res.data.modelResult)
        })
    }

    const getAllversion = () => {
        var token = datas;
        axios.post(URL.allcarversions, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setversion(res.data.versionsResult)
        })
    }

    const getactivebrand = () => {
        var token = datas;
        axios.post(URL.activecarbrands, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setmodel1(res.data.activecarBrandResult)
        })
    }


    const addversion = () => {
        var token = datas;
        const dataArray = new FormData();
        dataArray.append("brandId", form.brandId);
        dataArray.append("modelId", form.modelId);
        dataArray.append("carVersion", form.carVersion);

        axios.post(URL.addcarversions, dataArray, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllversion();
                    clearForm()

                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const deleteversion = (data) => {
        var token = datas;
        var remid = data._id
        axios.put(URL.removecarversions + "/" + remid, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllversion();

                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                } else if (error.response && error.response.status === 401) {
                    // navigate("/login");
                    // <Link to="/login"/>
                }
            }
        );
    }

    const manageDelete = (data) => {
        const confirmBox = window.confirm("Do you really want to Delete?");
        if (confirmBox === true) {
            deleteversion(data);
        }
    };

    const editversion = () => {
        var token = datas;
        const docid = form1._id
        const dataArray = new FormData();
        dataArray.append("brandId", form1.brandId);
        dataArray.append("modelId", form1.modelId);
        dataArray.append("carVersion", form1.carVersion);
        dataArray.append("status", form1.status);
        axios.put(URL.editcarversions + "/" + docid, dataArray, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllversion();
                    setmodal_small(false)
                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addversion();
        clearForm()
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();
        editversion();
        // setform1("")
        clearForm()
    };

    const clearForm = () => {
        setform({
            brandId: "",
            modelId: "",
            carVersion: "",
        });
    };

    const getpopup = (data) => {
        setform1(data);
        tog_small()
    };

    useEffect(() => {
        getAllmodal()
        getAllversion()
        getactivebrand()
    }, [])

    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = version.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(version.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const [forms, setforms] = useState([]);
    console.log(forms)
    
    const handlechange = (e) => {
        let myUser = { ...forms };
        myUser[e.target.name] = e.target.value;
        setforms(myUser);
        console.log(myUser)
        var token = datas;
        axios
            .post(
                `https://api.freedomcars.co.in/v1/admin/carversion/getallversions?searchQuery=${e.target.value}`,
                {},
    
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((res) => {
                setversion(res.data.versionsResult)
            });
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Car Version" />
                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Add Car Version</CardTitle>
                                </CardHeader>
                                <CardBody >
                                    <Form onSubmit={(e) => { handleSubmit(e) }}>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                Brand <span className="text-danger">*</span>
                                            </Label>

                                            {/* <Select
                                                required
                                                defaultValue={selectedOption1}
                                                onChange={setSelectedOption1}
                                                options={options1}
                                            /> */}
                                            <select onChange={(e) => { handleChange(e) }} value={form.brandId} name="brandId" className="form-select">
                                                <option value="">Select</option>
                                                {model1.map((data, key) => {
                                                    return (
                                                        <option key={key} value={data._id}>{data.title}</option>
                                                        // options={data}
                                                    )
                                                })}
                                                {/* <option value="Audi">Audi</option> */}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Model <span className="text-danger">*</span>
                                            </Label>
                                            {/* <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter Model Name"
                                                required
                                                name="model_name"
                                                value={form.model_name}
                                                onChange={(e)=>{handleChange(e)}}
                                            /> */}

                                            <select onChange={(e) => { handleChange(e) }} value={form.modelId} name="modelId" className="form-select">
                                                <option value="">Select</option>
                                                {model.map((data, key) => {
                                                    return (
                                                        <option key={key} value={data._id}>{data.model_name}</option>
                                                        // options={data}
                                                    )
                                                })}
                                                {/* <option value="Audi">Audi</option> */}
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Version  <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter Version"
                                                required
                                                name="carVersion"
                                                value={form.carVersion}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>

                                        <div style={{ float: "right" }}>
                                            {/* <Button      onClick={() => {
                                setmodal_small(false);
                            }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button> */}
                                            <Button className="m-1" color="primary" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>

                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={8}>
                            <Card>

                                <CardBody >
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <CardTitle>Versions List</CardTitle>
                                        </div>
                                        <div className="col-sm-6">
                                            <div style={{ float: "right" }}>
                                                <Input 
                                                name="search"
                                                value={forms.search}
                                                onChange={handlechange}
                                                type="text"
                                                className="form-control" placeholder="Search.." />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="table-responsive">

                                            <Table className="table table-bordered mb-4 mt-5">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Brand Name</th>
                                                        <th>Model Name</th>
                                                        <th>Version	</th>
                                                        <th>Status</th>
                                                        <th style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((data, key) => (
                                                        <tr key={key}>
                                                            <th scope="row">{(pageNumber - 1) * 5 + key + 6}</th>
                                                            <td>{data.brand_name}</td>
                                                            <td>{data.model_name}</td>
                                                            <td>{data.carVersion}</td>
                                                            {/* <td>
                                                                <img src={"https://api.freedomcars.co.in/" + data.carImage} style={{ width: "70px" }} />
                                                            </td> */}
                                                            <td>
                                                                {data.status}
                                                            </td>
                                                            <td>
                                                                <Button onClick={() => {
                                                                    getpopup(data);
                                                                }} className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                    <i className="bx bx-edit "></i>
                                                                </Button>
                                                                <Button onClick={() => {
                                                                    manageDelete(data);
                                                                }}
                                                                    style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                    <i className="bx bx-block"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}



                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>
                                                {/* <Stack spacing={2}> */}
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                                {/* </Stack> */}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>


                <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                    className="mt-5"
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Car Versions
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { handleSubmit1(e) }}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                    Brand <span className="text-danger">*</span>
                                </Label>

                                {/* <Select
                                                required
                                                defaultValue={selectedOption1}
                                                onChange={setSelectedOption1}
                                                options={options1}
                                            /> */}
                                <select onChange={(e) => { handleChange1(e) }} value={form1.brandId} name="brandId" className="form-select">
                                    {/* <option value="">Select</option> */}
                                    {model1.map((data, key) => {
                                        return (
                                            <option key={key} value={data._id}>{data.title}</option>
                                            // options={data}
                                        )
                                    })}
                                    {/* <option value="Audi">Audi</option> */}
                                </select>
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Model <span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter Model Name"
                                                required
                                                name="model_name"
                                                value={form.model_name}
                                                onChange={(e)=>{handleChange(e)}}
                                            /> */}

                                <select onChange={(e) => { handleChange1(e) }} value={form1.modelId} name="modelId" className="form-select">
                                    {/* <option value="">Select</option> */}
                                    {model.map((data, key) => {
                                        return (
                                            <option key={key} value={data._id}>{data.model_name}</option>
                                            // options={data}
                                        )
                                    })}
                                    {/* <option value="Audi">Audi</option> */}
                                </select>
                            </div>

                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Version <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Version"
                                    required
                                    name="carVersion"
                                    value={form1.carVersion}
                                    onChange={(e) => { handleChange1(e) }}
                                />
                            </div>

                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Status <span className="text-danger">*</span>
                                </Label>
                                <select
                                    className="form-select"
                                    value={form1.status} name="status"
                                    onChange={(e) => { handleChange1(e) }}>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>

                                </select>
                            </div>

                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
                <ToastContainer />
            </div>
        </React.Fragment>
    )
}

export default Version

