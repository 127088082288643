import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  TabContent,
  TabPane,
  FormGroup,
  PaginationItem,
  FormFeedback,
  PaginationLink,
  Modal,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import classnames from "classnames"
import "../../src/common.css"
import { withRouter, Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import user1 from "../assets/images/users/avatar-1.jpg"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"
import { useSelector, useDispatch } from "react-redux"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import passport from "../assets/images/latest/passport.jfif"
import card1 from "../assets/images/latest/profiele.png"
import card2 from "../assets/images/latest/card2.jpeg"
import visa from "../assets/images/latest/visa.jpg"
import emirate from "../assets/images/latest/emirate.jpg"
import aadhar from "../assets/images/latest/aadhar.png"
import driving from "../assets/images/latest/driving.jpg"

import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"

const Addusers = () => {
  const [checked, setChecked] = useState(false)
  const [radioValue, setRadioValue] = useState("2")

  const radios = [
    { name: "deactive", value: "1" },
    { name: "Active", value: "2" },
  ]

  //meta title
  // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })

  const [activeTab1, setactiveTab1] = useState("5")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const getAllcountries = () => {
    var token = datas
    axios
      .post(
        URL.allcountries,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setcount(res.data.countriesResult)
      })
  }

  const custid = sessionStorage.getItem("dataid")

  const getonecustomer = () => {
    var token = datas
    const params = {
      _id: custid,
    }

    axios
      .post(URL.viewcustomer, params, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        setform(res.data.customerResult)
      })
  }

  useEffect(() => {
    getAllcountries()
    getonecustomer()
  }, [])

  const reportstest = [
    // {
    //   title: "Total Users",
    //   icon: "mdi mdi-bitcoin",
    //   color: "warning",
    //   value: "$ 57,986.76",
    //   // desc: "+ 0.0012 ( 0.2 % )",
    //   series: series1,
    //   options: options1,
    //   arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    // },
    {
      title: "Total Bookings ",
      icon: "bx bxs-car",
      color: "primary",
      value: form.totaBooking,
      // desc: "- 4.102 ( 0.1 % )",
      //   series: series2,
      //   options: options2,
      arrowUpDown: "mdi mdi-arrow-down ms-1 text-danger",
    },
    {
      title: "Upcoming Bookings ",
      icon: "bx bxs-car",
      color: "info",
      value: form.upcomingBooking,
      // desc: "+ 1.792 ( 0.1 % )",
      //   series: series3,
      //   options: options3,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    {
      title: "Running Bookings",
      icon: "bx bxs-car",
      color: "warning",
      value: form.runningBooking,
      // desc: "+ 0.0012 ( 0.2 % )",
      //   series: series1,
      //   options: options1,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    {
      title: "Completed Bookings ",
      icon: "bx bxs-car",
      color: "success",
      value: form.completedBooking,
      // desc: "+ 0.0012 ( 0.2 % )",
      //   series: series4,
      //   options: options4,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
    {
      title: "Cancelled Bookings ",
      icon: "bx bxs-car",
      color: "danger",
      value: form.canceledBooking,
      // desc: "+ 0.0012 ( 0.2 % )",
      //   series: series4,
      //   options: options4,
      arrowUpDown: "mdi mdi-arrow-up ms-1 text-success",
    },
  ]

  const api = "https://api.freedomcars.co.in/"

  // const [remam, setremam] = useState([]);

  const handleChange2 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  //   const getpopup2 = (data) => {
  //     setremam(data);
  //     tog_small2()
  //   };

  const handleSubmit1 = e => {
    e.preventDefault()
    editbrands()
  }

  const editbrands = () => {
    var token = datas
    const docid = form._id
    const dataArray = new FormData()
    dataArray.append("status", form.status)
    axios
      .patch(
        "https://api.freedomcars.co.in/v1/admin/customer/editcustomerstatus" +
        "/" +
        docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            getonecustomer()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
    removeBodyCss()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    editbrands1()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const editbrands1 = () => {
    var token = datas
    const docid = form._id
    const dataArray = new FormData()

    dataArray.append("wallet", String(form.wallet))

    axios
      .patch(
        "https://api.freedomcars.co.in/v1/admin/customer/addamounttocustwallet" +
        "/" +
        docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            tog_small1()
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup1 = () => {
    tog_small1()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Customer Details" />

          <Row>
            <div>
              <Link to="/users-list">
                <Button
                  className="mb-3"
                  style={{ float: "right" }}
                  color="info"
                >
                  <i className="far fa-arrow-alt-circle-left"></i> Back
                </Button>
              </Link>
            </div>
            <Col md={3}>
              <Card>
                <CardBody>
                  {form.profilePic == "" ? (
                    <img style={{ width: "190px" }} src={card1} />
                  ) : (
                    <img
                      style={{ width: "190px" }}
                      src={"https://api.freedomcars.co.in/" + form.profilePic}
                    />
                  )}

                  <h5 className="mt-3">{form.customerName}</h5>
                  <p>Joined At {form.logDateCreated}</p>

                  <div className="mt-3">
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={9}>
              <Row>
                {reportstest.map((report, key) => (
                  <Col key={key}>
                    <Card className="p-2">
                      <Row>
                        <Col xs="2">
                          <p className="text-muted mb-4">
                            <i
                              className={
                                report.icon +
                                " h2 text-" +
                                report.color +
                                " align-middle mb-0 me-3"
                              }
                            />{" "}
                          </p>
                        </Col>
                        <Col xs="9">
                          <div>
                            <h5 className="text-center mt-2">{report.value}</h5>
                          </div>
                        </Col>
                        <p>{report.title}</p>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>
                    Information of {form.customerName}{" "}
                    <div style={{ float: "right" }}>
                      {/* <Button  onClick={() => {
                                    getpopup1(form.wallet)
                                  }}color="danger"  className="m-2">
                       Add Wallet
                      </Button> */}
                      <Button onClick={tog_small} color="primary">
                        Verification Status
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab1} className="p-1 text-muted">
                    <TabPane tabId="5">
                      <div>
                        <Row>
                          <Col md={6}>
                            <div className="row">
                              <div className="col col-sm-6">
                                <p>
                                  <b>Name</b>
                                </p>
                                <p>
                                  <b>Mobile No </b>
                                </p>
                                <p>
                                  <b>Email </b>
                                </p>
                                <p>
                                  <b>Gender </b>
                                </p>
                              </div>
                              <div className="col col-sm-6">
                                <p>: {form.customerName}</p>
                                <p>: {form.phone}</p>
                                <p>: {form.email}</p>
                                <p>
                                  : {form.gender == "M" ? "Male" : "Female"}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col>
                                <p>
                                  <b>Date Of Birth </b>
                                </p>
                                <p>
                                  <b>Occupation</b>
                                </p>
                                <p>
                                  <b>Resident Type</b>
                                </p>
                                <p>
                                  <b>Country Name</b>
                                </p>
                              </Col>
                              <Col>
                                <p>: {form.dateOfBirth}</p>
                                <p>: {form.occupation}</p>
                                <p>: {form.residentStatus}</p>
                                <p>: {form.countryName}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={6}>
                            <div className="row">
                              <div className="col col-sm-6">
                                {form.altnatePhone == "" ?(
                                  ""
                                ):(
                                  <p>
                                  <b>Altnate Mobile No </b>
                                </p>
                                )}
                            
                                <p>
                                  <b>Driving license</b>
                                </p>
                               
                                {form.voterIdNumber == "" || form.voterIdNumber == "undefined"? (
                                  ""
                                ) : (
                                  <p>
                                    <b>Voter Id Number</b>
                                  </p>
                                )}
                                <p>
                                  <b>Document Type </b>
                                </p>
                                {form.aadharNumber == "" || form.aadharNumber == "undefined" ? (
                                  ""
                                ) : (
                                  <p>
                                    <b>Aadhar Number</b>
                                  </p>
                                )}
                              </div>
                              <div className="col col-sm-6">
                              {form.altnatePhone == "" ||  form.altnatePhone == "undefined" ?(
                                  ""
                                ):(
                                  <p>
                                    <p>: {form.altnatePhone}</p>
                                </p>
                                )}

                                <p>: {form.dlNumber}</p>
                                {form.voterIdNumber == "" || form.voterIdNumber == "undefined"  ? (
                                  ""
                                ) : (
                                  <p>: {form.voterIdNumber}</p>
                                )}

                                <p>: {form.documentType}</p>
                                {form.aadharNumber == "" || form.aadharNumber == "undefined" ? (
                                  ""
                                ) : (
                                  <p>: {form.aadharNumber}</p>
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="row">
                              <div className="col col-sm-6">
                                {form.occupationdetails == "" || form.occupationdetails == "undefined" ? (
                                  ""
                                ) : (
                                  <p>
                                    <b>Company/College </b>
                                  </p>
                                )}

                                <p>
                                  <b>Status</b>
                                </p>
                                <p>
                                  <b>Address</b>
                                </p>
                                <p>
                                  <b>Wallet</b>
                                </p>
                              </div>
                              <div className="col col-sm-6">
                                {form.occupationdetails == "" || form.occupationdetails == "undefined" ? (
                                  ""
                                ) : (
                                  <p>: {form.occupationdetails}</p>
                                )}
                                <p>
                                  :{" "}
                                  {form.status == true ? (
                                    <a className="text-success">Verified</a>
                                  ) : (
                                    <a className="text-danger">Pending</a>
                                  )}
                                </p>
                                <p>: {form.address}</p>
                                <p>: {form.wallet}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      {/* <Col md={12} className="mb-3">
                      {form.selfie == "" ? (
                    <img style={{ width: "100px" }} src={card1} />
                  ) : (
                    <img
                      style={{ width: "100px" }}
                      src={"https://api.freedomcars.co.in/" + form.selfie}
                    />
                  )}    </Col> */}
                      {form.residentStatus !== "nonResident" ? (
                        <>
                          <Row className="mt-3">
                            <Col md={6}>
                              {form.aadharFront == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                   Aadhar Front
                                  </small>
                                  <br />

                                  <img
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.aadharFront
                                    }
                                    style={{ width: "100%", height: "150px" }}
                                  />
                                </>
                              )}
                            </Col>
                            <Col md={6}>
                              {form.aadharBack == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                  Aadhar Back
                                  </small>
                                  <br />
                                  <img
                                    // onClick={tog_small}
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.aadharBack
                                    }
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-3">
                            <Col md={6}>
                              {form.passportFront == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                  Passport Front
                                  </small>
                                  <br />

                                  <img
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.passportFront
                                    }
                                    style={{ width: "100%", height: "150px" }}
                                  />
                                </>
                              )}
                            </Col>
                            <Col md={6}>
                              {form.passportBack == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                  Passport Back
                                  </small>
                                  <br />
                                  <img
                                    // onClick={tog_small}
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.passportBack
                                    }
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-3">
                            <Col md={6}>
                              {form.voterIdFront == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                  VoterId Front
                                  </small>
                                  <br />

                                  <img
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.voterIdFront
                                    }
                                    style={{ width: "100%", height: "150px" }}
                                  />
                                </>
                              )}
                            </Col>
                            <Col md={6}>
                              {form.voterIdBack == "" ? (
                                ""
                              ) : (
                                <>
                                  <small className="text-uppercase">
                                  VoterId Back
                                  </small>
                                  <br />
                                  <img
                                    // onClick={tog_small}
                                    src={
                                      "https://api.freedomcars.co.in/" +
                                      form.voterIdBack
                                    }
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-3">
                            <Col md={6}>
                              {form.dlFront == "" ? (
                                ""
                              ) : (
                                <>
                                  <small>Driving license Front</small>
                                  <br />
                                  <img
                                    // onClick={tog_small}
                                    src={
                                      "https://api.freedomcars.co.in/" + form.dlFront
                                    }
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            </Col>
                            <Col md={6}>
                              {form.dlBack == "" ? (
                                ""
                              ) : (
                                <>
                                  <small>Driving license Back</small>
                                  <br />
                                  <img
                                    // onClick={tog_small}
                                    src={
                                      "https://api.freedomcars.co.in/" + form.dlBack
                                    }
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : ""}


                      <Row className="mt-3">
                        <Col md={6}>
                          {form.interPassport == "" ? (
                            ""
                          ) : (
                            <>
                              <small>Passport Copy</small>
                              <br />
                              <img
                                // onClick={tog_small}
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.interPassport
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>
                        <Col md={6}>
                          {form.photoIdProof == "" ? (
                            ""
                          ) : (
                            <>
                              <small>Id card Front</small>
                              <br />
                              <img
                                // onClick={tog_small}
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.photoIdProof
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>
                        <Col md={6}>
                          {form.visaCopy == "" ? (
                            ""
                          ) : (
                            <>
                              <small>Visit Visa Copy</small>
                              <br />
                              <img
                                // onClick={tog_small}
                                src={
                                  "https://api.freedomcars.co.in/" + form.visaCopy
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>
                        <Col md={6}>
                          {form.interDlFront == "" ? (
                            ""
                          ) : (
                            <>
                              <small>International Driving license Front</small>
                              <br />
                              <img
                                // onClick={tog_small}
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.interDlFront
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>
                        <Col md={6}>
                          {form.interDlBack == "" ? (
                            ""
                          ) : (
                            <>
                              <small>International Driving license Back</small>
                              <br />
                              <img
                                // onClick={tog_small}
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.interDlBack
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>

                        <Col md={6}>
                          {form.occupationIdCard == "" ? (
                            ""
                          ) : (
                            <>
                              <small>ID Card</small>
                              <br />
                              <img
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.occupationIdCard
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>

                        <Col md={6}>
                          {form.selfie == "" ? (
                            ""
                          ) : (
                            <>
                              <small>Selfie</small>
                              <br />
                              <img
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  form.selfie
                                }
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </Col>

                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="sm"
          centered
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    verification Status <span className="text-danger">*</span>
                  </Label>
                  <select
                    onChange={e => {
                      handleChange2(e)
                    }}
                    value={form.status}
                    name="status"
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="true">Verified </option>
                    <option value="false">Pending</option>
                  </select>
                </div>
              </div>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          className="mt-5"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Wallet
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit2(e)
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">ADD AMOUNT</Label>
                    <Input
                      type="text"
                      className="form-control"
                      value={form.wallet}
                      id="basicpill-firstname-input1"
                      placeholder="Enter ADD AMOUNT "
                      required
                      name="wallet"
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>

                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Addusers
