import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg"

import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import Select from "react-select"

const Model = () => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOption1, setSelectedOption1] = useState(null)

  //meta title
  // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [model, setmodel] = useState([])
  const [model1, setmodel1] = useState([])
  // console.log(model1[0].title)
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  const options1 = [
    { value: "AlfaRomeo", label: "Alfa Romeo" },
    { value: "Audi", label: "Audi" },
    { value: "Toyota", label: "Toyota" },
  ]

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const getAllmodal = () => {
    var token = datas
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/notify/getall-notification",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setmodel(res.data.notifyResult)
      })
  }

  const getactivebrand = () => {
    var token = datas
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/customer/getallactivecustomers",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setmodel1(res.data.custActiveResult)
      })
  }

  const addbrands = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form.title)
    dataArray.append("description", form.description)

    if (form.userList == "[]") {
      dataArray.append("userList", form.userList)
    } else {
      for (let i = 0; i < selectedOptions.length; i++) {
        dataArray.append("userList", selectedOptions[i].value)
      }
    }

    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/notify/addnotification",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllmodal()
            clearForm()
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletefeature = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(
        "https://api.freedomcars.co.in/v1/admin/notify/remove-notification" +
          "/" +
          remid,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllmodal()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Inactive?")
    if (confirmBox === true) {
      deletefeature(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    addbrands()
    setform("")
    clearForm()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editbrands()
    // setform1("")
    clearForm()
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
      userList: "",
    })
  }

  const clearForm1 = () => {
    setSelectedOptions("")
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  useEffect(() => {
    getAllmodal()
    getactivebrand()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = model.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(model.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [forms, setforms] = useState([])
  console.log(forms)

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    console.log(myUser)
    var token = datas
    axios
      .post(
        `https://api.freedomcars.co.in/v1/admin/notify/getall-notification?searchQueryParams=${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setmodel(res.data.notifyResult)
      })
  }

  const optionGroup1 = model1.map(response => ({
    value: response._id,
    label: response.customerName,
  }))

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    setSelectedOptions(details)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Notification" />
          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Notification</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Title<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter title "
                        required
                        name="title"
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Description
                        <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter description"
                        required
                        name="description"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        All Users
                        <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.userList}
                        name="userList"
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="[]"> All Users</option>
                      </select>
                    </div>

                    {form.userList == "[]" ? (
                      ""
                    ) : (
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Single User <span className="text-danger">*</span>
                        </Label>

                        <Select
                          className="input-required"
                          // style={{ width: "100%" }}
                          options={optionGroup1}
                          // required={required}
                          placeholder="Select Single Users"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isSearchable={true}
                          isMulti
                          name="userList"
                        />
                      </div>
                    )}

                    <div style={{ float: "right" }}>
                      <Button className="m-1 mt-2" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6">
                      <CardTitle>Notification List</CardTitle>
                    </div>
                    <div className="col-sm-6">
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={forms.search}
                          onChange={handlechange}
                          type="text"
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Title </th>
                            <th>Description</th>

                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th scope="row">
                                {(pageNumber - 1) * 5 + key + 6}
                              </th>
                              <td>{data.title}</td>
                              <td>{data.description}</td>

                              <td>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        {/* <Stack spacing={2}> */}
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                        {/* </Stack> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Model
