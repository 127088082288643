import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"

const Coupons = () => {
  const [fieldshow, setfieldshow] = useState()
  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }

  //meta title
  // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const [coup, setcoup] = useState([])

  const [form, setform] = useState({
    title: "",
    coupon_code: "",
    coupon_code_type: "",
    amount: "",
    description: "",
    from_date: "",
    to_date: "",
    couponUsage: "",
    percentDiscount: "",
  })

  console.log(form)
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const getAllbcoupons = () => {
    var token = datas
    axios
      .post(
        URL.allcoupons,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setcoup(res.data.couponsFound)
      })
  }

  const mt = ""

  const addcoupons = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form.title)
    dataArray.append("coupon_code", form.coupon_code)
    dataArray.append("coupon_code_type", form.coupon_code_type)
    dataArray.append("amount", form.amount)
    dataArray.append("percentDiscount", form.percentDiscount)

    // if (form.amount == "") {
    //   dataArray.append("amount", mt)
    // } else {
    //   dataArray.append("amount", form.amount)
    // }

    // if (form.amount == "") {
    //   dataArray.append("percentDiscount", mt)
    // } else {
    //   dataArray.append("percentDiscount", form.percentDiscount)
    // }

    dataArray.append("description", form.description)
    dataArray.append("from_date", form.from_date)
    dataArray.append("to_date", form.to_date)
    dataArray.append("couponUsage", form.couponUsage)

    axios
      .post(URL.addcoupons, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
            setform("")
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const editcoupon = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("title", form1.title)
    dataArray.append("coupon_code", form1.coupon_code)
    dataArray.append("coupon_code_type", form1.coupon_code_type)

    dataArray.append("amount", form1.amount)

    dataArray.append("percentDiscount", form1.percentDiscount)

    dataArray.append("description", form1.description)
    dataArray.append("from_date", form1.from_date)
    dataArray.append("to_date", form1.to_date)
    dataArray.append("couponUsage", form1.couponUsage)
    dataArray.append("status", form1.status)

    axios
      .put(URL.editcoupons + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    addcoupons()
    setform("")
    clearForm()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editcoupon()
    // clearForm()
  }

  const deletebenners = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URL.deletetcoupons + "/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    getAllbcoupons()
  }, [])

  const clearForm = () => {
    setform({
      title: "",
      coupon_code: "",
      coupon_code_type: "",
      amount: "",
      description: "",
      from_date: "",
      to_date: "",
      couponUsage: "",
    })
  }

  const [forms, setforms] = useState([])
  console.log(forms)

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    console.log(myUser)
    var token = datas
    axios
      .post(
        `https://api.freedomcars.co.in/v1/admin/coupons/all?searchQuery=${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.couponsFound)
      })
  }

  const [dds, setdds] = useState([])

  const handleChangedates = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds(tomorrow.toISOString().split("T")[0])
  }

  const [dds1, setdds1] = useState([])

  const handleChangedates1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds1(tomorrow.toISOString().split("T")[0])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Coupons" />
          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Coupons</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Title"
                        required
                        name="title"
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Coupon Code <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input3"
                        placeholder="Enter Coupon Code"
                        required
                        name="coupon_code"
                        value={form.coupon_code}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Coupon Code Type<span className="text-danger">*</span>
                      </Label>
                      <select
                        name="coupon_code_type"
                        value={form.coupon_code_type}
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="price">Price</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    {form.coupon_code_type == "percentage" ? (
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input2">
                          Percentage % <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input2"
                          placeholder="Enter  Percentage"
                          required
                          name="percentDiscount"
                          value={form.percentDiscount}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input2">
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input2"
                          placeholder="Enter Price "
                          required
                          name="amount"
                          value={form.amount}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    )}

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Use of Times <span className="text-danger">*</span>
                      </Label>
                      {/* <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input2"
                                                placeholder="Enter  Percentage"
                                                required
                                                name="couponUsage"
                                                value={form.couponUsage}
                                                onChange={(e) => { handleChange(e) }}
                                            /> */}

                      <select
                        name="couponUsage"
                        value={form.couponUsage}
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="single">Single</option>
                        <option value="multiple">Multiple</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        From Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        required
                        min={new Date().toISOString().split("T")[0]}
                        name="from_date"
                        value={form.from_date}
                        onChange={e => {
                          handleChangedates(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Expire Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter  Version"
                        required
                        min={dds}
                        name="to_date"
                        value={form.to_date}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Description
                      </Label>
                      <textarea
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Description"
                        name="description"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div style={{ float: "right" }}>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Coupons List</CardTitle>
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={forms.search}
                          onChange={handlechange}
                          type="text"
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Title</th>
                            <th>Coupon Codes</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Type</th>
                            <th>Value</th>
                            <th>Status</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th scope="row">
                                {(pageNumber - 1) * 5 + key + 6}
                              </th>
                              <td>{data.title}</td>
                              <td>{data.coupon_code}</td>
                              <td>{data.from_date}</td>
                              <td>{data.to_date}</td>
                              <td>{data.coupon_code_type}</td>
                              <td>
                                {data.coupon_code_type == "percentage"?(
                                  <span>{data.percentDiscount} %</span>
                                ):(<span>{data.amount}</span>)}
                                </td>
                              <td>
                                {data.status == true ? "Active" : "Inactive"}{" "}
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        {/* <Stack spacing={2}> */}
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                        {/* </Stack> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Coupon Details
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Title"
                      required
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Coupon Code Type<span className="text-danger">*</span>
                    </Label>
                    <select
                      name="coupon_code_type"
                      value={form1.coupon_code_type}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="price">Price</option>
                      <option value="percentage">Percentage</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Use of Times <span className="text-danger">*</span>
                    </Label>
                    {/* <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input2"
                                                placeholder="Enter  Percentage"
                                                required
                                                name="couponUsage"
                                                value={form.couponUsage}
                                                onChange={(e) => { handleChange(e) }}
                                            /> */}

                    <select
                      name="couponUsage"
                      value={form1.couponUsage}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      <option value="single">Single</option>
                      <option value="multiple">Multiple</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Expire Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter  Version"
                      required
                      name="to_date"
                      min={dds1}
                      value={form1.to_date}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">Description</Label>
                    <textarea
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Description"
                      name="description"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Coupon Code <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input3"
                      placeholder="Enter Coupon Code"
                      required
                      name="coupon_code"
                      value={form1.coupon_code}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>

                  {form1.coupon_code_type == "percentage" ? (
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Percentage % <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter  Percentage"
                        required
                        name="percentDiscount"
                        value={form1.percentDiscount}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Price "
                        required
                        name="amount"
                        value={form1.amount}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  )}

                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      From Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      required
                      name="from_date"
                      value={form1.from_date}
                      onChange={e => {
                        handleChangedates1(e)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Status<span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                </Col>
              </Row>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Coupons
