import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
    InputGroup,
    CardHeader,
    CardTitle,
    CardSubtitle,
    PaginationItem,
    PaginationLink,
    Modal,
    Pagination
} from "reactstrap"
import "../../../src/common.css"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import car1 from "../../assets/images/latest/car1.jpg";
import car2 from "../../assets/images/latest/car2.jpg";
import car3 from "../../assets/images/latest/car3.jpg";
import car4 from "../../assets/images/latest/car4.jpg";
import car5 from "../../assets/images/latest/car5.jpg";
import car6 from "../../assets/images/latest/car6.jpg";
import card1 from "../../assets/images/latest/card1.jpeg";
import card2 from "../../assets/images/latest/card2.jpeg";

const Driver = () => {

    const [activeTab, setactiveTab] = useState(1)
    const [activeTabVartical, setoggleTabVertical] = useState(1)
    const [showResults, setShowResults] = React.useState(false);
    const [editResults, seteditResults] = React.useState(false);
    const showfield = () => setShowResults(true);
    const hidefield = () => setShowResults(false);

    const [passedSteps, setPassedSteps] = useState([1])
    const [passedStepsVertical, setPassedStepsVertical] = useState([1])

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    function toggleTabVertical(tab) {
        if (activeTabVartical !== tab) {
            var modifiedSteps = [...passedStepsVertical, tab]

            if (tab >= 1 && tab <= 4) {
                setoggleTabVertical(tab)
                setPassedStepsVertical(modifiedSteps)
            }
        }
    }
    const [selectedMulti, setselectedMulti] = useState(null);
    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
    }

    const optionGroup = [
        {
            label: "Colors",
            options: [
                { label: "Beige", value: "Beige" },
                { label: "Black", value: "Black" },
                { label: "Blue", value: "Blue" },
                { label: "Brown", value: "Brown" },
                { label: "Dark Gray", value: "Dark Gray" },
            ]
        },
    ];
    const optionGroup1 = [
        {
            label: "Features",
            options: [
                { label: "3D Surround Camera", value: "Beige" },
                { label: "Memory Front Seats", value: "Black" },
                { label: "Blind Spot Warning", value: "Blue" },
                { label: "Adaptive Cruise Control", value: "Brown" },
                { label: "Digital HUD", value: "Dark Gray" },
                { label: "Temperature Controlled Seats", value: "Dark" },
            ]
        },
    ];

    const [isDisabled, setIsDisabled] = useState(true);
    const handleClick = () => {
        setIsDisabled(!isDisabled)
    };

    const [isDisabled1, setIsDisabled1] = useState(true);
    const handleClick1 = () => {
        setIsDisabled1(!isDisabled1)
    };
    const [isDisabled2, setIsDisabled2] = useState(false);
    const handleClick2 = () => {
        setIsDisabled2(!isDisabled2)
    };
    const [isDisabled3, setIsDisabled3] = useState(true);
    const handleClick3 = () => {
        setIsDisabled3(!isDisabled3)
    };
    const [isDisabled4, setIsDisabled4] = useState(false);
    const handleClick4 = () => {
        setIsDisabled4(!isDisabled4)
    };


    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFiles1, setselectedFiles1] = useState([])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }
    function handleAcceptedFiles1(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles1(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";


    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    const [fieldshow, setfieldshow] = useState()

    const [fieldshow1, setfieldshow1] = useState()

    function handleSelectChange(event) {
        setfieldshow(event.target.value);
    }
    function handleSelectChange1(event) {
        setfieldshow1(event.target.value);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Add Driver" />
                    <Row>
                        <Form>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                                    <div className="wizard clearfix">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 1 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ current: activeTab === 1 })}
                                                        onClick={() => {
                                                            setactiveTab(1)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(1)}
                                                    >
                                                        <span className="number"><i className="fas fa-user-shield"></i></span> Driver Details
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 2 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 2 })}
                                                        onClick={() => {
                                                            setactiveTab(2)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(2)}
                                                    >
                                                        <span className="number"><i className="fas fa-file-upload"></i></span> Documents

                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem
                                                    className={classnames({ current: activeTab === 3 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 3 })}
                                                        onClick={() => {
                                                            setactiveTab(3)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(3)}
                                                    >
                                                        <span className="number"><i className="fas fa-images"></i></span> Car Images
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 4 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 4 })}
                                                        onClick={() => {
                                                            setactiveTab(4)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(4)}
                                                    >
                                                        <span className="number"><i className="fas fa-money-check-alt"></i></span> PRICING
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 5 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 5 })}
                                                        onClick={() => {
                                                            setactiveTab(5)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(5)}
                                                    >
                                                        <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
                                                    </NavLink>
                                                </NavItem> */}

                                            </ul>
                                        </div>
                                        <div className="content clearfix mt-4">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <Form>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Full Name <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Mobile No <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter number"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Email <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Email"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Gender <span className="text-danger">*</span>
                                                                    </Label>
                                                                    {/* <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="basicpill-firstname-input3"
                                                        placeholder="Enter DOB"
                                                        required
                                                    /> */}
                                                                    <select className="form-select">
                                                                        <option value="male">Male</option>
                                                                        <option value="male">Female</option>
                                                                    </select>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Date of Birth <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter DOB"
                                                                        required
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Image
                                                                        {/* <span className="text-danger">*</span> */}
                                                                    </Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Image"

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Password <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Password"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Occupation <span className="text-danger">*</span>
                                                                    </Label>
                                                                
                                                                    <select className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="employee">Employee</option>
                                                                        <option value="businessman">Businessman</option>
                                                                        <option value="student">Student</option>
                                                                    </select>
                                                                </div>
                                                            </Col> */}
                                                             <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                    Residents Type <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <select required onChange={handleSelectChange} className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="res">Residency</option>
                                                                        <option value="gccres">GCC Residency</option>
                                                                        <option value="gccnonres">GCC NON-Residency</option>
                                                                        <option value="visitors">Visitors</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row>
{/* 
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Company Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Company"

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Address
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter number"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Id Card
                                                                    </Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"

                                                                    />
                                                                </div>
                                                            </Col>
                                                           
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                    Residents Type <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <select required onChange={handleSelectChange} className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="res">Residents</option>
                                                                        <option value="nonres">Non-Residents</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row> */}

                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    {fieldshow == "res" ? (
                                                        <div>
                                                            <Form>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Passport Front <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input1"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Passport Back <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Passport Visa <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Passport Emirates ID Front<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                                <Row className="mt-3">
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Passport Emirates ID Back<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input1"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                UAE driving license front<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                UAE driving license back <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    //    <p className="text-center text-danger">Please Select Residents Type  </p>
                                                        )}
                                                    {fieldshow == "gccres" ? (
                                                           <div>
                                                           <Form>
                                                               <Row>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input1">
                                                                               Passport Front <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input1"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               Passport Back <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   {/* <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               Passport Visa <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col> */}
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               Passport Emirates ID Front<span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input1">
                                                                               Passport Emirates ID Back<span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input1"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>

                                                               </Row>
                                                               <Row className="mt-3">
                                                                  
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               UAE driving license front<span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               UAE driving license back <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>

                                                               </Row>
                                                           </Form>
                                                       </div>
                                                    ) : (
                                                       ""
                                                        )}
                                                     {fieldshow == "gccnonres" ? (
                                                           <div>
                                                           <Form>
                                                               <Row>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input1">
                                                                               Passport Front <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input1"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               ID Front<span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input1">
                                                                              ID Back<span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input1"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                             Driving license <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>

                                                               </Row>
                                                               {/* <Row className="mt-3">
                                                                  
                                                                 
                                                                   <Col md={3}>
                                                                       <div className="mb-3">
                                                                           <Label for="basicpill-firstname-input3">
                                                                               UAE driving license back <span className="text-danger">*</span>
                                                                           </Label>
                                                                           <Input
                                                                               type="file"
                                                                               className="form-control"
                                                                               id="basicpill-firstname-input3"
                                                                               required
                                                                           />
                                                                       </div>
                                                                   </Col>

                                                               </Row> */}
                                                           </Form>
                                                       </div>
                                                    ) : (
                                                       ""
                                                        )}
                                                     {fieldshow == "visitors" ? (
                                                         <div>
                                                         <Form>
                                                             <Row>
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             Passport Copy <span className="text-danger">*</span>
                                                                         </Label>
                                                                         <Input
                                                                             type="file"
                                                                             className="form-control"
                                                                             id="basicpill-firstname-input3"
                                                                             required
                                                                         />
                                                                     </div>
                                                                 </Col>
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             Id card front <span className="text-danger">*</span>
                                                                         </Label>
                                                                         <Input
                                                                             type="file"
                                                                             className="form-control"
                                                                             id="basicpill-firstname-input3"
                                                                             required
                                                                         />
                                                                     </div>
                                                                 </Col>
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             Visa Copy<span className="text-danger">*</span>
                                                                         </Label>
                                                                         <Input
                                                                             type="file"
                                                                             className="form-control"
                                                                             id="basicpill-firstname-input3"
                                                                             required
                                                                         />
                                                                     </div>
                                                                 </Col>
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             License<span className="text-danger">*</span>
                                                                         </Label>
                                                                      
                                                                         <select onChange={handleSelectChange1} className="form-select">
                                                                            
                                                                             <option value="">Select</option>
                                                                             <option value="home">Home License</option>
                                                                             <option value="driving">International Driving License</option>
                                                                         </select>
                                                                     </div>
                                                                 </Col>

                                                             </Row>
                                                             {fieldshow1 == "home" ? (
                                                                 <Row className="mt-3">
                                                                     <Col md={3}>
                                                                         <div className="mb-3">
                                                                             <Label for="basicpill-firstname-input1">
                                                                                 Home License Front<span className="text-danger">*</span>
                                                                             </Label>
                                                                             <Input
                                                                                 type="file"
                                                                                 className="form-control"
                                                                                 id="basicpill-firstname-input1"
                                                                                 required
                                                                             />
                                                                         </div>
                                                                     </Col>
                                                                     <Col md={3}>
                                                                         <div className="mb-3">
                                                                             <Label for="basicpill-firstname-input3">
                                                                                 Home License Back<span className="text-danger">*</span>
                                                                             </Label>
                                                                             <Input
                                                                                 type="file"
                                                                                 className="form-control"
                                                                                 id="basicpill-firstname-input3"
                                                                                 required
                                                                             />
                                                                         </div>
                                                                     </Col>
                                                                 </Row>
                                                                 ) : (
""
                                                             )}
                                                             {fieldshow1 == "driving" ? (
                                                                 <Row className="mt-3">
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             Driving License Front <span className="text-danger">*</span>
                                                                         </Label>
                                                                         <Input
                                                                             type="file"
                                                                             className="form-control"
                                                                             id="basicpill-firstname-input3"
                                                                             required
                                                                         />
                                                                     </div>
                                                                 </Col>
                                                                 <Col md={3}>
                                                                     <div className="mb-3">
                                                                         <Label for="basicpill-firstname-input3">
                                                                             Driving License Back<span className="text-danger">*</span>
                                                                         </Label>
                                                                         <Input
                                                                             type="file"
                                                                             className="form-control"
                                                                             id="basicpill-firstname-input3"
                                                                             required
                                                                         />
                                                                     </div>
                                                                 </Col>

                                                             </Row>
                                                                 ) : (
""
                                                             )}

                                                         </Form>
                                                     </div>
                                                    ) : (
                                                       ""
                                                        )}

                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    {fieldshow == "nonres" ? (
                                                        <div>
                                                            <Form>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Countries <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {/* <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      required
                  /> */}
                                                                            <select className="form-select">
                                                                                <option value="saudi">Saudi Arabia</option>
                                                                                <option value="saudi">Oman</option>
                                                                                <option value="saudi">Bahrain</option>
                                                                                <option value="saudi">Belgium</option>
                                                                                <option value="saudi">Ireland</option>
                                                                                <option value="saudi">Turkey</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Passport Copy <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Id card front <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                License<span className="text-danger">*</span>
                                                                            </Label>
                                                                            {/* <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input3"
                      required
                  /> */}
                                                                            <select className="form-select">
                                                                                <option value="home">Home License</option>
                                                                                <option value="home">International Driving License</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                                <Row className="mt-3">
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Home License Front<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input1"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Home License Back<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                International Driving License Front <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                International Driving License Front<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    ) : (


                                                        <div>
                                                            <Form>
                                                                <Row>
                                                                    <Col lg="3">
                                                                        <div className="mb-3 mt-4">
                                                                            <div className="form-check mb-3" >
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="defaultCheck1"
                                                                                    onClick={handleClick}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="defaultCheck1"

                                                                                >
                                                                                    Available for Daily
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input11">
                                                                                Price Per Day
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">AED</div>
                                                                                <input disabled={isDisabled} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input21">
                                                                                Mileage
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">KM</div>
                                                                                <input type="text" disabled={isDisabled} className="form-control" id="basicpill-namecard-input21" placeholder="Mileage" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label>Minimum Day Booking</Label>
                                                                            <select disabled={isDisabled} className="form-select">
                                                                                <option value="">Select</option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>

                                                                        </div>
                                                                    </Col>


                                                                </Row>
                                                                <Row>
                                                                    <Col lg="3">
                                                                        <div className="mb-3 mt-4">
                                                                            <div className="form-check mb-3">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="defaultCheck2"
                                                                                    onClick={handleClick1}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="defaultCheck2"
                                                                                >
                                                                                    Available for Weekly
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input11">
                                                                                Price Per Week
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">AED</div>
                                                                                <input disabled={isDisabled1} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input21">
                                                                                Mileage
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">KM</div>
                                                                                <input disabled={isDisabled1} type="text" className="form-control" id="basicpill-namecard-input21" placeholder="Mileage" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col lg="3">
                                  <div className="mb-3">
                                    <Label>Minimum Day Booking</Label>
                                    <select className="form-select">
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    </select>
                              
                                  </div>
                                </Col> */}


                                                                </Row>

                                                                <Row>
                                                                    <Col lg="3">
                                                                        <div className="mb-3 mt-4">
                                                                            <div className="form-check mb-3">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="defaultCheck4"
                                                                                    onClick={handleClick2}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="defaultCheck4"
                                                                                >
                                                                                    It's Free!
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input11">
                                                                                Extra Millage Cost / km
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">AED</div>
                                                                                <input disabled={isDisabled2} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input21">
                                                                                CDW Insurance Per Day
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">KM</div>
                                                                                <input type="number" className="form-control" id="basicpill-namecard-input21" placeholder="" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col lg="3">
                                                                        <div className="mb-3 mt-4">
                                                                            <div className="form-check mb-3">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="defaultCheck3"
                                                                                    onClick={handleClick3}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="defaultCheck3"
                                                                                >
                                                                                    Available for Monthly
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-namecard-input11">
                                                                                CDW Insurance Per Month
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <div className="input-group-text">AED</div>
                                                                                <input disabled={isDisabled3} type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="table-responsive">

                                                                            <Table className="table table-sm table-bordered mb-5 mt-3">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Period / Mileage</th>
                                                                                        <th>1 Month </th>
                                                                                        <th>3 Months</th>
                                                                                        <th>6 Months</th>
                                                                                        <th>9 Months</th>
                                                                                        <th>12 Months</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th scope="row">2000KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="row">3000KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="row">4000KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="row">4500KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="row">5000KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="row">6000KM</th>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <div className="input-group-text">AED</div>
                                                                                                <input disabled={isDisabled3} type="number" className="form-control" id="basicpill-namecard-input11" placeholder="Charges" />
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </Form>
                                                        </div>
                                                    )}

                                                </TabPane>
                                                <TabPane tabId={4}>

                                                </TabPane>
                                                <TabPane tabId={5}>
                                                    <div>
                                                        <Form>
                                                            <Row>
                                                                <Col lg="2">
                                                                    <div className="mb-3 mt-4">
                                                                        <div className="form-check mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value=""
                                                                                id="defaultCheck5"
                                                                                onClick={handleClick4}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="defaultCheck5"
                                                                            >
                                                                                Not Required
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="3">
                                                                    <div className="mb-3">
                                                                        <Label for="basicpill-namecard-input11">
                                                                            Security Deposit
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="basicpill-namecard-input11"
                                                                            placeholder="Enter Amount"
                                                                            disabled={isDisabled4}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg="3">
                                                                    <div className="mb-3">
                                                                        <Label>Excess Claim Amount</Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="basicpill-namecard-input11"
                                                                            placeholder="Enter Amount"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="mb-3">
                                                                        <Label>Special Note for Customers</Label>
                                                                        <textarea
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-namecard-input11"
                                                                            placeholder="Upto 150 characters"
                                                                        />
                                                                    </div>
                                                                </Col>


                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPane>

                                            </TabContent>
                                        </div>
                                        <div className="actions clearfix">
                                            <ul>
                                                <li
                                                    className={
                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1)
                                                        }}
                                                        style={{background:"#d05151"}}
                                                    >
                                                      <i className="fas fa-arrow-circle-left"></i>  Previous
                                                    </Link>
                                                </li>
                                                <li
                                                    className={activeTab === 2 ? "next logbtn" : "next"}
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1)
                                                        }}
                                                    >
                                                        Next <i className="fas fa-arrow-circle-right"></i>
                                                    </Link>
                                                </li>
                                                <li
                                                    className={
                                                        activeTab < 2 ? "previous logbtn " : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        className="btn btn-primary" type="submit"
                                                    >
                                                       <i className="fas fa-check-circle"></i> Submit 
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        </Form>
                    </Row>




                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Users
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Branch <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Dubai</option>
                                            <option value="AbuDhabi">Abu Dhabi</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Phone No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Phone No"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Department <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Manager</option>
                                            <option value="AbuDhabi">Employee</option>
                                            {/* <option value="AbuDhabi">Driver</option> */}
                                            {/* <option value="AbuDhabi">Customer</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Email <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="emial"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Password <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Password"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div>
                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="button">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Driver
