import React, { useEffect, useState } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";
import axios from "axios";


const Specification = () => {



    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    const [brand, setbrand] = useState([])
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };
    const handleChange1 = (e) => {
        let myUser = { ...form1 };
        myUser[e.target.name] = e.target.value;
        setform1(myUser);
    };

    var gets = localStorage.getItem("authUser");
    var data = JSON.parse(gets);
    var datas = data.token;
    console.log(datas)

    const getAllfeature = () => {
        var token = datas;
        axios.post(URL.allcarspecs,{}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setbrand(res.data.specsResult)
        })
    }

    const addFeature = () => {
        var token = datas;
        const dataArray = new FormData();
        dataArray.append("specName", form.specName);
        axios.post(URL.addspecification, dataArray, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllfeature();
                    clearForm()

                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const editFeature = () => {
        var token = datas;
        const docid = form1._id
        const dataArray = new FormData();
        dataArray.append("specName", form1.specName);
        dataArray.append("status", form1.status);
        axios.put(URL.editcarspec +"/"+ docid, dataArray, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllfeature();
                    setmodal_small(false)


                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const deletefeature = (data) => {
        var token = datas;
        var remid = data._id
        axios.put(URL.removecarspec + "/" + remid,{}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllfeature();

                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const manageDelete = (data) => {
        const confirmBox = window.confirm("Do you really want to Inactive?");
        if (confirmBox === true) {
            deletefeature(data);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addFeature();
        setform("")
        clearForm()
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();
        editFeature();
        // setform1("")
        clearForm()
    };

    const clearForm = () => {
        setform({
            specName: "",
        });
    };

    const getpopup = (data) => {
        setform1(data);
        tog_small()
    };

    useEffect(() => {
        getAllfeature()
    }, [])

    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = brand.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(brand.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [forms, setforms] = useState([]);
    console.log(forms)
    
    const handlechange = (e) => {
        let myUser = { ...forms };
        myUser[e.target.name] = e.target.value;
        setforms(myUser);
        console.log(myUser)
        var token = datas;
        axios
            .post(
                `https://api.freedomcars.co.in/v1/admin/carspecs/getallcarspecifications?searchQuery=${e.target.value}`,
                {},
    
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((res) => {
                setbrand(res.data.specsResult)
            });
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Car Specification" />

                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Add Specification</CardTitle>
                                </CardHeader>
                                <CardBody >
                                    <Form onSubmit={(e)=>{handleSubmit(e)}}>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Name <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="specName"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter Specification Name"
                                                required
                                                value={form.specName}
                                                onChange={(e)=>{handleChange(e)}}
                                            />
                                        </div>
                                    
                                        <div style={{ float: "right" }}>
                                            <Button color="primary" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>

                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Specification List</CardTitle>
                                </CardHeader>

                                <CardBody >

                                    <div>
                                        <div className="table-responsive">
                                            <div style={{ float: "right" }}>
                                                <Input
                                                   name="search"
                                                   value={forms.search}
                                                   onChange={handlechange}
                                                   type="text"
                                                className="form-control" placeholder="Search.." />
                                            </div>
                                            <Table className="table table-bordered mb-4 mt-5">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Car Specifications</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((data, key) => (
                                                        <tr key={key}>
                                                            <th scope="row">{(pageNumber - 1) * 5 + key + 6}</th>
                                                            <td>{data.specName}</td>
                                                            <td>{data.logDateCreated}</td>
                                                            <td>
                                                                {data.status == true ?(
                                                                    "Active"
                                                                ):("Inactive")}
                                                            </td>
                                                            <td>
                                                                <Button onClick={()=>{getpopup(data)}} 
                                                                    className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                    <i className="bx bx-edit "></i>
                                                                </Button>
                                                                <Button onClick={()=>{manageDelete(data)}} style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                    <i className="bx bx-block"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>
                                                {/* <Stack spacing={2}> */}
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                                {/* </Stack> */}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
                <ToastContainer/>


                <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Car Specification
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e)=>{handleSubmit1(e)}}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Specification Name"
                                    required
                                    name="specName"
                                    value={form1.specName}
                                    onChange={(e)=>{handleChange1(e)}}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                    Status <span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter City"
                                    required
                                /> */}
                                <select onChange={(e)=>{handleChange1(e)}} value={form1.status} name="status" className="form-select">
                                    <option value="">Select</option>
                                    <option value="true">Active</option>
                                    <option value="false">In Active</option>
                                </select>
                            </div>
                            {/* <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div> */}
                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
           
            </div>
        </React.Fragment>
    )
}

export default Specification
