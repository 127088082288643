import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"

const Carsedit = () => {
  //meta title
  // document.title = "Freedom Cars ";

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const [isDisabled, setIsDisabled] = useState(true)
  const handleClick = () => {
    setIsDisabled(!isDisabled)
  }

  const [isDisabled1, setIsDisabled1] = useState(true)
  const handleClick1 = () => {
    setIsDisabled1(!isDisabled1)
  }
  const [isDisabled2, setIsDisabled2] = useState(false)
  const handleClick2 = () => {
    setIsDisabled2(!isDisabled2)
  }
  const [isDisabled3, setIsDisabled3] = useState(true)
  const handleClick3 = () => {
    setIsDisabled3(!isDisabled3)
  }
  const [isDisabled4, setIsDisabled4] = useState(false)
  const handleClick4 = () => {
    setIsDisabled4(!isDisabled4)
  }

  const [selectedFiles, setselectedFiles] = useState([])

  const [selectedFiles1, setselectedFiles1] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [fieldshow, setfieldshow] = useState()

  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }

  const [cars, setcars] = useState([])
  const [spce, setspce] = useState([])
  const [fut, setfut] = useState([])
  const [model1, setmodel1] = useState([])
  const [model, setmodel] = useState([])
  const [form, setform] = useState([])

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const [form3, setform3] = useState([])
  const [form4, setform4] = useState([])
  const [form5, setform5] = useState([])
  const [form6, setform6] = useState([])

  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }
  const handleChange3 = e => {
    let myUser = { ...form3 }
    myUser[e.target.name] = e.target.value
    setform3(myUser)
  }

  const handleChange4 = e => {
    let myUser = { ...form4 }
    myUser[e.target.name] = e.target.value
    setform4(myUser)
  }

  const handleChange5 = e => {
    let myUser = { ...form5 }
    myUser[e.target.name] = e.target.value
    setform5(myUser)
  }
  const handleChange6 = e => {
    let myUser = { ...form6 }
    myUser[e.target.name] = e.target.value
    setform6(myUser)
  }

  const [dats, setdats] = useState([])
  const [dats2, setdats2] = useState([])
  const [dats3, setdats3] = useState([])
  console.log(dats)

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    ///setSelectedOptions(current => [...current, details.value]);
    setSelectedOptions(details)
  }

  const zzz = dats3.map(response => ({
    label: response.id,
    value: response.value,
  }))

  const zzz2 = dats2.map(response => ({
    value: response,
  }))

  // const zzz = {
  //   value: dats,
  //   label: dats2

  // }
  // value: response._id,
  // label: response.featureName,

  const cursid = sessionStorage.getItem("carid")

  const getonecars = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("_id", cursid)
    axios
      .post(URL.getonecars, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setdats(res.data.carResult.carFeatures)
        setdats2(res.data.carResult.carFeatureId)
        setSelectedOptions(res.data.carResult.carFeatureObj)
        // carFeatureId

        setform(res.data.carResult)
        setformS0(res.data.carResult)
        console.log(res.data.carResult.carPrices[0])
        setform1(
          res.data.carResult.carPrices[0] != undefined
            ? res.data.carResult.carPrices[0]
            : console.log("No value")
        )
        setform2(
          res.data.carResult.carPrices[1] != undefined
            ? res.data.carResult.carPrices[1]
            : {
              kms: "",
              onedayPrice: "",
              sixHoursprice: "",
              tweleveHoursprice: "",
              unlimitedStatus: "",
              _id: "",
            }
        )
        setform3(
          res.data.carResult.carPrices[2] != undefined
            ? res.data.carResult.carPrices[2]
            : {
              kms: "",
              onedayPrice: "",
              sixHoursprice: "",
              tweleveHoursprice: "",
              unlimitedStatus: "",
              _id: "",
            }
        )
        setform4(
          res.data.carResult.carPrices[3] != undefined
            ? res.data.carResult.carPrices[3]
            : {
              kms: "",
              onedayPrice: "",
              sixHoursprice: "",
              tweleveHoursprice: "",
              unlimitedStatus: "",
              _id: "",
            }
        )
        setform5(
          res.data.carResult.carPrices[4] != undefined
            ? res.data.carResult.carPrices[4]
            : {
              kms: "",
              onedayPrice: "",
              sixHoursprice: "",
              tweleveHoursprice: "",
              unlimitedStatus: "",
              _id: "",
            }
        )
        setform6(
          res.data.carResult.carPrices[5] != undefined
            ? res.data.carResult.carPrices[5]
            : {
              kms: "",
              onedayPrice: "",
              sixHoursprice: "",
              tweleveHoursprice: "",
              unlimitedStatus: "",
              _id: "",
            }
        )
      })
  }

  const handleChange1s = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }
  const handleChange2s = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }
  const handleChange3s = e => {
    let myUser = { ...form3 }
    myUser[e.target.name] = e.target.value
    setform3(myUser)
  }
  const handleChange4s = e => {
    let myUser = { ...form4 }
    myUser[e.target.name] = e.target.value
    setform4(myUser)
  }
  const handleChange5s = e => {
    let myUser = { ...form5 }
    myUser[e.target.name] = e.target.value
    setform5(myUser)
  }
  const handleChange6s = e => {
    let myUser = { ...form6 }
    myUser[e.target.name] = e.target.value
    setform6(myUser)
  }

  const getAllcars = () => {
    var token = datas
    axios
      .post(
        URL.allcars,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcars(res.data.carsResult)
        // setform0(res.data.isPopular)

        // setform01(res.data.isFeatured)
      })
  }

  const getactivebrand = () => {
    var token = datas
    axios
      .post(
        URL.activecarbrands,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setmodel1(res.data.activecarBrandResult)
      })
  }

  const getAllmodal = () => {
    var token = datas
    axios
      .post(
        URL.allcaractivemodels,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // setmodel(res.data.modelResult)
      })
  }

  const getAllfeature = () => {
    var token = datas
    axios
      .post(
        URL.activefeature,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setfut(res.data.featuresResult)
      })
  }

  const getAllspfc = () => {
    var token = datas
    axios
      .post(
        URL.activecarspecs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setspce(res.data.activeSpecs)
      })
  }

  const priceArr = [
    {
      _id: form1 != undefined ? form1._id : "",
      sixHoursprice:
        form1.sixHoursprice != undefined ? form1.sixHoursprice : "",
      kms: form1 != undefined ? form1.kms : "",
      tweleveHoursprice: form1 != undefined ? form1.tweleveHoursprice : "",
      onedayPrice: form1 != undefined ? form1.onedayPrice : "",
      unlimitedStatus: form1.unlimitedStatus,
    },
    {
      _id: form2 != undefined ? form2._id : "",
      sixHoursprice: form2 != undefined ? form2.sixHoursprice : "",
      kms: form2 != undefined ? form2.kms : "",
      tweleveHoursprice: form2 != undefined ? form2.tweleveHoursprice : "",
      onedayPrice: form2 != undefined ? form2.onedayPrice : "",
      unlimitedStatus:
        form2.unlimitedStatus != undefined ? form2.unlimitedStatus : "",
    },
    {
      _id: form3 != undefined ? form3._id : "",
      sixHoursprice: form3 != undefined ? form3.sixHoursprice : "",
      kms: form3 != undefined ? form3.kms : "",
      tweleveHoursprice: form3 != undefined ? form3.tweleveHoursprice : "",
      onedayPrice: form3 != undefined ? form3.onedayPrice : "",
      unlimitedStatus:
        form3.unlimitedStatus != undefined ? form3.unlimitedStatus : "",
    },
    {
      _id: form4 != undefined ? form4._id : "",
      sixHoursprice: form4 != undefined ? form4.sixHoursprice : "",
      kms: form4 != undefined ? form4.kms : "",
      tweleveHoursprice: form4 != undefined ? form4.tweleveHoursprice : "",
      onedayPrice: form4 != undefined ? form4.onedayPrice : "",

      unlimitedStatus:
        form4.unlimitedStatus != undefined ? form4.unlimitedStatus : "",
    },
    {
      _id: form5 != undefined ? form5._id : "",
      sixHoursprice: form5 != undefined ? form5.sixHoursprice : "",
      kms: form5 != undefined ? form5.kms : "",
      tweleveHoursprice: form5 != undefined ? form5.tweleveHoursprice : "",
      onedayPrice: form5 != undefined ? form5.onedayPrice : "",
      unlimitedStatus:
        form5.unlimitedStatus != undefined ? form5.unlimitedStatus : "",
    },
    {
      _id: form6 != undefined ? form6._id : "",
      sixHoursprice: form6 != undefined ? form6.sixHoursprice : "",
      kms: form6 != undefined ? form6.kms : "",
      tweleveHoursprice: form6 != undefined ? form6.tweleveHoursprice : "",
      onedayPrice: form6 != undefined ? form6.onedayPrice : "",
      unlimitedStatus:
        form6.unlimitedStatus != undefined ? form6.unlimitedStatus : "",
    },
  ]

  const datass = JSON.stringify(priceArr)

  const history = useHistory()

  const addnewcar = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carType", form.carType)
    dataArray.append("vehicleType", form.vehicleType)
    dataArray.append("carBrandId", form.carBrandId)
    dataArray.append("carModelId", formS0.carModelId)
    dataArray.append("carMakeYearId", form.carMakeYearId)
    dataArray.append("carRegistNumber", form.carRegistNumber)
    dataArray.append("carSpecId", form.carSpecId)
    dataArray.append("carBootCapacity", form.carBootCapacity)
    dataArray.append("carColorIds", form.carColorIds)
    dataArray.append("carReading", form.carReading)
    dataArray.append("insurance", form.insurance)
    dataArray.append("priceArr", datass)

    for (let i = 0; i < selectedOptions.length; i++) {
      dataArray.append("carFeatureId", selectedOptions[i].value)
    }

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("carImages", Files[i])
    }
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("carRegisterImages", Files1[i])
    }
    axios
      .put(
        "https://api.freedomcars.co.in/v1/admin/car/editcar" + "/" + form._id,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/cars")
            // window.location("/cars")

            // clearForm()
            // handlenavigate()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Handlesubmit = e => {
    e.preventDefault()
    addnewcar()
  }

  useEffect(() => {
    getcolor()
    getonecars()
    getactivebrand()
    getAllmodal()
    getAllfeature()
    getAllspfc()
  }, [])

  const optionGroup123 = [
    {
      label: "Colors",
      options: [
        { label: "Beige", value: "Beige" },
        { label: "Black", value: "Black" },
        { label: "Blue", value: "Blue" },
        { label: "Brown", value: "Brown" },
        { label: "DarkGray", value: "Dark Gray" },
      ],
    },
  ]

  const optionGroup1 = fut.map(response => ({
    value: response._id,
    label: response.featureName,
  }))

  const optionGroup2 = fut.map(response => ({
    value: response._id,
    label: response.featureName,
  }))

  const onChange = () => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case "clear":
        break
    }

    setValue(orderOptions(newValue))
  }

  const [selectedMulti1, setselectedMulti1] = useState([])
  console.log(selectedMulti1)
  function handleMulti(data12) {
    setselectedMulti1(data12)
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = cars.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(cars.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  // const cursid = sessionStorage.getItem("carid")

  // const getonecars = () => {
  //     var token = datas;
  //     const dataArray = new FormData();
  //     dataArray.append("_id", cursid);
  //     axios.post(URL.getonecars, dataArray, {
  //         headers: { Authorization: `Bearer ${token}` }
  //     }).then((res) => {
  //
  //         setform(res.data.carResult)
  //         setform1(res.data.carResult.carPrices)
  //     })
  // }

  const [color, setcolor] = useState([])

  const getcolor = () => {
    var token = datas
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/color/getallcarcolors",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcolor(res.data.colorResult)
      })
  }

  const [formS0, setformS0] = useState([])

  const Optionchange12 = e => {
    let myUser = { ...formS0 }
    myUser.carModelId = e.target.value
    setformS0(myUser)
    getversons(e.target.value)
  }

  const [versSeat, setversSeat] = useState("")

  console.log(versSeat)


  const getversons = _id => {
    var token = datas
    const dataArray = new FormData()
    // dataArray.append("carType", form.carType);
    dataArray.append("modelId", _id)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carversion/getcarversionbymodelid",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setvers(res.data.carversions)
        setversSeat(res.data.noOfSeats)
      })
  }

  const Optionchang = e => {
    let myUser = { ...form }
    myUser.carColorIds = e.target.value
    setform(myUser)
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser.carBrandId = e.target.value
    setform(myUser)
    getAllmodals(e.target.value)
  }

  const [vers, setvers] = useState([])

  const getAllmodals = _id => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carType", form.carType)
    dataArray.append("brandId", _id)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carmodel/getcarmodelbytypebrand",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setmodel(res.data.modelsResult)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Cars Edit" />

          {/* Add cars */}

          <Row>
            <div>
              <Link to="/cars">
                {" "}
                <Button style={{ float: "right" }} className="btn-info mb-3">
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Button>
              </Link>
            </div>
            <Form
              onSubmit={e => {
                Handlesubmit(e)
              }}
            >
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        {/* <Row>
                          <Col>
                            <h5 className="mb-3">Add Car</h5>
                          </Col>
                          <Col>
                            <div style={{ float: "right" }}>
                              <a onClick={hidefield} className="text-danger"> <i className="far fa-times-circle"></i> Close</a>
                            </div>
                          </Col>
                        </Row> */}
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">
                                <i className="fas fa-car"></i>
                              </span>{" "}
                              SELECT CAR
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">
                                <i className="fas fa-shield-alt"></i>
                              </span>{" "}
                              CAR SPECS
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">
                                <i className="fas fa-images"></i>
                              </span>{" "}
                              Car Images
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">
                                <i className="fas fa-money-check-alt"></i>
                              </span>{" "}
                              PRICING
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(5)}
                          >
                            <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
                          </NavLink>
                        </NavItem> */}
                        </ul>
                      </div>
                      <div className="content clearfix mt-4">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            <Row>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Car Type
                                  </Label>
                                  {/* <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                  /> */}
                                  <select
                                    value={form.carType}
                                    required
                                    name="carType"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="compactsuv">
                                      Compact SUV
                                    </option>
                                    <option value="hatchback">Hatchback</option>
                                    <option value="suv">SUV</option>
                                    <option value="sedan">Sedan</option>
                                  </select>
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Car Brand
                                  </Label>
                                  {/* <Select
                                    defaultValue={selectedOption1}
                                    onChange={setSelectedOption1}
                                    options={options1}
                                  /> */}
                                  <select
                                    value={form.carBrandId}
                                    name="carBrandId"
                                    onChange={e => {
                                      Optionchange(e)
                                    }}
                                    className="form-select"
                                  >
                                    {/* <option value="">Select</option> */}
                                    <option value="">Select</option>
                                    {model1.map((data, key) => {
                                      return (
                                        <option key={key} value={data._id}>
                                          {data.title}
                                        </option>
                                        // options={data}
                                      )
                                    })}
                                    {/* <option value="Audi">Audi</option> */}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Model
                                  </Label>

                                  {/* <Select
                                    defaultValue={selectedOption2}
                                    onChange={setSelectedOption2}
                                    options={options2}
                                  /> */}

                                  <select
                                    value={formS0.carModelId}
                                    name="carModelId"
                                    onChange={e => {
                                      Optionchange12(e)
                                    }}
                                    className="form-select"
                                  >
                                    {model != "" ? (
                                      ""
                                    ) : (
                                      <option value={form.carModelId}>
                                        {form.carModelName}
                                      </option>
                                    )}

                                    {model.map((data, key) => {
                                      return (
                                        <option key={key} value={data._id}>
                                          {data.model_name}
                                        </option>
                                      )
                                    })}
                                  </select>

                                  {/* {optionCircle != "" ? (
                                ""
                              ) : (
                                <option value={user.circles_id}>
                                  {user.circle}
                                </option>
                              )}
                              {optionCircle.map((opt) => {
                                return (
                                  <option value={opt.id}>{opt.name}</option>
                                );
                              })} */}
                                </div>
                              </Col>

                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    Version
                                  </Label>
                                  <select
                                    value={form.carMakeYearId}
                                    name="carMakeYearId"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    {vers != "" ? (
                                      ""
                                    ) : (
                                      <option value={form.carMakeYearId}>
                                        {form.carMakeYear}
                                      </option>
                                    )}

                                    {vers.map((data, key) => {
                                      return (
                                        <option key={key} value={data._id}>
                                          {data.carVersion}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Select M/A/B{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  {/* <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                  /> */}
                                  <select
                                    value={form.vehicleType}
                                    name="vehicleType"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="manual">Manual</option>
                                    <option value="automatic">Automatic</option>
                                    <option value="battery">Battery</option>
                                  </select>
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Car Registration Number
                                  </Label>
                                  <Input
                                    value={form.carRegistNumber}
                                    name="carRegistNumber"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    type="text"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    placeholder=" Car Registration Number"
                                  />
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Car Reading
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Car Reading"
                                    required
                                    value={form.carReading}
                                    name="carReading"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              {versSeat == "" ? (
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      No.of Seates
                                    </Label>
                                    <Input
                                      disabled
                                      type="text"
                                      name="noOfSeats"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="No.of Seates"
                                      required
                                      value={versSeat}
                                      defaultValue={form.seatCount}
                                    />
                                  </div>
                                </Col>
                              ) : (
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      No.of Seates
                                    </Label>
                                    <Input
                                      disabled
                                      type="text"
                                      name="noOfSeats"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="No.of Seates"
                                      required
                                      defaultValue={form.seatCount}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-pancard-input5">
                                      Available Colors
                                    </Label>
                                    {/* <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-pancard-input5"
                                      placeholder="Enter Your PAN No."
                                    /> */}
                                    {/* <Select
                                      value={zzz1}
                                      isMulti={true}
                                      onChange={handleMulti}
                                      options={optionGroup123}
                                      name="carColorAvailble"
                                      // defaultValue={dats1}
                                    /> */}
                                    <select
                                      value={form.carColorIds}
                                      name="carColorIds"
                                      onChange={e => {
                                        Optionchang(e)
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>

                                      {color.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            {data.colorName}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </Col>

                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-vatno-input6">
                                      Car Features
                                    </Label>
                                    {/* <Select
                                      value={selectedMulti}
                                      isMulti={true}
                                      onChange={() => {
                                        handleMulti();
                                      }}
                                      options={optionGroup1}
                                      classNamePrefix="select2-selection"
                                    /> */}

                                    {/* <Select
                                      style={{ width: "100%" }}
                                      required
                                      options={optionGroup1}
                                      placeholder="Select Feature"
                                      value={zzz}
                                      onChange={handleSelect}
                                      isSearchable={true}
                                      isMulti
                                      name="carFeatureId"
                                      defaultValue={zzz}
                                      // defaultValue={zzz}
                                    /> */}

                                    <Select
                                      closeMenuOnSelect={true}
                                      onChange={handleSelect}
                                      isMulti
                                      options={optionGroup1}
                                      value={selectedOptions}
                                      placeholder="Select Feature"
                                    />

                                    {/* <Select
    options={fut}
    value={fut.filter(({ _id }) => _id === dats2)}
    getOptionLabel={({ featureName  }) => featureName}
    getOptionValue={({ _id  }) => _id }
     getOptionLabel={ x => x.id}
                                      getOptionValue={ x => x.value}
    onChange={handleSelect}
  /> */}
                                  </div>
                                </Col>

                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-servicetax-input8">
                                      Specification
                                    </Label>
                                    {/* <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-servicetax-input8"
                                      placeholder="Enter Your Service Tax No."
                                    /> */}
                                    <select
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      value={form.carSpecId}
                                      name="carSpecId"
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      {spce.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            {data.specName}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </Col>

                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      Boot Capacity
                                    </Label>
                                    <Input
                                      value={form.carBootCapacity}
                                      name="carBootCapacity"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      type="number"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      placeholder="Boot Capacity"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col md={6}>
                                      <Label>Add Car Images </Label>
                                      <Input
                                        type="file"
                                        multiple
                                        name="carImages"
                                        value={form.carImages}
                                        onChange={changeHandler}
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label>Add Registration Card</Label>

                                      <Input
                                        type="file"
                                        multiple
                                        name="carRegisterImages"
                                        value={form.carRegisterImages}
                                        onChange={changeHandler1}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <Row>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Insurance Amount <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Insurance Amount"
                                    required
                                    value={form.insurance}
                                    name="insurance"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="table-responsive">
                                  <Table className="table table-sm table-bordered mb-5 mt-3">
                                    <thead>
                                      <tr>
                                        <th>Period / Mileage</th>
                                        <th>6 Hours</th>
                                        <th>12 Hours</th>
                                        <th>Unlimited kms</th>
                                        <th>1 day</th>

                                        {/* <th>9 Months</th>
                                          <th>12 Months</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              value={form1.kms}
                                              name="kms"
                                              onChange={e => {
                                                handleChange1(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={form1.sixHoursprice}
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange1(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={form1.tweleveHoursprice}
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange1(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <select
                                            value={form1.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange1s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={form1.onedayPrice}
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange1(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              value={
                                                form2 != undefined
                                                  ? form2.kms
                                                  : ""
                                              }
                                              // value={form2.kms}
                                              name="kms"
                                              onChange={e => {
                                                handleChange2(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form2 != undefined
                                                  ? form2.sixHoursprice
                                                  : ""
                                              }
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange2(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form2 != undefined
                                                  ? form2.tweleveHoursprice
                                                  : ""
                                              }
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange2(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <select
                                            value={form2.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange2s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>

                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form2 != undefined
                                                  ? form2.onedayPrice
                                                  : ""
                                              }
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange2(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              value={
                                                form3 != undefined
                                                  ? form3.kms
                                                  : ""
                                              }
                                              name="kms"
                                              onChange={e => {
                                                handleChange3(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form3 != undefined
                                                  ? form3.sixHoursprice
                                                  : ""
                                              }
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange3(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.tweleveHoursprice}
                                              value={
                                                form3 != undefined
                                                  ? form3.tweleveHoursprice
                                                  : ""
                                              }
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange3(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <select
                                            value={form3.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange3s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>

                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form3 != undefined
                                                  ? form3.onedayPrice
                                                  : ""
                                              }
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange3(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              value={
                                                form4 != undefined
                                                  ? form4.kms
                                                  : ""
                                              }
                                              name="kms"
                                              onChange={e => {
                                                handleChange4(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.sixHoursprice3}
                                              value={
                                                form4 != undefined
                                                  ? form4.sixHoursprice
                                                  : ""
                                              }
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange4(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.tweleveHoursprice3}
                                              value={
                                                form4 != undefined
                                                  ? form4.tweleveHoursprice
                                                  : ""
                                              }
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange4(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>

                                        <td>
                                          <select
                                            value={form4.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange4s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.onedayPrice3}
                                              value={
                                                form4 != undefined
                                                  ? form4.onedayPrice
                                                  : ""
                                              }
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange4(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              // value={form.kms4}
                                              value={
                                                form5 != undefined
                                                  ? form5.kms
                                                  : ""
                                              }
                                              name="kms"
                                              onChange={e => {
                                                handleChange5(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.sixHoursprice4}
                                              value={
                                                form5 != undefined
                                                  ? form5.sixHoursprice
                                                  : ""
                                              }
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange5(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.tweleveHoursprice4}
                                              value={
                                                form5 != undefined
                                                  ? form5.tweleveHoursprice
                                                  : ""
                                              }
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange5(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <select
                                            value={form5.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange5s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.onedayPrice4}
                                              value={
                                                form5 != undefined
                                                  ? form5.onedayPrice
                                                  : ""
                                              }
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange5(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <InputGroup>
                                            <div className="input-group-text">
                                              KM
                                            </div>
                                            <input
                                              // value={form.kms5}
                                              value={
                                                form6 != undefined
                                                  ? form6.kms
                                                  : ""
                                              }
                                              name="kms"
                                              onChange={e => {
                                                handleChange6(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Mileage"
                                            />
                                          </InputGroup>
                                        </th>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form.sixHoursprice5}
                                              value={
                                                form6 != undefined
                                                  ? form6.sixHoursprice
                                                  : ""
                                              }
                                              name="sixHoursprice"
                                              onChange={e => {
                                                handleChange6(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              // value={form1.tweleveHoursprice5}
                                              value={
                                                form6 != undefined
                                                  ? form6.tweleveHoursprice
                                                  : ""
                                              }
                                              name="tweleveHoursprice"
                                              onChange={e => {
                                                handleChange6(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                        <td>
                                          <select
                                            value={form6.unlimitedStatus}
                                            name="unlimitedStatus"
                                            onChange={e => {
                                              handleChange6s(e)
                                            }}
                                            className="form-select"
                                          >
                                            <option value="">
                                              Select Unlimited
                                            </option>
                                            <option value="Yes">Yes</option>
                                          </select>
                                        </td>
                                        <td>
                                          <InputGroup>
                                            <div className="input-group-text">
                                              {" "}
                                              Rupee
                                            </div>
                                            <input
                                              value={
                                                form6 != undefined
                                                  ? form6.onedayPrice
                                                  : ""
                                              }
                                              name="onedayPrice"
                                              onChange={e => {
                                                handleChange6(e)
                                              }}
                                              type="number"
                                              className="form-control"
                                              id="basicpill-namecard-input11"
                                              placeholder="Charges"
                                            />
                                          </InputGroup>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          {/* <TabPane tabId={5}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3 mt-4">
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck5"
                                        onClick={handleClick4}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck5"
                                      >
                                        Not Required
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Security Deposit
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Enter Amount"
                                      disabled={isDisabled4}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label>Excess Claim Amount</Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Enter Amount"
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label>Special Note for Customers</Label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Upto 150 characters"
                                    />
                                  </div>
                                </Col>


                              </Row>
                            </Form>
                          </div>
                        </TabPane> */}
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                              style={{ background: "#d05151" }}
                            >
                              <i className="fas fa-arrow-circle-left"></i>{" "}
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 4 ? "next logbtn" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next <i className="fas fa-arrow-circle-right"></i>
                            </Link>
                          </li>
                          <li
                            className={
                              activeTab < 4 ? "previous logbtn " : "previous"
                            }
                          >
                            <Button type="submit" color="primary">
                              Submit <i className="fas fa-check-circle"></i>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Form>
          </Row>
          <ToastContainer />
          {/* Edit cars */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Carsedit
