import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import car1 from "../../assets/images/latest/car1.jpg"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../../Apiurl"
import axios from "axios"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import html2canvas from "html2canvas"
import pdfMake from "pdfmake"
import { CSVLink } from "react-csv"


import jsPDF from "jspdf"
import "jspdf-autotable"

function Cancel() {
  const [coup, setcoup] = useState([])
  useEffect(() => {
    getBookingReports()
  }, [])

  
  const [userInCsv, setuserInCsv] = useState([]);

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const [form, setform] = useState([])

  const getBookingReports = () => {
    var token = datas
    const dataArray = new FormData()
    // dataArray.append("status", "All");
    dataArray.append("fromDate", "")
    dataArray.append("toDate", "")

    axios
      .post(URL.cancelledreports, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        setcoup(res.data.bookingResult)
        setuserInCsv(res.data.ExcelData)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    status: "All",
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    getAllbookings123()
    // clearForm()
  }
  const getAllbookings123 = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("fromDate", filters.fromDate)
    dataArray.append("toDate", filters.toDate)
    // dataArray.append("status", filters.status);
    // dataArray.append("status", filters.status);
    axios
      .post(URL.cancelledreports, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        // getAllbookings()
        hidefilter()
        setfilters("")
        setcoup(res.data.bookingResult)
        setuserInCsv(res.data.ExcelData)
      })
  }

  const hidefield = () => setaddbooking(false)
  const hideeditfield = () => seteditbooking(false)
  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const genPdf = () => {
    html2canvas(document.getElementById("empTable")).then(canvas => {
      var data = canvas.toDataURL()
      var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      }
      pdfMake.createPdf(pdfExportSetting).download("file.pdf")
    })
  }

  const csvReport = {
    filename: "Cancel Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2" // Use A1, A2, A3 or A4
    const orientation = "portrait" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const headers = [
      [
        "SNO",
        "Date",
        "BookingId",
        "CustomerName",
        "Phone",
        "Email",
        "CarModelName",
        "TimeSlot",
        "PickupDate",
        "DeliveryDate",
        "PickupTime",
        "DeliveryTime",
        "Status",
        "Totalprice",
      ],
    ]

    const data = coup.map((elt, i) => [
      i + 1,
      elt.date,
      elt.booking_id,
      elt.customerName,
      elt.phone,
      elt.email,
      elt.carModelName,
      elt.timeSlot == "6" || elt.timeSlot == "12" ? elt.timeSlot+"/hrs":"Days", 
      elt.fromDate,
      elt.toDate,
      elt.fromTime,
      elt.toTime,
      elt.status,
      elt.totalprice,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("report.pdf")
  }


  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Freedom Cars"
              breadcrumbItem="Cancelled Booking Report"
            />

            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        {/* <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Bookings <span className="text-danger">*</span>
                                                            </Label>
                                                            <select
                                                                onChange={(e) => { handleChangeflt(e) }}
                                                                name="status"
                                                                value={filters.status}
                                                                className="form-select">
                                                                <option value="All">All</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="accepted">Accepted</option>
                                                                <option value="completed">Completed</option>
                                                                <option value="cancelled">Cancelled</option>
                                                            </select>
                                                        </div>
                                                    </Col> */}
                        <Col lg="3">
                          <div className="mt-4">
                            <Button
                              type="submit"
                              className="m-1"
                              color="primary"
                            >
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}

            <Card>
              <CardHeader className="bg-white">
                {/* <CardTitle>Booking Report</CardTitle> */}
              </CardHeader>

              <CardBody>
                <div>
                  <div className="table-responsive">
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              {/* <ReactHTMLTableToExcel
                                                                className=" btn btn-success m-2"
                                                                table="empTable"
                                                                filename="ReportExcel"
                                                                sheet="Sheet"
                                                                buttonText={<i className="fas fa-file-excel"> Excel</i> }
                                                                style={{ color: "white" }}
                                                              
                                                            /> */}
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                              {/* <Button className="m-1" onClick={() => { setaddbooking(!addbooking); }} color="info"><i className="fas fa-plus-circle"></i> New </Button> */}
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="primary"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                          {/* <Col>
                                                        <div style={{ float: "right" }}>
                                                            <Input name="search"
                                                                value={forms.search}
                                                                onChange={handlechange}
                                                             type="text" className="form-control" placeholder="Search.." />
                                                        </div>
                                                    </Col> */}
                        </Row>
                      </div>
                    </div>
                    <Table
                      id="empTable"
                      className="table table-bordered mb-4 mt-5"
                    >
                      <thead>
                        <tr>
                          <th>S NO</th>
                          <th>Date</th>
                          <th>Booking Id</th>
                          <th>Customer Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Car Model</th>
                          <th>Time Slot</th>
                       
  <th>Pickup Date</th>
                            <th>Delivery Date</th>
                            <th>Pickup Time</th>
                            <th>Delivery Time</th>
                          <th>Status</th>
                          <th>Price</th>
                          {/* <th>Total Price</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.date}</td>
                            <td>{data.booking_id}</td>
                            <td>{data.customerName}</td>
                            <td>{data.phone}</td>
                            <td>{data.email}</td>
                            <td>{data.carModelName}</td>
                            <td>
                              {data.timeSlot == "6" || data.timeSlot == "12" ? (
                                <>{data.timeSlot}/hrs</>
                              ) : (
                                ""
                              )}
                              {data.timeSlot == "one" ? (
                                <>{data.daySlot}Days</>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{data.fromDate}</td>
                            <td>{data.toDate}</td> 
                            <td>{data.fromTime}</td>
                              <td>{data.toTime}</td>
                            <td>{data.status}</td>
                            <td>{data.totalprice}</td>
                            {/* <td>{data.totalprice}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      {/* <Stack spacing={2}> */}
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                      {/* </Stack> */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
          <ToastContainer />
        </div>
      </React.Fragment>
    </div>
  )
}

export default Cancel
