import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
    InputGroup,
    CardHeader,
    CardTitle,
    CardSubtitle,
    PaginationItem,
    PaginationLink,
    Modal,
    Pagination
} from "reactstrap"
import "../../src/common.css"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import Select from "react-select";

import { ToastContainer, toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";
import axios from "axios";
import { useHistory } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg";
import car2 from "../assets/images/latest/car2.jpg";
import car3 from "../assets/images/latest/car3.jpg";
import car4 from "../assets/images/latest/car4.jpg";
import car5 from "../assets/images/latest/car5.jpg";
import car6 from "../assets/images/latest/car6.jpg";
import card1 from "../assets/images/latest/card1.jpeg";
import card2 from "../assets/images/latest/card2.jpeg";
import doc1 from "../assets/images/latest/11.png";
import doc2 from "../assets/images/latest/22.png";
import doc3 from "../assets/images/latest/33.png";

const options = [
    { value: 'Saudi', label: 'Saudi Arabia' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Turkey', label: 'Turkey' },
];

const Addusers = () => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [activeTab, setactiveTab] = useState(1)
    const [activeTabVartical, setoggleTabVertical] = useState(1)
    const [showResults, setShowResults] = React.useState(false);
    const [editResults, seteditResults] = React.useState(false);
    const showfield = () => setShowResults(true);
    const hidefield = () => setShowResults(false);

    const [passedSteps, setPassedSteps] = useState([1])
    const [passedStepsVertical, setPassedStepsVertical] = useState([1])

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    function toggleTabVertical(tab) {
        if (activeTabVartical !== tab) {
            var modifiedSteps = [...passedStepsVertical, tab]

            if (tab >= 1 && tab <= 4) {
                setoggleTabVertical(tab)
                setPassedStepsVertical(modifiedSteps)
            }
        }
    }
    const [selectedMulti, setselectedMulti] = useState(null);
    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
    }

    const optionGroup = [
        {
            label: "Colors",
            options: [
                { label: "Beige", value: "Beige" },
                { label: "Black", value: "Black" },
                { label: "Blue", value: "Blue" },
                { label: "Brown", value: "Brown" },
                { label: "Dark Gray", value: "Dark Gray" },
            ]
        },
    ];
    const optionGroup1 = [
        {
            label: "Features",
            options: [
                { label: "3D Surround Camera", value: "Beige" },
                { label: "Memory Front Seats", value: "Black" },
                { label: "Blind Spot Warning", value: "Blue" },
                { label: "Adaptive Cruise Control", value: "Brown" },
                { label: "Digital HUD", value: "Dark Gray" },
                { label: "Temperature Controlled Seats", value: "Dark" },
            ]
        },
    ];

    const [isDisabled, setIsDisabled] = useState(true);
    const handleClick = () => {
        setIsDisabled(!isDisabled)
    };

    const [isDisabled1, setIsDisabled1] = useState(true);
    const handleClick1 = () => {
        setIsDisabled1(!isDisabled1)
    };
    const [isDisabled2, setIsDisabled2] = useState(false);
    const handleClick2 = () => {
        setIsDisabled2(!isDisabled2)
    };
    const [isDisabled3, setIsDisabled3] = useState(true);
    const handleClick3 = () => {
        setIsDisabled3(!isDisabled3)
    };
    const [isDisabled4, setIsDisabled4] = useState(false);
    const handleClick4 = () => {
        setIsDisabled4(!isDisabled4)
    };


    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFiles1, setselectedFiles1] = useState([])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }
    function handleAcceptedFiles1(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles1(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";


    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }

    const [fieldshow, setfieldshow] = useState()
    const [fieldshow1, setfieldshow1] = useState()
    console.log(fieldshow1)
    const [fieldshow2, setfieldshow2] = useState()
    const [fieldshow3, setfieldshow3] = useState()

    const [form, setform] = useState({
        customerName: "", phone: "", email: "", password:
            "", gender:
            "", dateOfBirth: "",
        occupation: "",
        occupationdetails:
            "", address:
            "", residentStatus: ""
        , dlNumber:
            "", countryId:
            "", aadharNumber:
            "", voterIdNumber: "",

        documentType: ""
    })

    const [errorObject, seterrorObject] = useState({"customerName":"","phone":"","email":"","password":"","gender":"","dateOfBirth":'',"occupation":"","residentStatus":""});
    const [er,seter]=useState({"customerName":"","phone":"","email":"","password":"","gender":"","dateOfBirth":'',"occupation":"","residentStatus":""});




    const validateFun = (e) => {
        console.log(form);
       if(form.customerName=='')
       {
        let error = { ...er };
        error["customerName"] = "Please Enter Full Name";
        seterrorObject(error);
  
       }else if(form.phone=='')
       {
        let error = { ...er };
        error["phone"] = "Please Enter 10 Number";
        seterrorObject(error);
       }else if(!/^[0-9]{10}$/i.test(form.phone))
       {
        let error = { ...er };
        error["phone"] = "Please Enter 10 Number";
        seterrorObject(error);
       }
    //    {
    //     let error = { ...er };
    //     error["phone"] = "Please Enter Number";
    //     seterrorObject(error);
    //    }

       else if(form.email=='')
       {
        let error = { ...er };
        error["email"] = "Please enter  valid email Id";
        seterrorObject(error);
       }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email))
       {
        let error = { ...er };
        error["email"] = "Please enter  valid email Id";
        seterrorObject(error);
       }
       
       else if(form.gender=='')
       {
        let error = { ...er };
        error["gender"] = "Please Select Gender";
        seterrorObject(error);
       }else if(form.dateOfBirth=='')
       {
        let error = { ...er };
        error["dateOfBirth"] = "Please Enter DOB";
        seterrorObject(error);
       }else if(form.password=='')
       {
        let error = { ...er };
        error["password"] = "Please Enter Password";
        seterrorObject(error);
       }else if(!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i.test(form.password))
       {
        let error = { ...er };
        error["password"] = "Please Enter (min 6 character)";
        seterrorObject(error);
       }

    //    {
    //     let error = { ...er };
    //     error["password"] = "Please Enter Password";
    //     seterrorObject(error);
    //    }
       else if(form.occupation=='')
       {
        let error = { ...er };
        error["occupation"] = "Please Select Occupation";
        seterrorObject(error);
       }
       else if(form.residentStatus=='')
       {
        let error = { ...er };
        error["residentStatus"] = "Please Select Resident";
        seterrorObject(error);
       }
       
       else{
        let error = { ...er };
         seterrorObject(error);
         toggleTab(activeTab + 1);
       }

    }

    function handleSelectChange(event) {
        setfieldshow(event.target.value);
    }
    function handleSelectChange1(event) {
        setfieldshow1(event.target.value);
    }
    function handleSelectChange2(event) {
        setfieldshow2(event.target.value);
    }
    function handleSelectChange3(event) {
        setfieldshow3(event.target.value);
    }

    const [count, setcount] = useState([]);
    
    const [Files, setFiles] = useState("");
    const [Files1, setFiles1] = useState("");
    const [Files2, setFiles2] = useState("");
    const [Files3, setFiles3] = useState("");
    const [Files4, setFiles4] = useState("");
    const [Files5, setFiles5] = useState("");
    const [Files6, setFiles6] = useState("");
    const [Files7, setFiles7] = useState("");
    const [Files8, setFiles8] = useState("");
    const [Files9, setFiles9] = useState("");
    const [Files10, setFiles10] = useState("");
    const [Files11, setFiles11] = useState("");
    const [Files12, setFiles12] = useState("");
    const [Files13, setFiles13] = useState("");
    const [Files14, setFiles14] = useState("");
    // const [Files3, setFiles3] = useState("");

    const changeHandler = (e) => {
        setFiles(e.target.files);
    };
    const changeHandler1 = (e) => {
        setFiles1(e.target.files);
    };
    const changeHandler2 = (e) => {
        setFiles2(e.target.files);
    };
    const changeHandler3 = (e) => {
        setFiles3(e.target.files);
    };
    const changeHandler4 = (e) => {
        setFiles4(e.target.files);
    };
    const changeHandler5 = (e) => {
        setFiles5(e.target.files);
    };
    const changeHandler6 = (e) => {
        setFiles6(e.target.files);
    };
    const changeHandler7 = (e) => {
        setFiles7(e.target.files);
    };
    const changeHandler8 = (e) => {
        setFiles8(e.target.files);
    };
    const changeHandler9 = (e) => {
        setFiles9(e.target.files);
    };
    const changeHandler10 = (e) => {
        setFiles10(e.target.files);
    };
    const changeHandler11 = (e) => {
        setFiles11(e.target.files);
    };
    const changeHandler12 = (e) => {
        setFiles12(e.target.files);
    };
    const changeHandler13 = (e) => {
        setFiles13(e.target.files);
    };
    const changeHandler14 = (e) => {
        setFiles14(e.target.files);
    };


    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };

    const handleChanges = (e) => {
        let myUser = { ...form };
        myUser.countryId = e.target.value;
        setform(myUser);
    };

    var gets = localStorage.getItem("authUser");
    var data = JSON.parse(gets);
    var datas = data.token;
    console.log(datas)

    const getAllcountries = () => {
        var token = datas;
        axios.post(URL.allcountries, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setcount(res.data.countriesResult)
        })
    }

    useEffect(() => {
        getAllcountries()
    }, [])

    const history = useHistory();

    function handlenavigate() {
        history.push("/users-list");
      }


    const addcutomer = () => {
        var token = datas;
        const dataArray = new FormData();
        dataArray.append("customerName", form.customerName);
        dataArray.append("phone", form.phone);
        dataArray.append("email", form.email);
        dataArray.append("password", form.password);
        dataArray.append("gender", form.gender);
        dataArray.append("dateOfBirth", form.dateOfBirth);
        dataArray.append("occupation", form.occupation);
        dataArray.append("occupationdetails", form.occupationdetails);
        dataArray.append("address", form.address);
        dataArray.append("residentStatus", form.residentStatus);
        dataArray.append("dlNumber", form.dlNumber);
        dataArray.append("countryId", form.countryId);
        dataArray.append("aadharNumber", form.aadharNumber);
        dataArray.append("voterIdNumber", form.voterIdNumber);
        // dataArray.append("countryId", form.countryId);
        dataArray.append("documentType", form.documentType);

        for (let i = 0; i < Files.length; i++) {
            dataArray.append("profileImg", Files[i]);
        }
        for (let i = 0; i < Files1.length; i++) {
            dataArray.append("occupidImg", Files1[i]);
        }
        for (let i = 0; i < Files2.length; i++) {
            dataArray.append("dlFrontImg", Files2[i]);
        }
        for (let i = 0; i < Files3.length; i++) {
            dataArray.append("dlBackImg", Files3[i]);
        }

        for (let i = 0; i < Files4.length; i++) {
            dataArray.append("aadharFrontImg", Files4[i]);
        }
        for (let i = 0; i < Files5.length; i++) {
            dataArray.append("aadharBackImg", Files5[i]);
        }
        for (let i = 0; i < Files6.length; i++) {
            dataArray.append("voterIdFrontImg", Files6[i]);
        }
        for (let i = 0; i < Files7.length; i++) {
            dataArray.append("voterIdBackImg", Files7[i]);
        }
        for (let i = 0; i < Files8.length; i++) {
            dataArray.append("passportFrontImg", Files8[i]);
        }
        for (let i = 0; i < Files9.length; i++) {
            dataArray.append("passportBackImg", Files9[i]);
        }
        for (let i = 0; i < Files10.length; i++) {
            dataArray.append("photoidImg", Files10[i]);
        }
        for (let i = 0; i < Files11.length; i++) {
            dataArray.append("visaImg", Files11[i]);
        }
        for (let i = 0; i < Files12.length; i++) {
            dataArray.append("interPassportImg", Files12[i]);
        }
        for (let i = 0; i < Files13.length; i++) {
            dataArray.append("interDlFrontImg", Files13[i]);
        }
        for (let i = 0; i < Files14.length; i++) {
            dataArray.append("interDlBackImg", Files14[i]);
        }

        axios.post(URL.addcustomer, dataArray, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    console.log(res.data)
                    setform("");
                    setFiles("");
                    clearForm();
                    handlenavigate();
                    getAllcustomer();
                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addcutomer();
        clearForm()
        // handlenavigate()
    };

    
    const clearForm = () => {
        setform({
            customerName: "",
            phone: "",
            email: "",
            password: "",
            gender: "",
            dateOfBirth: "",
            occupation: "",
            occupationdetails: "",
            address: "",
            residentStatus: "",
            dlNumber: "",
            countryId: "",
            documentType: "",
            aadharNumber: "",
            voterIdNumber: "",
        });
        setFiles({
            profileImg:""
        })
    };






    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Add Customer" />
                    <Row>
                        <Form onSubmit={(e) => { handleSubmit(e) }}>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                                        <div className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul>
                                                    <NavItem
                                                        className={classnames({ current: activeTab === 1 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ current: activeTab === 1 })}
                                                            onClick={() => {
                                                                setactiveTab(1)
                                                            }}
                                                            disabled={!(passedSteps || []).includes(1)}
                                                        >
                                                            <span className="number"><i className="fas fa-user"></i></span> Personal Details
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({ current: activeTab === 2 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 2 })}
                                                            onClick={() => {
                                                                setactiveTab(2)
                                                            }}
                                                            disabled={!(passedSteps || []).includes(2)}
                                                        >
                                                            <span className="number"><i className="fas fa-shield-alt"></i></span> Documents

                                                        </NavLink>
                                                    </NavItem>
                                                    {/* <NavItem
                                                    className={classnames({ current: activeTab === 3 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 3 })}
                                                        onClick={() => {
                                                            setactiveTab(3)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(3)}
                                                    >
                                                        <span className="number"><i className="fas fa-images"></i></span> Car Images
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 4 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 4 })}
                                                        onClick={() => {
                                                            setactiveTab(4)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(4)}
                                                    >
                                                        <span className="number"><i className="fas fa-money-check-alt"></i></span> PRICING
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 5 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 5 })}
                                                        onClick={() => {
                                                            setactiveTab(5)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(5)}
                                                    >
                                                        <span className="number"><i className="fas fa-users-cog"></i></span> RENTAL TERMS
                                                    </NavLink>
                                                </NavItem> */}

                                                </ul>
                                            </div>
                                            <div className="content clearfix mt-2">
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId={1}>

                                                        <Row>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Full Name <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"
                                                                        required
                                                                        value={form.customerName}
                                                                        name="customerName"
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                    <small style={{color:"#ff0000"}}>{errorObject.customerName}</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Mobile No <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter number"
                                                                        required
                                                                        value={form.phone}
                                                                        name="phone"
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                   <small style={{color:"#ff0000"}}>{errorObject.phone}</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Email <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Email"
                                                                        required
                                                                        value={form.email}
                                                                        name="email"
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                     <small style={{color:"#ff0000"}}>{errorObject.email}</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Gender <span className="text-danger">*</span>
                                                                    </Label>
                                                        
                                                                    <select value={form.gender}
                                                                        name="gender"
                                                                        onChange={(e) => { handleChange(e) }}
                                                                        className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="M">Male</option>
                                                                        <option value="F">Female</option>
                                                                    </select>
                                                                    <small style={{color:"#ff0000"}}>{errorObject.gender}</small>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Date of Birth <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter DOB"
                                                                        required
                                                                        name="dateOfBirth"
                                                                        value={form.dateOfBirth}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                     <small style={{color:"#ff0000"}}>{errorObject.dateOfBirth}</small>
                                                                </div>

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Image
                                                                        {/* <span className="text-danger">*</span> */}
                                                                    </Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Image"
                                                                        name="profileImg"
                                                                        value={form.profileImg}
                                                                        onChange={changeHandler}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Password <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                    minLength={6}
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Password"
                                                                        required
                                                                        name="password"
                                                                        value={form.password}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                    <small style={{color:"#ff0000"}}>{errorObject.password}</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Occupation <span className="text-danger">*</span>
                                                                    </Label>

                                                                    <select
                                                                        name="occupation"
                                                                        value={form.occupation}
                                                                        // onChange={handleSelectChange1}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                        className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="student">Student</option>
                                                                        <option value="employee">Employee</option>
                                                                        <option value="business">Businessman</option>
                                                                    </select>
                                                                    <small style={{color:"#ff0000"}}>{errorObject.occupation}</small>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            {form.occupation == "employee" || form.occupation == "business" ? (
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label for="basicpill-firstname-input3">
                                                                            Company Name
                                                                            {/* <span className="text-danger">*</span> */}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-firstname-input3"
                                                                            placeholder="Enter Company"
                                                                            name="occupationdetails"
                                                                            value={form.occupationdetails}
                                                                            onChange={(e) => { handleChange(e) }}

                                                                        />
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label for="basicpill-firstname-input3">
                                                                            College Name
                                                                            {/* <span className="text-danger">*</span> */}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-firstname-input3"
                                                                            placeholder="Enter College"
                                                                            name="occupationdetails"
                                                                            value={form.occupationdetails}
                                                                            onChange={(e) => { handleChange(e) }}

                                                                        />
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Occupation Id Card
                                                                        {/* <span className="text-danger">*</span> */}
                                                                    </Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"
                                                                        name="occupidImg"
                                                                        value={form.occupidImg}
                                                                        onChange={changeHandler1}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Address
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input3"
                                                                        placeholder="Enter Address"
                                                                        name="address"
                                                                        value={form.address}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={3}>
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-firstname-input3">
                                                                        Residents Type <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <select name="residentStatus"
                                                                        value={form.residentStatus}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                        required
                                                                        // onChange={handleSelectChange}
                                                                        className="form-select">
                                                                        <option value="">Select</option>
                                                                        <option value="resident">Residents</option>
                                                                        <option value="nonResident">Non-Residents</option>
                                                                    </select>
                                                                    <small style={{color:"#ff0000"}}>{errorObject.residentStatus}</small>
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        {form.residentStatus == "resident" ? (
                                                            <div>
                                                                {/* <Row>
                                                                    <Col>
                                                                    <img src={doc1} style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                    <Col>
                                                                    <img src={doc2}  style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                    <Col>
                                                                    <img src={doc3}  style={{width:"100%", height:"110px"}}/>
                                                                    </Col>
                                                                </Row> */}

                                                                <Row className="mt-3">
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Select Aadhar / vote / Passport<span className="text-danger">*</span>
                                                                            </Label>
                                                                            {/* <Input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    id="basicpill-firstname-input1"
                                                                                    required
                                                                                /> */}
                                                                            <select
                                                                                name="documentType"
                                                                                value={form.documentType}
                                                                                onChange={(e) => { handleChange(e) }}
                                                                                // onChange={handleSelectChange3}
                                                                                className="form-select">
                                                                                <option value="">Select</option>
                                                                                <option value="aadhar">Aadhar</option>
                                                                                <option value="voterId">Vote Id</option>
                                                                                <option value="passport">Passport</option>
                                                                            </select>

                                                                        </div>
                                                                    </Col>

                                                                    {form.documentType == "voterId" ? (
                                                                        <>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">
                                                                                        Vote Id <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="text"
                                                                                        maxLength="10"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        placeholder=" Vote Id"
                                                                                        required
                                                                                        name="voterIdNumber"
                                                                                        value={form.voterIdNumber}
                                                                                        onChange={(e) => { handleChange(e) }}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input3">
                                                                                        Vote ID Front<span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input3"
                                                                                        required
                                                                                        name="voterIdFrontImg"
                                                                                        value={form.voterIdFrontImg}
                                                                                        onChange={changeHandler6}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">
                                                                                        Vote ID Back<span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        required
                                                                                        name="voterIdBackImg"
                                                                                        value={form.voterIdBackImg}
                                                                                        onChange={changeHandler7}
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    {form.documentType == "passport" ? (
                                                                        <>

                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">
                                                                                        Passport Front <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        required
                                                                                        name="passportFrontImg"
                                                                                        value={form.passportFrontImg}
                                                                                        onChange={changeHandler8}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input3">
                                                                                        Passport Back <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input3"
                                                                                        required
                                                                                        name="passportBackImg"
                                                                                        value={form.passportBackImg}
                                                                                        onChange={changeHandler9}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {form.documentType == "aadhar" ? (
                                                                        <>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">
                                                                                        Aadhar No. <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="text"
                                                                                        maxlength="12"
                                                                                        pattern="\d{12}"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        placeholder="Aadhar No."
                                                                                        required
                                                                                        name="aadharNumber"
                                                                                        value={form.aadharNumber}
                                                                                        onChange={(e) => { handleChange(e) }}
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">
                                                                                        Aadhar Front <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        required
                                                                                        name="aadharFrontImg"
                                                                                        value={form.aadharFrontImg}
                                                                                        onChange={changeHandler4}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input3">
                                                                                        Aadhar Back <span className="text-danger">*</span>
                                                                                    </Label>
                                                                                    <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input3"
                                                                                        required
                                                                                        name="aadharBackImg"
                                                                                        value={form.aadharBackImg}
                                                                                        onChange={changeHandler5}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    {/* <Col md={3}>
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input3">
                                                                                    Passport Visa <span className="text-danger">*</span>
                                                                                </Label>
                                                                                <Input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    id="basicpill-firstname-input3"
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </Col> */}
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Driving License Number<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                maxLength="16"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                placeholder="license Number"
                                                                                required
                                                                                name="dlNumber"
                                                                                value={form.dlNumber}
                                                                                onChange={(e) => { handleChange(e) }}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Driving License front<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="dlFrontImg"
                                                                                value={form.dlFrontImg}
                                                                                onChange={changeHandler2}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Driving License back <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="dlBackImg"
                                                                                value={form.dlBackImg}
                                                                                onChange={changeHandler3}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>


                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {form.residentStatus == "nonResident" ? (
                                                            <div>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input1">
                                                                                Countries <span className="text-danger">*</span>
                                                                            </Label>

                                                                            {/* <Select
                                                                                    defaultValue={selectedOption}
                                                                                    onChange={setSelectedOption}
                                                                                    options={options}
                                                                                /> */}

                                                                            <select name="countryId"
                                                                                value={form.countryId}
                                                                                onChange={(e) => { handleChanges(e) }}
                                                                                className="form-select">
                                                                                <option value="">Select</option>
                                                                                {count.map((data, key) => {
                                                                                    return (
                                                                                        <option key={key} value={data._id}>{data.countryName}</option>
                                                                                    )
                                                                                })}
                                                                            </select>


                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Passport Copy <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="interPassportImg"
                                                                                value={form.interPassportImg}
                                                                                onChange={changeHandler12}

                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Id card front <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="photoidImg"
                                                                                value={form.photoidImg}
                                                                                onChange={changeHandler10}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                Visit Visa Copy<span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="visaImg"
                                                                                value={form.visaImg}
                                                                                onChange={changeHandler11}
                                                                            />
                                                                        </div>
                                                                    </Col>


                                                                </Row>
                                                                <Row className="mt-3">
                                                                    <Col md={3}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                International Driving License <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                placeholder="license Number"
                                                                                required
                                                                                name="dlNumber"
                                                                                value={form.dlNumber}
                                                                                onChange={(e) => { handleChange(e) }}

                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                International Driving License Front  <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="interDlFrontImg"
                                                                                value={form.interDlFrontImg}
                                                                                onChange={changeHandler13}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <div className="mb-3">
                                                                            <Label for="basicpill-firstname-input3">
                                                                                International Driving License Back <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                id="basicpill-firstname-input3"
                                                                                required
                                                                                name="interDlBackImg"
                                                                                value={form.interDlBackImg}
                                                                                onChange={changeHandler14}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                            <div className="actions clearfix">
                                                <ul>
                                                    <li
                                                        className={
                                                            activeTab === 1 ? "previous disabled" : "previous"
                                                        }
                                                    >
                                                        <Link
                                                            to="#"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1)
                                                            }}
                                                            style={{ background: "#d05151" }}
                                                        >
                                                            <i className="fas fa-arrow-circle-left"></i>  Previous
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className={activeTab === 2 ? "next logbtn" : "next"}
                                                    >
                                                        <Link
                                                            to="#"
                                                            onClick={(e) => {
                                                                // toggleTab(activeTab + 1)
                                                                validateFun(e)
                                                            }}
                                                        >
                                                            Next <i className="fas fa-arrow-circle-right"></i>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className={
                                                            activeTab < 2 ? "previous logbtn " : "previous"
                                                        }
                                                    >
                                                        <button
                                                            to="#"
                                                            className="btn btn-primary"
                                                            type="submit"
                                                        >
                                                            <i className="fas fa-check-circle"></i> Submit
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Form>
                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Users
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Branch <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Dubai</option>
                                            <option value="AbuDhabi">Abu Dhabi</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Phone No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Phone No"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Department <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Manager</option>
                                            <option value="AbuDhabi">Employee</option>
                                            {/* <option value="AbuDhabi">Driver</option> */}
                                            {/* <option value="AbuDhabi">Customer</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Email <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="emial"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Password <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Password"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div>
                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>

                </Modal>
                <ToastContainer />
            </div>
        </React.Fragment>
    )
}

export default Addusers
