import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Branch from "../pages/Branch";
import Cars from "../pages/Cars";
import Addusers from "../pages/Addusers";
import Addnewone from "../pages/Addnewone";
import Editone from "../pages/Editone";
import Viewuser from "../pages/Viewuser";
import Test from "../pages/Test";
import Brand from "../pages/Brand";
import Model from "../pages/Model";
import Version from "../pages/Version";
import Specification from "../pages/Specification";
import Fatures from "../pages/Fatures";
import Customers from "../pages/Customers";
import Driver from "../pages/drivers/Driver";
import Driverlist from "../pages/drivers/Driverlist";
import Editdriver from "../pages/drivers/Editdriver";
import Viewdrever from "../pages/drivers/Viewdrever";
import Bookings from "../pages/Bookings";
import Coupons from "../pages/Coupons";
import Banners from "../pages/Banners";
import Editcar from "../pages/Editcar";
import Viewcar from "../pages/Viewcar";

import Viewcar1 from "../pages/Viewcar1";
import Viewcar2 from "../pages/Viewcar2";
import Viewcar3 from "../pages/Viewcar3";
import Viewcar4 from "../pages/Viewcar4";

import Viewbooking from "../pages/Viewbooking";
import BookingReport from "pages/BookingReport"

import Wallets from "../pages/Wallets";
import Walletview from "../pages/Walletview";
import Wallethistory from "../pages/Wallethistory";

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Bookings1 from "../pages/Bookings1";
import Bookings2 from "../pages/Bookings2";
import Bookings3 from "../pages/Bookings3";
import Bookings4 from "../pages/Bookings4";

import Accepeted from "../pages/Allreports/Accepeted";
import Cancel from "../pages/Allreports/Cancel";
import Completed from "../pages/Allreports/Completed";
import Payment from "../pages/Allreports/Payment";
import Securitydep from "../pages/Allreports/Securitydep";

import Document from "pages/Documents/Document";
import Invoices from "pages/Documents/Invoice"

import Carcolors from "pages/Carcolors"


import Notification from "pages/Notification"
import Contactdetails from "pages/Contactdetails"
import Termsandcond from "pages/Termsandcond"
import Pravicypolicy from "pages/Pravicypolicy"
import Aboutus from "pages/Aboutus"
import Refund from "pages/Refund"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/branch", component: Branch },
  { path: "/cars", component: Cars },
  { path: "/users-list", component: Addusers },
  { path: "/addusers", component: Addnewone },
  { path: "/edituser", component: Editone },
  { path: "/viewuser", component: Viewuser },
  { path: "/Test", component: Test },
  { path: "/car-brand", component: Brand },
  { path: "/car-model", component: Model },
  { path: "/version", component: Version },
  { path: "/specification", component: Specification },
  { path: "/fatures", component: Fatures },
  { path: "/customers", component: Customers },
  { path: "/driver", component: Driver },
  { path: "/driver-list", component: Driverlist },
  { path: "/edit-driver", component: Editdriver },
  { path: "/view-driver", component: Viewdrever },
  { path: "/bookings", component: Bookings},
  { path: "/bookings1", component: Bookings1},
  { path: "/bookings2", component: Bookings2},
  { path: "/bookings3", component: Bookings3},
  { path: "/bookings4", component: Bookings4},
  { path: "/coupons", component: Coupons },
  { path: "/banners", component: Banners },
  { path: "/editcar", component: Editcar },
  { path: "/car-details", component: Viewcar },

  { path: "/Contactdetails", component: Contactdetails },

  { path: "/booking-details", component: Viewbooking },

  
  { path: "/booking-details1", component: Viewcar1 },
  { path: "/booking-details2", component: Viewcar2 },
  { path: "/booking-details3", component: Viewcar3 },
  { path: "/booking-details4", component: Viewcar4 },


  { path: "/bookingreport", component: BookingReport },
  { path: "/Notification", component: Notification },

  { path: "/wallet", component: Wallets },
  { path: "/wallet-details", component: Walletview },
  { path: "/wallet-history", component: Wallethistory },

  { path: "/accepeted", component: Accepeted },
  { path: "/security-deposit", component: Securitydep },
  { path: "/payment", component: Payment },
  { path: "/completed", component: Completed },
  { path: "/cancel", component: Cancel },

  { path: "/document", component: Document },
  { path: "/LC-invoice", component: Invoices },

  { path: "/Carcolors", component: Carcolors },
  { path: "/termsandconditions", component: Termsandcond },
  { path: "/privacypolicy", component: Pravicypolicy },
  { path: "/aboutus", component: Aboutus },
  { path: "/refundpolicy", component: Refund },


  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
