import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"

import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import car1 from "../assets/images/latest/car1.jpg"
import car2 from "../assets/images/latest/car2.jpg"
import car3 from "../assets/images/latest/car3.jpg"
import car4 from "../assets/images/latest/car4.jpg"
import car5 from "../assets/images/latest/car5.jpg"
import car6 from "../assets/images/latest/car6.jpg"
import card1 from "../assets/images/latest/card1.jpeg"
import card2 from "../assets/images/latest/card2.jpeg"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"

const options = [
  { value: "Luxury", label: "Luxury" },
  { value: "LuxurySUV", label: "Luxury SUV" },
  { value: "SUV", label: "SUV" },
  { value: "Sedan", label: "Sedan" },
  { value: "Hatchback", label: "Hatchback" },
  { value: "Crossover", label: "Crossover" },
]
const options1 = [
  { value: "AlfaRomeo", label: "Alfa Romeo" },
  { value: "Audi", label: "Audi" },
  { value: "Toyota", label: "Toyota" },
  { value: "BMW", label: "BMW" },
  { value: "Bugatti", label: "Bugatti" },
]
const options2 = [
  { value: "Giulietta", label: "Giulietta" },
  { value: "Brera", label: "Brera" },
  { value: "Giulietta", label: "Giulietta" },
  { value: "Spider", label: "Spider" },
  { value: "Stelvio", label: "Stelvio" },
]

const Viewbooking = () => {
  //meta title
  // document.title = "Freedom Cars ";

  const [cars, setcars] = useState([])
  const [spce, setspce] = useState([])
  const [fut, setfut] = useState([])
  const [model1, setmodel1] = useState([])
  const [model, setmodel] = useState([])
  const [form, setform] = useState([])
  const [forms, setforms] = useState([])
  console.log(forms)
  const [form1, setform1] = useState([])
  console.log(form1)
  const [Files, setFiles] = useState("")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const changeHandler = e => {
    setFiles(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const [modal_small2, setmodal_small2] = useState(false)

  function tog_small2() {
    setmodal_small2(!modal_small2)
    removeBodyCss()
  }

  const [modal_small3, setmodal_small3] = useState(false)

  function tog_small3() {
    setmodal_small3(!modal_small3)
    removeBodyCss()
  }

  const [modal_small4, setmodal_small4] = useState(false)

  function tog_small4() {
    setmodal_small4(!modal_small4)
    removeBodyCss()
  }

  const cursid = sessionStorage.getItem("bookid")
  const [formsss, setformss] = useState("")

  const [pay, setpay] = useState([])
  const [pay1, setpay1] = useState([])

  const handleChangesss = e => {
    let myUser = { ...pay }
    myUser[e.target.name] = e.target.value
    setpay(myUser)
    // const zzz = parseInt(formsss) - parseInt(e.target.value)
    // const zzz1 = parseInt(e.target.value) + parseInt(form.totalprice)
    // setformss(zzz)
    // setpay1(zzz1)
  }

  const getonecars = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("_id", cursid)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carbooking/getacceptedbookingbyid",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setform(res.data.bookingResult)
        // setformss(res.data.bookingResult.payments[1])
        setform1(res.data.bookingResult.payments)
        setpay1(res.data.bookingResult.payments[0].totalprice)
      })
  }

  const downloadImage = () => {
    saveAs("https://api.freedomcars.co.in/" + form.softCopy) // Put your image url here.
  }


  const [price, setprice] = useState([])

  const remainpay = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("price", form.price)
    dataArray.append("totalprice", pay.pays)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carbooking/addpaymentbybookingid" +
          "/" +
          cursid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        toast(res.data.message)
        setmodal_small2(false)
        getonecars()
        setpay("")

       
      })
  }

  const formSubmit = e => {
    e.preventDefault()
    remainpay()
  }

  const hidefield = () => setaddbooking(false)

  useEffect(() => {
    getonecars()
  }, [])

  const optionGroup123 = [
    {
      label: "Colors",
      options: [
        { label: "Beige", value: "Beige" },
        { label: "Black", value: "Black" },
        { label: "Blue", value: "Blue" },
        { label: "Brown", value: "Brown" },
        { label: "DarkGray", value: "Dark Gray" },
      ],
    },
  ]

  const optionGroup1 = fut.map(response => ({
    value: response._id,
    label: response.featureName,
  }))
  console.log(optionGroup1)

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    ///setSelectedOptions(current => [...current, details.value]);
    setSelectedOptions(details)
    console.log(details)
  }

  const [selectedMulti1, setselectedMulti1] = useState([])
  console.log(selectedMulti1)
  function handleMulti(data12) {
    setselectedMulti1(data12)
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = cars.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(cars.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  // const getpopup = (data) => {
  //     setforms(data)
  //     tog_small2()
  // }

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    adddeposite()
  }
  const [Files1, setFiles1] = useState("")

  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  const adddeposite = () => {
    var token = datas
    const docid = form._id
    const dataArray = new FormData()
    dataArray.append("securityDeposite", form2.securityDeposite)
    dataArray.append("RegistNumber", form2.RegistNumber)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("depositImg", Files1[i])
    }
    dataArray.append("depositeAmount", form2.depositeAmount)

    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carbooking/addsecuritydep" +
          "/" +
          docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small3(false)
            getonecars()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [form3, setform3] = useState([])

  const handleChange3 = e => {
    let myUser = { ...form3 }
    myUser[e.target.name] = e.target.value
    setform3(myUser)
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    adddeposite1()
  }

  const adddeposite1 = () => {
    var token = datas
    const docid = form._id
    const dataArray = new FormData()
    dataArray.append("returnedDate", form3.returnedDate)
    dataArray.append("securityDepositReturn", form3.securityDepositReturn)
    dataArray.append("description", form3.description)

    axios
      .put(
        "https://api.freedomcars.co.in/v1/admin/carbooking/editsecuritydep" +
          "/" +
          docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small4(false)
            // setret(res.data)
            console.log(res.data)
            getonecars()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  // const [zzz, setzzz] = useState("")

  // const balance = form.price - form.totalprice

  // console.log(balance)

  const handleChanges = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    form.paidamount - balance
  }

  // const totaltime=
  const ftime = form.fromDate
  const totime = form.toDate
  const totaldays = new Date(totime).getTime() - new Date(ftime).getTime()
  const countdays = totaldays / (1000 * 60 * 60 * 24)
  // console.log(countdays)

  
  let sum = form1.reduce(function (prev, current) {
    return prev + +current.totalprice
  }, 0)
  var total = form.price

  var total1 = form.coupon_percentDiscount / 100

  var total2 = total * total1

  var total3 = total - total2

  console.log(total3)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Booking Details" />

          <Row>
            <div>
              <Link to="/bookings2">
                {" "}
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn-info mb-3"
                >
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Button>
              </Link>
              <Link to="/document">
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn- mb-3"
                  color="primary"
                >
                  <i className="fas fa-file-contract"></i>Agreement
                </Button>
              </Link>
              {/* <Link to="/LC-invoice"> */}
               
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn- mb-3"
                  color="danger"
                  onClick={downloadImage}
                >
                  
                  <i className="fas fa-file-invoice"></i> Invoice
                </Button>
              {/* </Link> */}
              {/* <Link to="/LC-invoice">
           
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn- mb-3"
                  color="danger"
                >
            
                  <i className="fas fa-file-invoice"></i> Invoice
                </Button>
              </Link>
              <Link to="/document">
              
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn- mb-3"
                  color="primary"
                >
                  
                  <i className="fas fa-file-contract"></i> Agreement
                </Button>
              </Link> */}
            </div>
            <Form>
              <Col lg="12">
                <Row>
                  <Col md={6}>
                    <Card>
                      <CardHeader className="bg-white">
                        <h5>Customer Details / CarDetails</h5>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Booking Id</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Customer Name</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Phone No</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Email</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Car</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Registration Number</b>
                            </Label>
                            <br />
                            <Label>
                            <b>Pickup Date</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Delivery Date</b>
                            </Label>
                            <br />
                            <Label>
                            <b>Pickup Time</b>
                            </Label>
                            <br />
                            <Label>
                            <b>Delivery Time</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-7">
                            <Label>: {form.booking_id}</Label>
                            <br />
                            <Label>: {form.customerName}</Label>
                            <br />
                            <Label>: {form.phone}</Label>
                            <br />
                            <Label>: {form.email}</Label>
                            <br />
                            <Label>: {form.carModelName}</Label>
                            <br />
                            <Label>: {form.carRegistNumber}</Label>
                            <br />
                            <Label>: {form.fromDate}</Label>
                            <br />

                            <Label>
                              :{" "}
                              {form.toDate == "undefined" ? (
                                ""
                              ) : (
                                <span>{form.toDate}</span>
                              )}
                            </Label>
                            <br />
                            <Label>: {form.fromTime}</Label>
                            <br />
                            <Label>: {form.toTime}</Label>
                            <br />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <CardHeader className="bg-white">
                        <h5>Booking Details</h5>
                      </CardHeader>
                      <CardBody className="mb-3 mb-1">
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Booking Status</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Booking Date</b>{" "}
                            </Label>
                            <br />
                            <Label>
                              <b>Drive Points</b>
                            </Label>
                            <br />
                            <Label>
                              <b>price</b>
                            </Label>
                            <br />
                            {/* <Label><b>GST</b></Label><br />
                                                        <Label><b>Transaction Charge</b></Label><br /> */}
                            <Label>
                              <b>Paid Amount</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Insurance Amount</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Pending Amount</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Booking Time</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Coupon Amount</b>
                            </Label>
                            <br />
                            <Label>
                              <b> Coupon Code</b>
                            </Label>
                          </div>
                          <div className="col col-sm-7">
                            <Label>: {form.status}</Label>
                            <br />
                            <Label>: {form.bookingDate}</Label>
                            <br />
                            <Label>: {form.drivepoints}</Label>
                            <br />
                            {/* <Label>: {form.price}</Label> */}

                            {form.timeSlot == "one" ? (
                              <>
                                <Label>: {form.price} </Label>
                              </>
                            ) : (
                              <>
                                <Label>:{form.price} </Label>
                              </>
                            )}
                            <br />
                            <Label>: {form.totalprice}</Label>
                            <br />
                            <Label>: {form.insurance}</Label>
                            <br />
                            <Label>: {form.balanceAmount}</Label>
                            <br />

                            {form.timeSlot == "one" ? (
                              <>
                                <Label>: {1 * countdays} Days</Label>
                                <br />
                              </>
                            ) : (
                              <>
                                <Label>: {form.timeSlot} Hours</Label>
                                <br />
                              </>
                            )}
                                   <Label>: {form.coupon_amount}</Label>
                            <br />
                            <Label>: {form.coupon_code_type}</Label>
                            <br />

{/* {form.coupon_amount ||
                            form.coupon_percentDiscount == "" ? (
                              <Label>: -</Label>
                            ) : (
                              <Label>
                                {form.coupon_amount == "" ? (
                                  <>: {total3}</>
                                ) : (
                                  <>: {form.coupon_amount} </>
                                )}
                              </Label>
                            )}

                            <br />

                            {form.coupon_amount ||
                            form.coupon_percentDiscount == "" ? (
                              <Label>: -</Label>
                            ) : (
                              <Label>: {form.coupon_percentDiscount}%</Label>
                            )}
                            <br /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {form.status == "cancelled" ? (
                  ""
                ) : (
                  <Col md={12}>
                    <Card>
                      <CardHeader className="bg-white">
                        <h5>Payment Details</h5>
                      </CardHeader>
                      <CardBody>
                        <div style={{ float: "right" }}>
                          <Button
                            onClick={() => {
                              tog_small2()
                            }}
                            className="mr-2"
                            style={{ padding: "6px", margin: "3px" }}
                            color="success"
                            outline
                          >
                            <i className=" "></i> Pay Amount
                          </Button>
                        </div>

                        <Table className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>S No</th>
                              <th>price</th>
                              <th>Transaction Charges</th>
                              <th>Gst</th>
                              <th>Coupon</th>
                              <th>Paid Amount</th>
                              <th>Balance Amount</th>
                              {/* <th style={{ width: "150px" }}>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {form1.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <td>{(pageNumber - 1) * 10 + key + 11}</td>
                                <td>{data.price}</td>
                                <td>{data.transactionCharges}</td>
                                <td>{data.gst}</td>
                                <td>{data.couponCode}</td>
                                <td>{data.totalprice}</td>
                                <td>{data.balanceAmount == "0" || parseFloat(data.balanceAmount) < 0 ?"0":data.balanceAmount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {form.status == "cancelled" ? (
                  ""
                ) : (
                  <Card>
                    <CardBody>
                      <div>
                        <div style={{ float: "right" }}>
                          {form.securityDeposite == "" ? (
                            <Button
                              onClick={() => {
                                tog_small3()
                              }}
                              className="mr-2"
                              style={{ padding: "6px", margin: "3px" }}
                              color="success"
                              outline
                            >
                              <i className=" "></i>Add Security Deposite
                            </Button>
                          ) : (
                            <>
                              {form.status == "completed" ? (
                                <>
                                  {form.securityDepositReturn == 0 ? (
                                    <Button
                                      onClick={() => {
                                        tog_small4()
                                      }}
                                      className="mr-2"
                                      style={{ padding: "6px", margin: "3px" }}
                                      color="success"
                                      outline
                                    >
                                      <i className=" "></i>Return Security
                                      Deposite
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <h5 className="mt-2">Security Deposite Details</h5>
                      {form.securityDepositReturn == 0 ? (
                        <p
                          className="text-danger"
                          style={{ float: "right" }}
                        ></p>
                      ) : (
                        ""
                      )}
                      <Row className="mt-4">
                        <Col md={4}>
                          <div className="row">
                            <div className="col col-sm-7">
                              <Label>
                                <b>Security Deposite</b>
                              </Label>
                              <br />
                            </div>
                            <div className="col col-sm-5">
                              <Label>: {form.securityDeposite}</Label>
                              <br />
                            </div>
                          </div>
                        </Col>
                        {form.securityDeposite == "bike" ? (
                          <>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Registration Number</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>: {form.RegistNumber}</Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Image</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>
                                    {" "}
                                    <img
                                      style={{ width: "100px" }}
                                      src={
                                        "https://api.freedomcars.co.in/" +
                                        form.RegistImage
                                      }
                                    />{" "}
                                  </Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        {form.securityDeposite == "laptop" ? (
                          <>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Modal Name</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>: {form.RegistNumber}</Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Image</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>
                                    {" "}
                                    <img
                                      style={{ width: "100px" }}
                                      src={
                                        "https://api.freedomcars.co.in/" +
                                        form.RegistImage
                                      }
                                    />{" "}
                                  </Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        {form.securityDeposite == "passport" ? (
                          <>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Passport Number</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>: {form.RegistNumber}</Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Image</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>
                                    {" "}
                                    <img
                                      style={{ width: "100px" }}
                                      src={
                                        "https://api.freedomcars.co.in/" +
                                        form.RegistImage
                                      }
                                    />{" "}
                                  </Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        {form.securityDeposite == "cash" ? (
                          <>
                            <Col md={4}>
                              <div className="row">
                                <div className="col col-sm-5">
                                  <Label>
                                    <b>Amount</b>
                                  </Label>
                                  <br />
                                </div>
                                <div className="col col-sm-7">
                                  <Label>: {form.depositeAmount}</Label>
                                  <br />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Form>
          </Row>
          {form.securityDepositReturn == 1 ? (
            <Card>
              <CardBody>
                <h5 className="mt-2">Security Deposite Return Details</h5>
                {/* <p className="text-success" style={{ float: "right" }}>Retrun Successfully</p> */}

                <Row className="mt-4">
                  <Col md={4}>
                    <div className="row">
                      <div className="col col-sm-4">
                        <Label>
                          <b>Date</b>
                        </Label>
                        <br />
                      </div>
                      <div className="col col-sm-7">
                        <Label>:{form.returnedDate}</Label>
                        <br />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="row">
                      <div className="col col-sm-5">
                        <Label>
                          <b>Return Details</b>
                        </Label>
                        <br />
                      </div>
                      <div className="col col-sm-7">
                        <Label>
                          :
                          {form.securityDepositReturn == 0
                            ? "Pending"
                            : "Returned"}
                        </Label>
                        <br />
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="row">
                      <div className="col col-sm-4">
                        <Label>
                          <b>Remarks</b>
                        </Label>
                        <br />
                      </div>
                      <div className="col col-sm-8">
                        <Label>: {form.description}</Label>
                        <br />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <ToastContainer />
        </Container>

        <Modal
          size="sm"
          isOpen={modal_small2}
          toggle={() => {
            tog_small2()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Remaining Amount
            </h5>
            <button
              onClick={() => {
                setmodal_small2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                formSubmit(e)
              }}
            >
              <Label>Booking Id : {form.booking_id}</Label>

              <div className="mb-3">
                <Label for="basicpill-firstname-input2">
                  Total Amount <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input2"
                  placeholder="Total Amount"
                  required
                  disabled
                  name="price"
                  value={form.price}
                  // onChange={e => {
                  //   handleChange(e)
                  // }}
                />
              </div>
              {/* <div className="mb-3">
                <Label for="basicpill-firstname-input2">
                  Paid Amount <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input2"
                  placeholder="Paid Amount"
                  required
                  name="pay1"
                  value={pay1}
                  disabled
                  // onChange={e => {
                  //   handleChanges(e)
                  // }}
                />
              </div> */}

              <div className="mb-3">
                <Label for="basicpill-firstname-input2">
                  Paid Amount <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input2"
                  placeholder="Paid Amount"
                  required
                  name="pays"
                  value={pay.pays}
                  onChange={e => {
                    handleChangesss(e)
                  }}
                />
              </div>

              {/* <div className="mb-3">
                <Label for="basicpill-firstname-input2">
                  Balance Amount <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input2"
                  placeholder="Balance Amount"
                  required
                  name="formsss"
                  disabled
                  value={formsss}

                  // onChange={e => {
                  //   handleChange(e)
                  // }}
                />
              </div> */}

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small2(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>


        <Modal
          size="md"
          isOpen={modal_small3}
          toggle={() => {
            tog_small3()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Security Deposite Details
            </h5>
            <button
              onClick={() => {
                setmodal_small3(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-declaration-input10">
                  Select Type <span className="text-danger">*</span>
                </Label>
                <select
                  name="securityDeposite"
                  value={form2.securityDeposite}
                  onChange={e => {
                    handleChange2(e)
                  }} required
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="bike">Bike</option>
                  <option value="laptop">Laptop</option>
                  <option value="passport">Passport</option>
                  <option value="cash">Cash</option>
                </select>
              </div>

              {form2.securityDeposite == "bike" ? (
                <>
                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Bike Rgs No. <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      placeholder="Rgs Number"
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="RegistNumber"
                      required
                      valid={form2.RegistNumber}
                      onChange={e => {
                        handleChange2(e)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Image <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      required
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="depositImg"
                      onChange={changeHandler1}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {form2.securityDeposite == "laptop" ? (
                <>
                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Modal Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      required
                      placeholder="Modal Name"
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="RegistNumber"
                      valid={form2.RegistNumber}
                      onChange={e => {
                        handleChange2(e)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Image <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      required
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="depositImg"
                      onChange={changeHandler1}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              {form2.securityDeposite == "passport" ? (
                <>
                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Passport No. <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      required
                      placeholder="Passport No"
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="RegistNumber"
                      valid={form2.RegistNumber}
                      onChange={e => {
                        handleChange2(e)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="basicpill-declaration-input10">
                      Image <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      required
                      className="form-control"
                      id="basicpill-Declaration-input10"
                      name="depositImg"
                      onChange={changeHandler1}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              {form2.securityDeposite == "cash" ? (
                <div className="mb-3">
                  <Label for="basicpill-declaration-input10">
                    Amount <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    required
                    placeholder="Amount"
                    className="form-control"
                    id="basicpill-Declaration-input10"
                    name="depositeAmount"
                    valid={form2.depositeAmount}
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="mb-3" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small3(false)
                  }}
                  className="m-1  mb-1"
                  color="danger"
                >
                  <i className="fas fa-times-circle"></i> Cancel
                </Button>
                <Button type="submit" className="m-1 mb-1" color="primary">
                  <i className="fas fa-check-circle"></i> Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>

      <Modal
        size="md"
        isOpen={modal_small4}
        toggle={() => {
          tog_small4()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Security Deposite Return
          </h5>
          <button
            onClick={() => {
              setmodal_small4(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          onSubmit={e => {
            handleSubmit2(e)
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label for="basicpill-declaration-input10">
                Date <span className="text-danger">*</span>
              </Label>
              <Input
                type="date"
                required
                placeholder="Rgs Number"
                className="form-control"
                id="basicpill-Declaration-input10"
                name="returnedDate"
                value={form3.returnedDate}
                onChange={e => {
                  handleChange3(e)
                }}
              />
            </div>
            <div className="mb-3">
              <Label for="basicpill-firstname-input2">
                Return<span className="text-danger">*</span>
              </Label>
              <select
                onChange={e => {
                  handleChange3(e)
                }}
                required
                name="securityDepositReturn"
                className="form-select"
              >
                <option value="">Select</option>
                <option value="0">Pending</option>
                <option value="1">Returned</option>
              </select>
            </div>

            <div className="mb-3">
              <Label for="basicpill-firstname-input2">
                Remarks <span className="text-danger">*</span>
              </Label>

              <textarea
                value={form3.description}
                required
                className="form-control"
                placeholder="Remarks"
                onChange={e => {
                  handleChange3(e)
                }}
                name="description"
              />
            </div>
            <div className="mb-3" style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small4(false)
                }}
                className="m-1  mb-1"
                color="danger"
              >
                <i className="fas fa-times-circle"></i> Cancel
              </Button>
              <Button type="submit" className="m-1 mb-1" color="primary">
                <i className="fas fa-check-circle"></i> Submit
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default Viewbooking
