import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
import "../../src/common.css"
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Addusers = () => {

    const [customer, setcustomer] = useState([])

    var gets = localStorage.getItem("authUser");
    var data = JSON.parse(gets);
    var datas = data.token;
    console.log(datas)

    const getAllcustomer = () => {
        var token = datas;
        axios.post(URL.allcustomer, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setcustomer(res.data.custsResult)
        })
    }

    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = customer.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(customer.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        getAllcustomer();
        // const items1 = JSON.parse(localStorage.getItem('authUser'));
        // setItems(items1);
        // setuserinfo(items1.user);
    }, []);

    const history = useHistory();

    function handleClick() {
        history.push("/edituser");
    }

    function handleClick12() {
        history.push("/viewuser");
    }

    const getdata = (data) => {
        sessionStorage.setItem("dataid", data._id);
        // history.push("/edituser")
        // <Redirect to='/edituser' />
        handleClick()
    }

    const getdata12 = (data) => {
        sessionStorage.setItem("dataid", data._id);
        // history.push("/edituser")
        // <Redirect to='/edituser' />
        handleClick12()
    }


    const disablecust = (data) => {
        var token = datas;
        var remid = data._id
        axios.put(URL.disablecustomer + "/" + remid, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            (res) => {
                if (res.status === 200) {
                    toast(res.data.message);
                    getAllcustomer();

                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        );
    }

    const manageDelete = (data) => {
        const confirmBox = window.confirm("Do you really want to Block ?");
        if (confirmBox === true) {
            disablecust(data);
        }
    };

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";


    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }


    const [forms, setforms] = useState([]);
    console.log(forms)

    const handlechange = (e) => {
        let myUser = { ...forms };
        myUser[e.target.name] = e.target.value;
        setforms(myUser);
        console.log(myUser)
        var token = datas;
        axios
            .post(
                `https://api.freedomcars.co.in/v1/admin/customer/getallcustomers?searchQuery=${e.target.value}`,
                {},

                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((res) => {
                setcustomer(res.data.custsResult)
            });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Customers" />

                    <Row>

                        <Col md={12}>
                            <Card>
                                <CardHeader className="bg-white">
                                    {/* <CardTitle>Users List</CardTitle> */}
                                </CardHeader>
                                <div className="container">
                                    <Input

                                        type="text"
                                        name="search"
                                        value={forms.search}
                                        onChange={handlechange}

                                        style={{ width: "20%", float: "right" }} className="form-control" placeholder="Search.." />
                                </div>
                                <CardBody >

                                    <div>
                                        <div className="table-responsive mb-4">

                                            <Table className="table table-bordered mb-3">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th>S No</th>
                                                        <th>Customer</th>
                                                        <th>Email-Phone </th>
                                                        <th>Address</th>
                                                        <th>Joined At</th>
                                                        <th>Balance</th>
                                                        <th>Verification Status</th>
                                                        {/* <th style={{ width: "200px" }}>Address</th> */}
                                                        <th style={{ width: "130px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((data, key) => (
                                                        <tr key={key} className="text-center">
                                                             <th scope="row">{(pageNumber - 1) * 5 + key + 6}</th>
                                                            <td>{data.customerName}</td>
                                                            <td>
                                                                <small><b>{data.email}</b></small><br />
                                                                <small>{data.phone}</small>
                                                            </td>
                                                            <td>{data.address}</td>
                                                            <td>{data.logDateCreated.slice(0, 10)}</td>
                                                            <td>{data.wallet}</td>
                                                            <td ><span style={{ borderRadius: "5px" }} >
                                                                {data.status == true ? (
                                                                    <a style={{ borderRadius: "5px" }} className="bg-success text-white p-1">Verified
                                                                    </a>
                                                                ) : (
                                                                    <a style={{ borderRadius: "5px" }} className="bg-danger text-white p-1">Pending</a>
                                                                )}
                                                            </span></td>

                                                            <td>
                                                            <Button onClick={() => { getdata12(data) }} style={{ padding: "6px", margin: "3px" }} color="warning" outline>
                                                                    <i className="fas fa-eye text-dark"></i>
                                                                </Button>
                                                                {/* <Link to="/edituser">  */}
                                                                <Button
                                                                    onClick={() => {
                                                                        getdata(data);
                                                                    }}
                                                                    className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                    <i className="bx bx-edit "></i>
                                                                </Button>
                                                                {/* </Link> */}
                                                                {/* <Link to="/viewuser"> */}
                                                               
                                                                {/* </Link> */}
                                                                {/* <Button onClick={() => { manageDelete(data) }} style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                    <i className="fas fa-ban text-primary"></i>
                                                                </Button> */}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="mt-3" style={{ float: "right" }}>
                                            {/* <Stack spacing={2}> */}
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                total={lists.length}
                                            />
                                            {/* </Stack> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                            Edit Users
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Branch <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Dubai</option>
                                            <option value="AbuDhabi">Abu Dhabi</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Phone No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Phone No"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Department <span className="text-danger">*</span>
                                        </Label>
                                        {/* <Input
                                                type="emial"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter Email"
                                                required
                                            /> */}
                                        <select className="form-select" required>
                                            <option value="">Select</option>
                                            <option value="Dubai">Manager</option>
                                            <option value="AbuDhabi">Employee</option>
                                            {/* <option value="AbuDhabi">Driver</option> */}
                                            {/* <option value="AbuDhabi">Customer</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Email <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="emial"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Password <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            id="basicpill-firstname-input3"
                                            placeholder="Enter Password"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div>
                            <div style={{ float: "right" }}>
                                <Button onClick={() => {
                                    setmodal_small(false);
                                }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="button">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
                <ToastContainer />
            </div>
        </React.Fragment>
    )
}

export default Addusers
