const FC_Url = "https://api.freedomcars.co.in/";
export const URL={
    allbanners:FC_Url+"v1/admin/carBanner/getallcarbanners",
    addbanners:FC_Url+"v1/admin/carBanner/addcarbanner",
    editbanners:FC_Url+"v1/admin/carBanner/editbanner",
    removebanners:FC_Url+"v1/admin/carBanner/removebanner",
    adminprofile:FC_Url+"v1/admin/admingetprofile",
    editprofile:FC_Url+"v1/admin/adminupdateprofile",
    changepass:FC_Url+"v1/admin/adminchangepass",

    allcustomer:FC_Url+"v1/admin/customer/getallcustomers",
    addcustomer:FC_Url+"v1/admin/customer/customeradminregister",
    editcustomer:FC_Url+"v1/admin/customer/editcustomerbyadmin",
    viewcustomer:FC_Url+"v1/admin/customer/getdetailsbyid",
    disablecustomer:FC_Url+"v1/admin/customer/disablecustomer",
    activecustomer:FC_Url+"v1/admin/customer/getallactivecustomers",

    allcountries:FC_Url+"v1/admin/country/getallcountries",


    allcarbrands:FC_Url+"v1/admin/brand/getallcarbrands",
    activecarbrands:FC_Url+"v1/admin/brand/getallactivecarbrands",
    addcarbrands:FC_Url+"v1/admin/brand/addcarbrand",
    editcarbrands:FC_Url+"v1/admin/brand/editcarbrand",
    
    allcarmodels:FC_Url+"v1/admin/carmodel/getallcarmodels",
    allcaractivemodels:FC_Url+"v1/admin/carmodel/getallactivecarmodels",
    addcarmodels:FC_Url+"v1/admin/carmodel/addcarmodel",
    editcarmodels:FC_Url+"v1/admin/carmodel/editcarmodel",
    removecarmodels:FC_Url+"v1/admin/carmodel/disablecarmodel",

    allcarversions:FC_Url+"v1/admin/carversion/getallversions",
    addcarversions:FC_Url+"v1/admin/carversion/addcarversion",
    editcarversions:FC_Url+"v1/admin/carversion/editversdion",
    removecarversions:FC_Url+"v1/admin/carversion/disableversion",

    allcarfeature:FC_Url+"v1/admin/carfeature/getallCarfeatures",
    activefeature:FC_Url+"v1/admin/carfeature/getallactivefeatures",
    addcarfeature:FC_Url+"v1/admin/carfeature/new",
    editcarfeature:FC_Url+"v1/admin/carfeature/edit",
    removecarfeature:FC_Url+"v1/admin/carfeature/delete",

    allcarspecs:FC_Url+"v1/admin/carspecs/getallcarspecifications",
    activecarspecs:FC_Url+"v1/admin/carspecs/getallactivecarspecifications",
    addspecification:FC_Url+"v1/admin/carspecs/addcarspecification",
    editcarspec:FC_Url+"v1/admin/carspecs/editcarspec",
    removecarspec:FC_Url+"v1/admin/carspecs/disablecarspec",


    allcars:FC_Url+"v1/admin/car/getallcarmodel",
    addcars:FC_Url+"v1/admin/car/addcarmodel",
    getonecars:FC_Url+"v1/admin/car/getcarmodel",
    editcars:FC_Url+"v1/admin/car/editcar",
    disablecars:FC_Url+"v1/admin/car/disablecar",


    allcoupons:FC_Url+"v1/admin/coupons/all",
    addcoupons:FC_Url+"v1/admin/coupons/new",
    editcoupons:FC_Url+"v1/admin/coupons/edit",
    deletetcoupons:FC_Url+"v1/admin/coupons/delete",



    allbookings:FC_Url+"v1/admin/carbooking/getallbookings",
    bookingsrc:FC_Url+"v1/admin/carbooking/getallbookings?searchQuery=",
    addbookings:FC_Url+"v1/admin/carbooking/addbooking",
    disablebookings:FC_Url+"v1/admin/carbooking/disablebooking",
    getonebook:FC_Url+"v1/admin/carbooking/getbookingbyid",

    
    getcustempil:FC_Url+"v1/admin/customer/getcustbyemailorphone",
    allcarbyids:FC_Url+"v1/admin/car/getallcarsbymodelid",
    getprices:FC_Url+"v1/admin/carbooking/getprices",
    couponsbyid:FC_Url+"v1/admin/coupons/couponbycode",


    popularcar:FC_Url+"v1/admin/car/updatecartopopular",
    featurecar:FC_Url+"v1/admin/car/updatecartofeatured",
    drivepoin:FC_Url+"v1/admin/carbooking/editdrivepoint",
    bookingsts:FC_Url+"v1/admin/carbooking/editbookingstatus",


    dashdata:FC_Url+"v1/admin/getadmindashitems",
    addpayment:FC_Url+"v1/admin/carbooking/addpaymentbybookingid",
    addpayment:FC_Url+"v1/admin/carbooking/editdeposit",
   
    Bookingreports:FC_Url+"v1/admin/carbooking/carbookingreport",
    acceptedreports:FC_Url+"v1/admin/carbooking/carbookingacceptreport",
    cancelledreports:FC_Url+"v1/admin/carbooking/carbookingcancelreport",
    completedreports:FC_Url+"v1/admin/carbooking/carbookingcompletereport",
    paymentreports:FC_Url+"v1/admin/carbooking/paymentsrepost",
    securityreports:FC_Url+"v1/admin/carbooking/securitydepositrepost",
   
    removebrands:FC_Url+'v1/admin/brand/disablecarbrand',


    aggriment:FC_Url+'v1/admin/carbooking/addrideagreement',
    viewaggriment:FC_Url+'v1/admin/carbooking/getagreement',
    addinvoice:FC_Url+'v1/admin/carbooking/addinvoice',
    viewinvoice:FC_Url+'v1/admin/carbooking/getinvoice',



   
}