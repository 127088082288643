import React, { useState } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"

const Branch = () => {

    //meta title
    // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

    const [modal_small, setmodal_small] = useState(false);

    function tog_small() {
        setmodal_small(!modal_small);
        removeBodyCss();
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Freedom Cars" breadcrumbItem="Branch" />

                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Add Branches</CardTitle>
                                </CardHeader>
                                <CardBody >
                                    <Form>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Name <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input1"
                                                placeholder="Enter  Branch Name"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input3">
                                                City <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input3"
                                                placeholder="Enter City"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input2">
                                                Address <span className="text-danger">*</span>
                                            </Label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="basicpill-firstname-input2"
                                                placeholder="Enter  Address"
                                                required
                                            />
                                        </div>
                                        <div style={{ float: "right" }}>
                                            <Button color="primary" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>

                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Branch List</CardTitle>
                                </CardHeader>

                                <CardBody >

                                    <div>
                                        <div className="table-responsive">
                                            <div style={{ float: "right" }}>
                                                <Input type="search" className="form-control" placeholder="Search.." />
                                            </div>
                                            <Table className="table table-bordered mb-4 mt-5">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Branch</th>
                                                        <th>City</th>
                                                        <th>Address</th>
                                                        <th style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Dubai</td>
                                                        <td>Dubai</td>
                                                        <td>100 42 C St, Al Mizhar - Al Mizhar 1 - Dubai</td>
                                                        <td>
                                                            <Button onClick={() => {
                                                                tog_small();
                                                            }} className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                <i className="bx bx-edit "></i>
                                                            </Button>
                                                            <Button style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                <i className="bx bx-trash"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>Abu Dhabi</td>
                                                        <td>Abu Dhabi</td>
                                                        <td>100 42 C St, Al Mizhar - Al Mizhar 1 - Abu Dhabi Dubai</td>
                                                        <td>
                                                            <Button onClick={() => {
                                                                tog_small();
                                                            }} className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                <i className="bx bx-edit "></i>
                                                            </Button>
                                                            <Button style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                <i className="bx bx-trash"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div style={{ float: "right" }}>
                                                <Pagination
                                                    size="sm"
                                                    aria-label="Page navigation example"
                                                >
                                                    <PaginationItem disabled>
                                                        <PaginationLink href="#" tabIndex="-1">
                                                            Previous
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">1</PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">2</PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">3</PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">Next</PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>


                <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small();
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="mySmallModalLabel"
                        >
                           Edit Branches
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter  Branch Name"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                    City <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input3"
                                    placeholder="Enter City"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                    Address <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter  Address"
                                    required
                                />
                            </div>
                            <div style={{ float: "right" }}>
                                <Button      onClick={() => {
                                setmodal_small(false);
                            }} color="danger" type="button">
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>

                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Branch
