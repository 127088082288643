import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { number } from "prop-types"
import { useHistory } from "react-router-dom"

const options = [
  { value: "Luxury", label: "Luxury" },
  { value: "LuxurySUV", label: "Luxury SUV" },
  { value: "SUV", label: "SUV" },
  { value: "Sedan", label: "Sedan" },
  { value: "Hatchback", label: "Hatchback" },
  { value: "Crossover", label: "Crossover" },
]
const options1 = [
  { value: "AlfaRomeo", label: "Alfa Romeo" },
  { value: "Audi", label: "Audi" },
  { value: "Toyota", label: "Toyota" },
]
const options2 = [
  { value: "Giulietta", label: "Giulietta" },
  { value: "Brera", label: "Brera" },
  { value: "Giulietta", label: "Giulietta" },
  { value: "Spider", label: "Spider" },
  { value: "Stelvio", label: "Stelvio" },
]

const Booking = () => {
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedOption1, setSelectedOption1] = useState("")
  const [selectedOption2, setSelectedOption2] = useState("")
  const [selectedOption3, setSelectedOption3] = useState("")
  const [selectedOption12, setSelectedOption12] = useState("")

  const history = useHistory()

  //meta title
  // document.title = "Stater Page | Skote - React Admin & Dashboard Template";

  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [modal_small2, setmodal_small2] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }
  function tog_small1() {
    setmodal_small1(!modal_small1)
    removeBodyCss()
  }
  function tog_small2() {
    setmodal_small2(!modal_small2)
    removeBodyCss()
  }

  const [addbooking, setaddbooking] = useState(false)
  const [editbooking, seteditbooking] = useState(false)
  const [filter, setfilter] = useState(false)

  const hidefield = () => setaddbooking(false)
  const hideeditfield = () => seteditbooking(false)
  const hidefilter = () => setfilter(false)

  const [Files, setFiles] = useState("")

  const changeHandler = e => {
    setFiles(e.target.files)
  }

  const [fieldshow, setfieldshow] = useState()
  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }
  const [fieldshow1, setfieldshow1] = useState()
  function handleSelectChange1(event) {
    setfieldshow1(event.target.value)
  }

  function handleSelect123(details) {
    setSelectedOption3(details)
    console.log(details.value)
    activecustomer(details.value)
    // let myUser = { ...form };
    // myUser["customerName"] = details.value;
    // setform(myUser);
  }

  function handleSelect1234(details) {
    setSelectedOption2(details)
    console.log(details.value)
    carbyid(details.value)
  }

  function handleSelect012(details) {
    setSelectedOption12(details)
    console.log(details.value)

    let myUser = { ...form }
    myUser["carId"] = details.value
    setform(myUser)
    //   getcarprice()
  }

  const [booking, setbooking] = useState([])
  const [form, setform] = useState([])
  console.log(form)
  const [model, setmodel] = useState([])
  const [cust, setcust] = useState([])
  const [cust1, setcust1] = useState([])
  const [search, setsearch] = useState([])
  const [carids, setcarids] = useState([])
  const [Kms, setKms] = useState([])
  const [coupon1, setcoupon1] = useState([])
  const [filters, setfilters] = useState([])
  console.log(coupon1)

  const [coup, setcoup] = useState([])
  // console.log(coup.amount)
  const [coup1, setcoup1] = useState([])
  const [drive, setdrive] = useState([])
  console.log(drive)
  const [book, setbook] = useState([])
  console.log(model)

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    // const couponval = e.target.name + coup.amount;
    // console.log(couponval)
    // setcoupon1(couponval)
  }

  // const fdates = form.fromDate
  // console.log(fdates)
  // const tdates = form.toDate
  // console.log(tdates)

  const handleChangedate = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    // setform(myUser);

    const fdate = new Date(form.fromDate).getTime()
    console.log(fdate)

    const totaldays = new Date(e.target.value).getTime() - fdate
    const countdays = totaldays / (1000 * 60 * 60 * 24)
    console.log(countdays)

    const user1 = countdays * form.price

    var res = user1.split("/")

    user1["price"] = res
    user1["totalprice"] = res

    setform(user1)
  }

  const [forms, setforms] = useState([])
  const [remam, setremam] = useState([])
  console.log(forms)
  console.log(remam)

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    console.log(myUser)
    var token = datas
    axios
      .post(
        `https://api.freedomcars.co.in/v1/admin/carbooking/getallbookings?searchQuery=${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbooking(res.data.bookingSearchResult)
      })
  }

  const handlechangeprice = e => {
    var res = e.target.value.split("/")
    console.log(res)
    let myUser = { ...form }
    myUser[e.target.name] = res[0]

    myUser["price"] = res[1]
    myUser["totalprice"] = res[1]

    setform(myUser)

    console.log(myUser)
  }

  const handleChange0 = e => {
    let myUser = { ...Kms }
    myUser[e.target.name] = e.target.value
    setKms(myUser)
  }

  const handleChangedrive = e => {
    let myUser = { ...drive }
    myUser[e.target.name] = e.target.value
    setdrive(myUser)
  }

  const handleChangebs = e => {
    let myUser = { ...book }
    myUser[e.target.name] = e.target.value
    setbook(myUser)
  }

  const handleChangebook = e => {
    let myUser = { ...remam }
    myUser[e.target.name] = e.target.value
    setremam(myUser)
  }

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const handleChanges1 = e => {
    let myUser = { ...cust1 }
    myUser[e.target.name] = e.target.value
    setcust1(myUser)
  }

  const handleChange12 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    //handleSelect012(e);
    getcarprice(e.target.value)
  }

  const searchchange = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    bookinsearch(e)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const getAllbookings = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("fromDate", "")
    dataArray.append("toDate", "")
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carbooking/getallpendingbookings",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setbooking(res.data.pendingBookingResult)
      })
  }

  const getAllbookings123 = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("fromDate", filters.fromDate)
    dataArray.append("toDate", filters.toDate)
    dataArray.append("status", filters.status)
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/carbooking/getallpendingbookings",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        // getAllbookings()
        hidefilter()
        setfilters("")
        setbooking(res.data.pendingBookingResult)
      })
  }

  const getfilter = e => {
    e.preventDefault()
    getAllbookings123()
    // clearForm()
  }

  const bookinsearch = () => {
    var token = datas
    axios
      .post(
        URL.bookingsrc,
        $(e.target.value),
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setbooking(res.data.bookingSearchResult)
      })
  }

  const activecust = () => {
    var token = datas
    axios
      .post(
        URL.activecustomer,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setcust(res.data.custActiveResult)
      })
  }

  const getAllmodal = () => {
    var token = datas
    axios
      .post(
        URL.allcaractivemodels,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setmodel(res.data.modelResult)
      })
  }

  const activecustomer = data => {
    var token = datas
    const dataArray = new FormData()
    // const params = {
    //     _id: data
    // }
    dataArray.append("_id", data)
    axios
      .post(URL.getcustempil, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            // toast(res.data.message);
            // getAllbookings();
            console.log(res.data.custUser)
            setcust1(res.data.custUser)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const carbyid = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carModelId", data)
    axios
      .post(URL.allcarbyids, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            // toast(res.data.message);
            // getAllbookings();
            console.log(res.data)
            setcarids(res.data.cars)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getcarprice = val => {
    var token = datas
    console.log(form)
    const dataArray = new FormData()

    let objData = { carId: form.carId, timeSlot: val }
    // dataArray.append("carId", data);
    // dataArray.append("timeSlot", form.timeSlot);
    axios
      .post(URL.getprices, objData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            // toast(res.data.message);
            // getAllbookings();
            console.log(res.data)
            setKms(res.data.data)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            // toast(error.response.data.message);
          }
        }
      )
  }

  const getcoupons = () => {
    var token = datas
    const dataArray = new FormData()
    // let objData = { "carId": form.carId, "timeSlot": val }
    // dataArray.append("carId", data);
    dataArray.append("coupon_code", form.coupon_code)
    axios
      .post(URL.couponsbyid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            setcoup(res.data.coupon)
            setcoup1(res.data)
            if (res.data.coupon.coupon_code_type == "price") {
              var total = form.totalprice - res.data.coupon.amount
            } else {
              var total = (form.totalprice * res.data.coupon.amount) / 100
            }
            form.totalprice = total
            //console.log();
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            // toast(error.response.data.message);
          }
        }
      )
  }

  const couponsub = e => {
    e.preventDefault()
    getcoupons()
  }

  useEffect(() => {
    getAllbookings()
    getAllmodal()
    activecust()
  }, [])

  const optionGroup1 = model.map(response => ({
    value: response._id,
    label: response.model_name,
  }))
  console.log(optionGroup1)

  const allcarids = carids.map(response => ({
    value: response._id,
    label: response.carModelName,
  }))
  console.log(optionGroup1)

  const actcut = cust.map(response => ({
    value: response._id,
    label: response.customerName,
  }))
  console.log(actcut)

  const addbookings = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("carId", form.carId)
    dataArray.append("customerName", form.customerName)
    dataArray.append("phone", cust1.phone)
    dataArray.append("email", cust1.email)
    dataArray.append("fromDate", form.fromDate)
    dataArray.append("toDate", form.toDate)
    dataArray.append("timeSlot", form.timeSlot)
    dataArray.append("price", form.price)
    dataArray.append("totalprice", form.totalprice)

    dataArray.append("securityDeposite", form.securityDeposite)
    dataArray.append("depositeAmount", form.depositeAmount)
    // dataArray.append("paymentStatus", form.paymentStatus);
    // dataArray.append("gst", form.gst);
    dataArray.append("couponCode", form.couponCode)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("depositImg", Files[i])
    }
    axios
      .post(URL.addbookings, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbookings()
            hidefield()
            setform("")
            setSelectedOption3("")
            setcust1("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    addbookings()
    // setform("")
    // clearForm()
  }

  const adddrive = () => {
    var token = datas
    const driveid = drive._id
    const dataArray = new FormData()
    // let objData = { "carId": form.carId, "timeSlot": val }
    // dataArray.append("carId", data);
    dataArray.append("drivepoints", drive.drivepoints)
    axios
      .put(URL.drivepoin + "/" + driveid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbookings()
            console.log(res.data)
            setmodal_small1(false)
            // setcoup(res.data.coupon)
            // setcoup1(res.data)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const submitdriv = e => {
    e.preventDefault()
    adddrive()
  }

  const getdocument = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("id", book._id)
    axios
      .post(URL.aggriment, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
    
      })
  }


  const changstatus = () => {
    var token = datas
    const driveid = book._id
    const dataArray = new FormData()
    // let objData = { "carId": form.carId, "timeSlot": val }
    // dataArray.append("carId", data);
    dataArray.append("status", book.status)
    dataArray.append("reason", book.reason)
    dataArray.append("carReading", book.carReading)

    axios
      .put(URL.bookingsts + "/" + driveid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getdocument()
            getAllbookings()
            console.log(res.data)
            setmodal_small(false)
            // setcoup(res.data.coupon)
            // setcoup1(res.data)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const changrsdntstatus = () => {
    var token = datas
    const driveid = remam._id
    const dataArray = new FormData()
    // let objData = { "carId": form.carId, "timeSlot": val }
    // dataArray.append("carId", data);
    dataArray.append("securityDepositReturn", remam.securityDepositReturn)
    // dataArray.append("reason", book.reason);
    axios
      .patch(URL.addpayment + "/" + driveid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbookings()
            console.log(res.data)
            setmodal_small2(false)
            // setcoup(res.data.coupon)
            // setcoup1(res.data)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }
  const subrsdntchng = e => {
    e.preventDefault()
    changrsdntstatus()
  }

  const getpopup = data => {
    setdrive(data)
    tog_small1()
  }
  const getpopup1 = data => {
    setbook(data)
    tog_small()
  }

  const getpopup2 = data => {
    setremam(data)
    tog_small2()
  }

  function handleClick123() {
    history.push("/booking-details1")
  }

  const viewdata = data => {
    sessionStorage.setItem("bookid", data._id)
    handleClick123()
  }

  const deletebooking = data => {
    var token = datas
    var remid = data._id
    axios
      .put(
        "http://103.186.185.77:5021/api/v1/admin/carbooking/disablebooking" + "/" + remid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbookings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Remove ?")
    if (confirmBox === true) {
      deletebooking(data)
    }
  }
  

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = booking.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(booking.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Bookings" />

          <Row>
            <Col md={12}>
              {addbooking ? (
                <>
                  <Card>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <h5 className="mb-4"> Add New Booking</h5>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Customer Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                                placeholder="Customer Name"
                                                                required
                                                            /> */}

                              <Select
                                required
                                defaultValue={selectedOption3}
                                onChange={handleSelect123}
                                options={actcut}
                                // name="customerName"
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Phone No. <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                placeholder="number"
                                required
                                value={cust1.phone}
                                name="phone"
                                onChange={e => {
                                  handleChanges1(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                placeholder="Enter Email"
                                required
                                value={cust1.email}
                                name="email"
                                onChange={e => {
                                  handleChanges1(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Car Type <span className="text-danger">*</span>
                              </Label>

                              {/* <Select
                                                                required
                                                                defaultValue={selectedOption}
                                                                onChange={setSelectedOption}
                                                                options={options}
                                                            /> */}
                              <select
                                value={form.carType}
                                required
                                name="carType"
                                onChange={e => {
                                  handleChange(e)
                                }}
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="compactsuv">Compact SUV</option>
                                <option value="hatchback">Hatchback</option>
                                <option value="suv">SUV</option>
                                <option value="sedan">Sedan</option>
                              </select>
                            </div>
                          </Col>

                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Car Modal <span className="text-danger">*</span>
                              </Label>

                              <Select
                                required
                                defaultValue={selectedOption2}
                                onChange={handleSelect1234}
                                options={optionGroup1}
                                // name=""
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Cars <span className="text-danger">*</span>
                              </Label>

                              <Select
                                required
                                defaultValue={selectedOption12}
                                onChange={handleSelect012}
                                options={allcarids}
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Package <span className="text-danger">*</span>
                              </Label>

                              {/* <Select
                                                                required
                                                                defaultValue={selectedOption2}
                                                                onChange={setSelectedOption2}
                                                                options={options2}
                                                            /> */}
                              <select
                                value={form.timeSlot}
                                name="timeSlot"
                                onChange={e => {
                                  handleChange12(e)
                                }}
                                className="form-select"
                              >
                                <option value="">Select Package</option>
                                <option value="6">06 Hours</option>
                                <option value="12">12 Hours</option>
                                <option value="one">Day</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                km / Price{" "}
                                <span className="text-danger">*</span>
                              </Label>

                              {/* <Select
                                                                required
                                                                defaultValue={selectedOption2}
                                                                onChange={setSelectedOption2}
                                                                options={options2}
                                                            /> */}
                              <select
                                className="form-select"
                                name="priceId"
                                onChange={e => handlechangeprice(e)}
                              >
                                <option value="">Select kms</option>
                                {Kms.map((data, key) => (
                                  <option
                                    key={key}
                                    value={data._id + "/" + data.price}
                                  >
                                    {data.kms}kms/₹{data.price}
                                  </option>
                                ))}
                                ;
                              </select>
                            </div>
                          </Col>
                          {form.timeSlot == "one" ? (
                            <>
                              <Col lg="2">
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    From Date{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="fromDate"
                                    value={form.fromDate}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    type="date"
                                    required
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                  />
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    To Date{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="toDate"
                                    value={form.toDate}
                                    onChange={e => {
                                      handleChangedate(e)
                                    }}
                                    type="date"
                                    required
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {/* <Col lg="2">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Payment status <span className="text-danger">*</span>
                                                            </Label>

                                                            
                                                            <select
                                                                name="paymentStatus"
                                                                valid={form.paymentStatus}
                                                                onChange={(e) => { handleChange(e) }}
                                                                className="form-select">
                                                                <option value="">Select Payment</option>
                                                                <option value="0">partial</option>
                                                                <option value="1">full</option>
                                                            </select>
                                                        </div>
                                                    </Col> */}
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Price <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                required
                                placeholder="Price"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                name="price"
                                value={form.price}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            {/* <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Coupon Code
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Coupon Code"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                            />
                                                        </div> */}
                            <Label for="basicpill-declaration-input10">
                              Coupon Code
                            </Label>
                            <InputGroup>
                              <input
                                value={form.coupon_code}
                                name="coupon_code"
                                onChange={e => {
                                  handleChange(e)
                                }}
                                // required
                                type="text"
                                className="form-control"
                                id="basicpill-namecard-input11"
                                placeholder="Code"
                              />
                              <div
                                onClick={e => {
                                  couponsub(e)
                                }}
                                style={{ cursor: "pointer" }}
                                className="input-group-text"
                              >
                                {" "}
                                <i className="fas fa-check-circle text-success"></i>
                              </div>
                            </InputGroup>
                            {coup1.coupon == null || coup1.coupon == "" ? (
                              <small className="text-danger">
                                Invalid Coupon
                              </small>
                            ) : (
                              <small className="text-success">
                                Coupon Applied Successfully
                              </small>
                            )}
                            {/* {coup.status == true ? (
                                                            <small className="text-success">Coupon applyed</small>
                                                        ) : (
                                                            ""
                                                        )} */}
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Total Price{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                placeholder="Amount"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                name="totalprice"
                                value={form.totalprice}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <h5 className="mt-3">Security Deposit</h5>

                        {/* <Row>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col>
                                                            <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                                10 % Payment
                                                            </label>
                                                        </div>
                                                            </Col>
                                                            <Col>
                                                            <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" defaultValue="option2" />
                                                            <label className="form-check-label" htmlFor="exampleRadios2">
                                                            100 % Payment
                                                            </label>
                                                        </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row> */}

                        {/* <Row className="mt-3">
                                                <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Total Price <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Amount"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                booking Amount <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Amount"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Remaining Amount <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Amount"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}

                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Select Type{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Input
                                                                type="number"
                                                                placeholder="Amount"
                                                                className="form-control"
                                                                id="basicpill-Declaration-input10"
                                                            /> */}
                              <select
                                name="securityDeposite"
                                value={form.securityDeposite}
                                onChange={e => {
                                  handleChange(e)
                                }}
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="bike">Bike</option>
                                <option value="laptop">Laptop</option>
                                <option value="passport">Passport</option>
                                <option value="cash">Cash</option>
                              </select>
                            </div>
                          </Col>
                          {form.securityDeposite == "bike" ? (
                            <>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Bike Rgs No.{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="Rgs Number"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="RegistNumber"
                                    valid={form.RegistNumber}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Image <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="depositImg"
                                    onChange={changeHandler}
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {form.securityDeposite == "laptop" ? (
                            <>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Modal Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="Modal Name"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="RegistNumber"
                                    valid={form.RegistNumber}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Image <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="depositImg"
                                    onChange={changeHandler}
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {form.securityDeposite == "passport" ? (
                            <>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Passport No.{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="Passport No"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="RegistNumber"
                                    valid={form.RegistNumber}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-declaration-input10">
                                    Image <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-Declaration-input10"
                                    name="depositImg"
                                    onChange={changeHandler}
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {form.securityDeposite == "cash" ? (
                            <Col md={4}>
                              <div className="mb-3">
                                <Label for="basicpill-declaration-input10">
                                  Amount <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  placeholder="Amount"
                                  className="form-control"
                                  id="basicpill-Declaration-input10"
                                  name="depositeAmount"
                                  valid={form.depositeAmount}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>

                        <div style={{ float: "right" }}>
                          <Button type="submit" className="m-1" color="primary">
                            <i className="fas fa-check-circle"></i> Search
                          </Button>
                          <Button
                            onClick={hidefield}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              ) : (
                ""
              )}

              {editbooking ? (
                <>
                  <Card>
                    <CardBody>
                      <Form>
                        <h5 className="mb-4"> Edit Booking Details</h5>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Customer Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                placeholder="Customer Name"
                                required
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Phone No. <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                placeholder="number"
                                required
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                placeholder="Enter Email"
                                required
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Car Type <span className="text-danger">*</span>
                              </Label>

                              <Select
                                required
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Car Brand <span className="text-danger">*</span>
                              </Label>

                              <Select
                                required
                                defaultValue={selectedOption1}
                                onChange={setSelectedOption1}
                                options={options1}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Car Modal <span className="text-danger">*</span>
                              </Label>

                              <Select
                                required
                                defaultValue={selectedOption2}
                                onChange={setSelectedOption2}
                                options={options2}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                From Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                required
                                className="form-control"
                                id="basicpill-Declaration-input10"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                To Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                required
                                className="form-control"
                                id="basicpill-Declaration-input10"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Price <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                required
                                placeholder="Price"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Coupon Code
                              </Label>
                              <Input
                                type="number"
                                placeholder="Coupon Code"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                Total Price{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                placeholder="Amount"
                                className="form-control"
                                id="basicpill-Declaration-input10"
                              />
                            </div>
                          </Col>
                        </Row>

                        <div style={{ float: "right" }}>
                          <Button
                            onClick={hideeditfield}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                          <Button type="submit" className="m-1" color="primary">
                            <i className="fas fa-check-circle"></i> Submit
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              ) : (
                ""
              )}

              {filter ? (
                <>
                  <Card>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          getfilter(e)
                        }}
                      >
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                From Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                required
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                onChange={e => {
                                  handleChangeflt(e)
                                }}
                                name="fromDate"
                                value={filters.fromDate}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-declaration-input10">
                                To Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                required
                                className="form-control"
                                id="basicpill-Declaration-input10"
                                onChange={e => {
                                  handleChangeflt(e)
                                }}
                                name="toDate"
                                value={filters.toDate}
                              />
                            </div>
                          </Col>
                          {/* <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-declaration-input10">
                                                                Bookings <span className="text-danger">*</span>
                                                            </Label>
                                                            <select
                                                                onChange={(e) => { handleChangeflt(e) }}
                                                                name="status"
                                                                value={filters.status}
                                                                className="form-select">
                                                                <option value="All">All</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="accepted">Accepted</option>
                                                                <option value="completed">Completed</option>
                                                                <option value="cancelled">Canceled</option>
                                                            </select>
                                                        </div>
                                                    </Col> */}
                          <Col lg="3">
                            <div className="mt-4">
                              <Button
                                type="submit"
                                className="m-1"
                                color="primary"
                              >
                                <i className="fas fa-check-circle"></i> search
                              </Button>
                              <Button
                                onClick={hidefilter}
                                className="m-1"
                                color="danger"
                              >
                                <i className="fas fa-times-circle"></i> Cancel
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              ) : (
                ""
              )}

              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6">
                      <CardTitle> Booking List</CardTitle>
                    </div>
                    <div className="col-sm-6">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              {/* <Button className="m-1" onClick={() => { setaddbooking(!addbooking); }} color="info"><i className="fas fa-plus-circle"></i> New </Button> */}
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="primary"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                          {/* <Col>
                                                        <div style={{ float: "right" }}>
                                                            <Input name="search"
                                                                value={forms.search}
                                                                onChange={handlechange}
                                                             type="text" className="form-control" placeholder="Search.." />
                                                        </div>
                                                    </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-2">
                        <thead>
                          <tr className="text-center">
                            <th>S No</th>
                            <th>Booking Id</th>
                            <th>Booking Status</th>
                            {/* <th>No.of days</th> */}
                            <th>Vehicle</th>
                            <th>Rental Period</th>
                            <th>Client</th>
                            <th>Price</th>
                            <th>Date</th>
                            <th style={{ width: "170px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <th scope="row">
                                {(pageNumber - 1) * 5 + key + 6}
                              </th>
                              <td>
                                <b style={{ color: "#1B7BB2" }}>
                                  {data.booking_id}
                                </b>
                                <br />
                                <a style={{ color: "info" }}>{data.date}</a>
                              </td>
                              <td>
                                {data.status == "pending" ? (
                                  <a
                                    style={{ borderRadius: "5px" }}
                                    className="bg-warning text-white p-1"
                                  >
                                    {data.status}
                                  </a>
                                ) : (
                                  ""
                                )}
                                {data.status == "accepted" ? (
                                  <a
                                    style={{ borderRadius: "5px" }}
                                    className="bg-primary text-white p-1"
                                  >
                                    {data.status}
                                  </a>
                                ) : (
                                  ""
                                )}
                                {data.status == "cancelled" ? (
                                  <a
                                    style={{ borderRadius: "5px" }}
                                    className="bg-danger text-white p-1"
                                  >
                                    {data.status}
                                  </a>
                                ) : (
                                  ""
                                )}
                                {data.status == "completed" ? (
                                  <a
                                    style={{ borderRadius: "5px" }}
                                    className="bg-success text-white p-1"
                                  >
                                    {data.status}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              {/* <td>{data.timeSlot}</td> */}
                              <td>{data.carModelName} </td>
                              <td>
                                {data.fromDate == "undefined" || "" ? (
                                  "-"
                                ) : (
                                  <>From : {data.fromDate}</>
                                )}
                                <br></br>
                                <br></br>
                                {data.toDate == "undefined" || "" ? (
                                  "-"
                                ) : (
                                  <a> To :{data.toDate}</a>
                                )}
                              </td>
                              <td>{data.customerName}</td>
                              <td>{data.totalprice}</td>
                              <td>{data.date}</td>

                              <td>
                                {/* <Button
                                                                    onClick={() => { seteditbooking(!editbooking); }}
                                                                    className="mr-2" style={{ padding: "6px", margin: "3px" }} color="success" outline>
                                                                    <i className="bx bx-edit "></i>
                                                                </Button> */}

                                {data.status == "completed" ? (
                                  <Button
                                    onClick={() => {
                                      getpopup(data)
                                    }}
                                    className="mr-2 "
                                    style={{ padding: "6px", margin: "3px" }}
                                    color="info"
                                    outline
                                  >
                                    <i className="fas fa-car-alt text-dark"></i>
                                  </Button>
                                ) : (
                                  ""
                                )}

                                <Button
                                  onClick={() => {
                                    viewdata(data)
                                  }}
                                  className="mr-2 "
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="secondary"
                                  outline
                                >
                                  <i className="far fa-eye"></i>
                                </Button>

                                {data.status == "cancelled" ||
                                data.status == "completed" ? (
                                  ""
                                ) : (
                                  <Button
                                    onClick={() => {
                                      getpopup1(data)
                                    }}
                                    className="mr-2 "
                                    style={{ padding: "6px", margin: "3px" }}
                                    color="warning"
                                    outline
                                  >
                                    <i className="fas fa-user-edit text-dark"></i>
                                  </Button>
                                )}
                                {data.status == "completed" ? (
                                  <Button
                                    onClick={() => {
                                      getpopup2(data)
                                    }}
                                    style={{ padding: "6px", margin: "3px" }}
                                    color="success"
                                    outline
                                  >
                                    <i className="fas fa-retweet text-dark "></i>
                                  </Button>
                                ) : (
                                  ""
                                )}

                                {/* {data.paymentStatus == 0 ? (
                                                                    <Button
                                                                        onClick={() => {
                                                                            getpopup2(data);
                                                                        }}
                                                                        style={{ padding: "6px", margin: "3px" }} color="primary" outline>
                                                                        <i className="fas fa-retweet text-dark"></i>
                                                                    </Button>
                                                             ) : (
                                                                    ""
                                                                )}  */}

                                {/* <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        {/* <Stack spacing={2}> */}
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                        {/* </Stack> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      

        <Modal
          size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Booking Status
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                submibooking(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Car Reading</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Car Reading"
                  required
                  value={book.carReading}
                  name="carReading"
                  onChange={e => {
                    handleChangebs(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Status <span className="text-danger">*</span>
                </Label>
                {/* <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter   BookingName"
                                    required
                                /> */}

                <select
                  value={book.status}
                  name="status"
                  required
                  onChange={e => {
                    handleChangebs(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {/* <option value="pending">pending</option> */}
                  <option value="accepted">Accepted</option>
                  {/* <option value="completed">Completed</option> */}
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
              {book.status == "cancelled" ? (
                <div className="mb-3">
                  <Label for="basicpill-firstname-input2">
                    Reason <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input2"
                    placeholder="Enter Reason"
                    required
                    value={book.reason}
                    name="reason"
                    onChange={e => {
                      handleChangebs(e)
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Drive Points
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                submitdriv(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input2">
                  Drive Points <span className="text-danger">*</span>
                </Label>
                {/* <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Points"
                                required
                                
                            /> */}
                <select
                  className="form-select"
                  name="drivepoints"
                  onChange={e => {
                    handleChangedrive(e)
                  }}
                >
                  <option value="">Select</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                </select>
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={modal_small2}
          toggle={() => {
            tog_small2()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Security Deposit Status
            </h5>
            <button
              onClick={() => {
                setmodal_small2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                subrsdntchng(e)
              }}
            >
              <Label>Booking Id : {remam.booking_id}</Label>

              <div className="mb-3 mt-2">
                <Label for="basicpill-firstname-input2">
                  Paid Amount <span className="text-danger">*</span>
                </Label>
                <select
                  onChange={e => {
                    handleChangebook(e)
                  }}
                  name="securityDepositReturn"
                  value={remam.securityDepositReturn}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="0">Pending</option>
                  <option value="1">Completed</option>
                </select>
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small2(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Booking
