import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import car1 from "../assets/images/latest/car1.jpg"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"

function BookingReport() {
  const [coup, setcoup] = useState([])
  useEffect(() => {
    getBookingReports()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const getBookingReports = () => {
    var token = datas
    axios
      .post(
        "https://api.freedomcars.co.in/v1/admin/customer/getallwallethistory",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setcoup(res.data.walletHistResult)
      })
  }
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [form, setform] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editbrands()
  }


  const editbrands = () => {
    var token = datas
    const docid = form._id
    const dataArray = new FormData()
    dataArray.append("wallet  ", form.wallet)

    axios
      .patch(
        "https://api.freedomcars.co.in/v1/admin/customer/addamounttocustwallet" +
          "/" +
          docid,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform(data)
    tog_small()
  }


  const [forms, setforms] = useState([])
  console.log(forms)

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    console.log(myUser)
    var token = datas
    axios
      .post(
        `https://api.freedomcars.co.in/v1/admin/customer/getallwallethistory?searchQueryParam=${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.walletHistResult)
      })
  }


  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Freedom Cars" breadcrumbItem="Wallet History" />
            <Card>
              <CardHeader className="bg-white">
                {/* <CardTitle>Booking Report</CardTitle> */}
              </CardHeader>

              <CardBody>
                <div>
                  <div className="table-responsive">
                    <div style={{ float: "right" }}>
                      <Input
                        name="search"
                        value={forms.search}
                        onChange={handlechange}
                        type="text"
                        className="form-control"
                        placeholder="Search.."
                      />
                    </div>
                    <Table className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr>
                          <th>S No</th>

                          <th>Customer Name</th>
                          <th>phone</th>
                          <th>Email</th>
                          <th>wallet</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th>{(pageNumber - 1) * 5 + key + 6}</th>
                            <td>{data.customerName}</td>
                            <td>{data.customerPhone}</td>
                            <td>{data.customeremail}</td>
                            <th>{data.wallet}</th>
                            {/* <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                               
                              </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      {/* <Stack spacing={2}> */}
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                      {/* </Stack> */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
          <ToastContainer />

          <Modal
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            className="mt-5"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Add Wallet
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        ADD AMOUNT
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        value={form.wallet}
                        id="basicpill-firstname-input1"
                        placeholder="Enter ADD AMOUNT "
                        required
                        name="wallet"
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>

                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    </div>
  )
}

export default BookingReport
