import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"

import classnames from "classnames"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import car1 from "../assets/images/latest/car1.jpg"
import car2 from "../assets/images/latest/car2.jpg"
import car3 from "../assets/images/latest/car3.jpg"
import car4 from "../assets/images/latest/car4.jpg"
import car5 from "../assets/images/latest/car5.jpg"
import car6 from "../assets/images/latest/car6.jpg"
import card1 from "../assets/images/latest/card1.jpeg"
import card2 from "../assets/images/latest/card2.jpeg"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../Apiurl"
import axios from "axios"
import { useHistory } from "react-router-dom"

const options = [
  { value: "Luxury", label: "Luxury" },
  { value: "LuxurySUV", label: "Luxury SUV" },
  { value: "SUV", label: "SUV" },
  { value: "Sedan", label: "Sedan" },
  { value: "Hatchback", label: "Hatchback" },
  { value: "Crossover", label: "Crossover" },
]
const options1 = [
  { value: "AlfaRomeo", label: "Alfa Romeo" },
  { value: "Audi", label: "Audi" },
  { value: "Toyota", label: "Toyota" },
  { value: "BMW", label: "BMW" },
  { value: "Bugatti", label: "Bugatti" },
]
const options2 = [
  { value: "Giulietta", label: "Giulietta" },
  { value: "Brera", label: "Brera" },
  { value: "Giulietta", label: "Giulietta" },
  { value: "Spider", label: "Spider" },
  { value: "Stelvio", label: "Stelvio" },
]

const Carsveiw = () => {
  //meta title
  // document.title = "Freedom Cars ";
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOption1, setSelectedOption1] = useState(null)
  const [selectedOption2, setSelectedOption2] = useState(null)

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [showResults, setShowResults] = React.useState(false)
  const [editResults, seteditResults] = React.useState(false)
  const showfield = () => setShowResults(true)
  const hidefield = () => setShowResults(false)
  const hideeditfield = () => seteditResults(false)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const [isDisabled, setIsDisabled] = useState(true)
  const handleClick = () => {
    setIsDisabled(!isDisabled)
  }

  const [isDisabled1, setIsDisabled1] = useState(true)
  const handleClick1 = () => {
    setIsDisabled1(!isDisabled1)
  }
  const [isDisabled2, setIsDisabled2] = useState(false)
  const handleClick2 = () => {
    setIsDisabled2(!isDisabled2)
  }
  const [isDisabled3, setIsDisabled3] = useState(true)
  const handleClick3 = () => {
    setIsDisabled3(!isDisabled3)
  }
  const [isDisabled4, setIsDisabled4] = useState(false)
  const handleClick4 = () => {
    setIsDisabled4(!isDisabled4)
  }

  const [selectedFiles, setselectedFiles] = useState([])
  console.log(selectedFiles)
  const [selectedFiles1, setselectedFiles1] = useState([])
  console.log(selectedFiles1)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [fieldshow, setfieldshow] = useState()

  function handleSelectChange(event) {
    setfieldshow(event.target.value)
  }

  const [cars, setcars] = useState([])
  const [spce, setspce] = useState([])
  const [fut, setfut] = useState([])
  const [model1, setmodel1] = useState([])
  const [model, setmodel] = useState([])
  const [form, setform] = useState([])
  console.log(form)
  const [form1, setform1] = useState([])
  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const [imgs, setimgs] = useState([])
  const [imgs1, setimgs1] = useState([])

  const cursid = sessionStorage.getItem("carid")

  const [fet, setfet] = useState([])

  const getonecars = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("_id", cursid)
    axios
      .post(URL.getonecars, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        setform(res.data.carResult)
        setimgs(res.data.carResult.carImage)
        setimgs1(res.data.carResult.carRegisterImage)
        setform1(res.data.carResult.carPrices)
        setfet(res.data.carResult.carFeatures)
      })
  }

  const getactivebrand = () => {
    var token = datas
    axios
      .post(
        URL.activecarbrands,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setmodel1(res.data.activecarBrandResult)
      })
  }

  const getAllmodal = () => {
    var token = datas
    axios
      .post(
        URL.allcaractivemodels,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setmodel(res.data.modelResult)
      })
  }

  const getAllfeature = () => {
    var token = datas
    axios
      .post(
        URL.activefeature,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setfut(res.data.featuresResult)
      })
  }

  const getAllspfc = () => {
    var token = datas
    axios
      .post(
        URL.activecarspecs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res.data)
        setspce(res.data.activeSpecs)
      })
  }

  useEffect(() => {
    getonecars()
    getactivebrand()
    getAllmodal()
    getAllfeature()
    getAllspfc()
  }, [])

  const optionGroup123 = [
    {
      label: "Colors",
      options: [
        { label: "Beige", value: "Beige" },
        { label: "Black", value: "Black" },
        { label: "Blue", value: "Blue" },
        { label: "Brown", value: "Brown" },
        { label: "DarkGray", value: "Dark Gray" },
      ],
    },
  ]

  const optionGroup1 = fut.map(response => ({
    value: response._id,
    label: response.featureName,
  }))
  console.log(optionGroup1)

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    ///setSelectedOptions(current => [...current, details.value]);
    setSelectedOptions(details)
    console.log(details)
  }

  const [selectedMulti1, setselectedMulti1] = useState([])
  console.log(selectedMulti1)
  function handleMulti(data12) {
    setselectedMulti1(data12)
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = cars.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(cars.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const allcolors = form.carColorAvailble
  console.log(allcolors)

  // const allll = allcolors

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="View Car Details" />

          <Row>
            <div>
              <Link to="/cars">
                {" "}
                <Button style={{ float: "right" ,  margin: "5px" }} className="btn-info">
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Button>
              </Link>
              {/* <Link to="/LC-invoice">
                {" "}
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn- mb-3"
                  color="danger"
                >
                  <i className="fas fa-file-invoice"></i> Invoice
                </Button>
              </Link> */}
            </div>
            <Form>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Label>
                          <b>Car images:</b>
                        </Label>
                        <br />
                        {imgs.map((data, i) => (
                          <Label key={i}>
                            <b>
                              <img
                                className="m-1"
                                src={"https://api.freedomcars.co.in/" + data}
                                style={{ width: "100px" }}
                              />
                            </b>
                          </Label>
                        ))}
                      </Col>
                      <Col md={6}>
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Car Registration Number</b>
                            </Label>{" "}
                          </div>
                          <br />
                          <div className="col col-sm-7">
                            <Label>: {form.carRegistNumber}</Label>
                            <br />{" "}
                          </div>
                        </div>
                        {imgs1.map((data, i) => (
                          <Label key={i}>
                            <b>
                              <img
                                className="m-1"
                                src={"https://api.freedomcars.co.in/" + data}
                                style={{ width: "100px" }}
                              />
                            </b>
                          </Label>
                        ))}
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Car Type</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Version</b>
                            </Label>
                            <br />
                            <Label>
                              <b>No.of Seats</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-7">
                            <Label>: {form.carType}</Label>
                            <br />
                            <Label>: {form.carMakeYear}</Label>
                            <br />{" "}
                            <Label>: {form.seatCount}</Label>
                            {/* {fet.map((data, i) => (
                              <Label key={i}>{data} ,</Label>
                            ))} */}
                            <br />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Car Brand</b>
                            </Label>
                            <br />
                            <Label>
                              <b> M/A/B</b>{" "}
                            </Label>
                            <br />
                            <Label>
                              <b>Specification</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-7">
                            <Label>: {form.carBrandName}</Label>
                            <br />
                            <Label>: {form.vehicleType}</Label>
                            <br />
                            <Label>: {form.carSpecs}</Label>
                            <br />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="row">
                          <div className="col col-sm-5">
                            <Label>
                              <b>Model</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Colors</b>
                            </Label>
                            <br />
                            <Label>
                              <b>Boot Capacity</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-7">
                            <Label>: {form.carModelName}</Label>
                            <br />
                            <Label>: {form.carColorAvailble}</Label>
                            <br />
                            <Label>: {form.carBootCapacity}</Label>
                            <br />
                          </div>
                        </div>
                      </Col>
                      <Col className="mt-2" md={4}>
                        <div className="row">
                          <div className="col col-sm-7">
                            <Label>
                              <b>Insurance Amount</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-5">
                            <Label>: {form.insurance}</Label>
                            <br />
                          </div>
                        </div>
                      </Col>
                      <Col className="mt-2" md={7}>
                        <div className="row">
                          <div className="col col-sm-3">
                            <Label>
                              <b>Car Features</b>
                            </Label>
                            <br />
                          </div>
                          <div className="col col-sm-9">: 
                         {fet.map((data, i) => (
                              <Label key={i}>{data} ,</Label>
                            ))}
                            <br />
                          </div>
                        </div>
                      </Col>

                    </Row>


                    <Row className="mt-2">
                      <Col md={12}>
                        <div className="table-responsive">
                          <Table className="table table-sm  mb-5 mt-3">
                            <thead>
                              <tr className="">
                                <th style={{ width: "150px" }}>
                                  Period / Mileage :
                                </th>
                                <th style={{ width: "150px" }}>6 Hours :</th>
                                <th style={{ width: "150px" }}>12 Hours :</th>
                                <th style={{ width: "150px" }}>1 day :</th>
                              </tr>
                            </thead>
                            <tbody>
                              {form1.map((data, key) => {
                                return (
                                  <tr className="text-center" key={key}>
                                    <th style={{ width: "150px" }} scope="row">
                                      <InputGroup style={{ width: "150px" }}>
                                        <div className="input-group-text">
                                          KM
                                        </div>
                                        <input
                                          value={data.kms}
                                          type="number"
                                          disabled
                                          className="form-control"
                                          id="basicpill-namecard-input11"
                                          placeholder="Mileage"
                                        />
                                      </InputGroup>
                                    </th>
                                    <td style={{ width: "150px" }}>
                                      <InputGroup style={{ width: "150px" }}>
                                        <div className="input-group-text">
                                          {" "}
                                          <i className="fas fa-rupee-sign text-dark"></i>
                                        </div>
                                        <input
                                          value={data.sixHoursprice}
                                          type="number"
                                          disabled
                                          className="form-control"
                                          id="basicpill-namecard-input11"
                                          placeholder="Charges"
                                        />
                                      </InputGroup>
                                    </td>
                                    <td style={{ width: "150px" }}>
                                      <InputGroup style={{ width: "150px" }}>
                                        <div className="input-group-text">
                                          {" "}
                                          <i className="fas fa-rupee-sign text-dark"></i>
                                        </div>
                                        <input
                                          value={data.tweleveHoursprice}
                                          type="number"
                                          disabled
                                          className="form-control"
                                          id="basicpill-namecard-input11"
                                          placeholder="Charges"
                                        />
                                      </InputGroup>
                                    </td>
                                    <td style={{ width: "150px" }}>
                                      <InputGroup style={{ width: "150px" }}>
                                        <div className="input-group-text">
                                          {" "}
                                          <i className="fas fa-rupee-sign text-dark"></i>
                                        </div>
                                        <input
                                          value={data.onedayPrice}
                                          type="number"
                                          disabled
                                          className="form-control"
                                          id="basicpill-namecard-input11"
                                          placeholder="Charges"
                                        />
                                      </InputGroup>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Carsveiw
