import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URL } from "../../Apiurl"
import axios from "axios"
import { saveAs } from "file-saver"
import { withRouter, Link } from "react-router-dom"
import { useHistory } from "react-router-dom"

const Document = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [brand, setbrand] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const downloadImage = () => {
    saveAs("https://api.freedomcars.co.in/" + form.softCopy) // Put your image url here.
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const cursid = sessionStorage.getItem("bookid")

  const getdocument = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("id", cursid)
    axios
      .post(URL.addinvoice, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        // setform(res.data.bookingResult)
        // setform1(res.data.bookingResult.payments)
      })
  }
  const getdocument123 = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("id", cursid)
    axios
      .post(URL.viewinvoice, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data)
        setform(res.data.invoiceResult)
        // setform1(res.data.bookingResult.payments)
      })
  }

  useEffect(() => {
    getdocument()
    getdocument123()
  }, [])

  const goBack = () => {
    history.goBack()
  }
  const history = useHistory()
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Freedom Cars" breadcrumbItem="Invoice" />

          <Row>
            <div>
              {/* <Link to="/booking-details"> */}
                {" "}
                <Button
                  style={{ float: "right", margin: "5px" }}
                  className="btn-info mb-3"
                  onClick={goBack}
                >
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Button>
              {/* </Link> */}
              <button
                style={{ float: "right", margin: "5px" }}
                onClick={downloadImage}
                className="btn btn-primary bg-primary text-white"
              >
                <i
                  className="fas fa-cloud-download-alt mr-1"
                  aria-hidden="true"
                ></i>{" "}
                Download{" "}
              </button>
            </div>
            <Col md={12}>
              <Card>
                <CardBody>
                  {form == null ? (
                    "No Data..."
                  ) : (
                    <div>
                      <div className="text-center">
                        <img
                          src={"https://api.freedomcars.co.in/" + form.softCopy}
                          alt=""
                          style={{ width: "80%" }}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Document
